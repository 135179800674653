import React from 'react'

import './ClassListItem.scss';
import { useGetSection } from '../../../../queryHooks/Section/useGetSection/useGetSection';
import { convertToOrdinal } from '../../../../utils/convertToOrdinal';

const ClassListItem = ({
    class_id, class_name, item, school_id, onClick,
}) => {
    const { data: sections, error: sectionError, isLoading: sectionLoading, refetch: sectionRefetch } = useGetSection({ class_id: class_id, enabled: school_id && true })

    const board_id = item?.board_id;


    const header = class_name
    return (
        <div className='class-module-container'>
            <div className='class-module-wrapper'>
                <div>
                    <p className='class-module-header-text'>
                        {header} class
                    </p>
                </div>
                <div className='class-module-section-container'>
                    <p className='class-module-sub-header-text'>Sections</p>
                    <div className='class-module-section-wrapper'>
                    {sections?.length > 0 && sections.map((item, index) => {
                        if (item?.section_name !== null && item?.section_name) {
                            return (
                                <div className='class-module-section-item'>
                                <p>{item?.section_name}</p>
                            </div>
                                )
                            }
                        })}
                    <div className='class-module-add-button' onClick={() => onClick(class_id, board_id)}>
                        <p>+</p>
                    </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default ClassListItem;
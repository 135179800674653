import React, { createRef, useCallback, useMemo, useRef, useState } from 'react'
import { CREATE_BUS_OPERATOR_FORM, DEFAULT_FORM_DATA, FILE_INPUT_NAME } from './constants';
import { FiUploadCloud } from 'react-icons/fi';
import FormInput from '../../../../components/FormInput/Input';
import { useNavigate } from 'react-router-dom';
import { useSharedToast } from '../../../../App';
import { useCreateAdmin } from '../../../../queryHooks/useCreateAdmin/useCreateAdmin';
import { useAuthStore } from '../../../../stores';
import { Avatar, Button, LayoutContainer } from '../../../../components';

import './CreateBusOperator.scss';
import { checkEmpty } from '../../../../utils/checkEmpty';
import { useDropzone } from 'react-dropzone';
import { useCreateBusOperator } from '../../../../queryHooks/useCreateBusOperator/useCreateBusOperator';
import { getBase64 } from '../../../../utils/base64Encode';
import { useGetBuses } from '../../../../queryHooks/useGetBuses/useGetBuses';

export default function CreateBusOperator() {
  const navigate = useNavigate();
  const [input, setInput] = useState(DEFAULT_FORM_DATA);
  const { toast } = useSharedToast();
  const user = useAuthStore(state => state.user);
  const school_id = useAuthStore(state => state.school_id)
  const { createBusOperator } = useCreateBusOperator();
  const { data: busOptions, error: busError, isInitialLoading: busLoading } = useGetBuses({ school_id: school_id, enabled: school_id && true })


  const goBack = () => {
    navigate("../");
  }
  const ActionButton = () => {
    return (
      <div>
        <Button
          onClick={goBack}
          theme={"secondary cancel"}
          style={{ marginTop: "15px" }}
        >
          Exit
        </Button>
      </div>
    );
  };

  const showSuccess = () => {
    toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Bus Operator profile created successfully!' });
  };

  const showError = () => {
    toast?.current?.show({ severity: 'error', summary: 'Invalid Form', detail: 'Please enter all the details!' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //do nothing
    const isEmpty = checkEmpty(input)
    if (isEmpty) {
      showError();
      return false;
    }
    createBusOperator({ inputs: input, school_id: school_id }).then(() => {
      goBack();
    }).catch((err) => {
      console.log('create bus operator err', err)
    })
  }

  const RenderActionButtons = ({ onSubmit }) => {
    return (
      <div style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
        <div style={{ width: '20%', margin: '10px', position: 'absolute', bottom: 10, right: 10 }}>
          <Button type="submit" form="profile-inputs" theme={'primary'} onClick={onSubmit}>
            {'Submit'}
          </Button>
        </div>
      </div>
    )
  }

  const hiddenFileInput = createRef(null);

  const handleClick = event => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const onDrop = useCallback((e) => {
    //access file dropped.
    getBase64(e[0]).then((value) => {
      setInput(prevState => {
        return {
          ...prevState,
          [FILE_INPUT_NAME]: value,
        };
      });
    })
  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'image/*',
  });

  const acceptedFileItems = acceptedFiles.map((file) => {
    return (
      <Avatar image={input[FILE_INPUT_NAME]} height={50} width={50} />)
  });


  const CreateInputs = useMemo(() => {
    return (
      <>
        <form id="admin-inputs" className='admin-inputs' onSubmit={handleSubmit}>
          <div className="admin-input-container">
            {CREATE_BUS_OPERATOR_FORM.map(
              (
                { label, type, options, name, placeholder, uploadButton, width },
                index
              ) => {
                return (
                  <div className={`form-field ${name}`} key={index}>
                    <h4 style={{ flexDirection: 'row', display: 'inline-flex' }}>{label}<p style={{ color: 'red' }}>*</p></h4>
                    <div className={`${uploadButton && "upload-present"}`}>
                      {type !== "dragNdrop" && (
                        <FormInput
                          type={type}
                          name={name}
                          options={type === "select" && name == 'bus_no' ? busOptions : options ?? []}
                          {...(type == 'dragNdrop' && {
                            onChange: (e) => {
                              const value = URL.createObjectURL(e.target.files[0])
                              setInput(prevState => {
                                return {
                                  ...prevState,
                                  [name]: value,
                                };
                              });
                            }
                          })}
                          width={width && width}
                          {...(type !== 'file' && { value: input })}
                          setValue={setInput}
                          required={true}
                          placeholder={placeholder && placeholder}
                        />
                      )}
                      {type === "dragNdrop" && (
                        <>
                          <section className="drop-container">
                            <div {...getRootProps({ className: 'dropzone' })}>
                              <input {...getInputProps()} />
                              <p className="drop-text">
                                {placeholder}
                              </p>
                            </div>
                            <ul>{acceptedFileItems}</ul>
                          </section>
                        </>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </form>
      </>
    )
  }, [input])

  return (
    <LayoutContainer
      label={"Create new bus operator profile"}
      RightComponent={<ActionButton />}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {CreateInputs}
        <RenderActionButtons onSubmit={handleSubmit} />
      </div>
    </LayoutContainer>
  )
}

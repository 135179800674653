import React, { useCallback, useMemo, useState } from "react";
import { Button, LayoutContainer, Topbar, VideoPlayer } from "../../components";
import { Outlet, useLocation, useNavigate, useOutlet } from "react-router-dom";
import { convertToOrdinal } from "../../utils/convertToOrdinal";
import { useAuthStore } from "../../stores";
import { GoPlus } from "react-icons/go";
import { GrNotes } from "react-icons/gr";
import { Skeleton } from "primereact/skeleton";
import { useGetChapters } from "../../queryHooks/useGetChapters/useGetChapters";

import "./Topics.scss";
import Card from "../../components/Card/Card";
import { useGetNotes } from "../../queryHooks/useGetNotes/useGetNotes";
import NotesModal from "./modals/NotesModal/NotesModal";
import { useGetVideos } from "../../queryHooks/useGetVideos/useGetVideos";
import VideoPagination from "./components/VideoPagination/VideoPagination";
// import ChapterModal from './modals/ChapterModal/ChapterModal';

export default function Topics() {
  const { state } = useLocation();
  const outlet = useOutlet();
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const isSuperAdmin = user?.admin_type === "super_admin" ? true : false;
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [open, setOpen] = useState("others");
  const [selectedType, setSelectedType] = useState("videos");
  const [videoIndex, setVideoIndex] = useState(0);
  const school_id = useAuthStore((state) => state.school_id);
  const header = `${state?.topic_name}`;
  const subject_id = state?.subject_id;
  const topic_id = state?.topic_id;
  const class_id = state?.class_id;
  const chapter_id = state?.chapter_id;

  const {
    data: videos,
    error: videoError,
    isInitialLoading: videoLoading,
    refetch: videoRefetch,
  } = useGetVideos({
    topic_id: state?.topic_id,
    subject_id: subject_id,
    chapter_id: state?.chapter_id,
    class_id: class_id,
  });

  const video_id =
    Array.isArray(videos) && videos.length > 0
      ? videos[videoIndex].video_id
      : false;
  const enabled = Array.isArray(videos) && videos.length > 0 ? true : false;

  const {
    data: notes,
    error: notesError,
    isInitialLoading: notesLoading,
    refetch: notesRefetch,
  } = useGetNotes({
    video_id: video_id,
    enabled: enabled,
  });

  const SkeletonLoader = () => {
    return (
      <div className="teacher-wrapper">
        {Array.from(Array(10).keys()).map((_, index) => {
          return (
            <div
              className="user-item-container"
              key={index}
              style={{ backgroundColor: "transparent" }}
            >
              <Skeleton height="125px" key={index} />
            </div>
          );
        })}
      </div>
    );
  };

  const handleChange = (event) => {
    setSelectedType(event.target.value);
  };

  const NoContent = () => {
    return (
      <div className="no-content-chapter">
        <p>You haven't created any of the note(s) yet.</p>
      </div>
    );
  };

  const handleBackPress = () => {
    navigate(-1);
  };

  const CreateNotesModal = useCallback(() => {
    return (
      <NotesModal
        videoIndex={videoIndex}
        videos={videos}
        open={open}
        visible={noteModalVisible}
        setVisible={setNoteModalVisible}
        subject_id={subject_id}
        class_id={class_id}
        props={{
          class_id: class_id,
          subject_id: subject_id,
          topic_id: topic_id,
          chapter_id: chapter_id,
        }}
      />
    );
  }, [noteModalVisible]);

  const NoteButton = () => {
    return (
      <div className="flex">
        {isSuperAdmin && (
          <>
            <Button
              onClick={() => {
                setNoteModalVisible(true);
                setOpen("video");
              }}
              icon={<GoPlus />}
              theme={"primary"}
              style={{ marginTop: "15px", width: "150px", marginRight: "20px" }}
            >
              New Video
            </Button>
            {Array.isArray(videos) && videos.length > 0 && (
              <Button
                onClick={() => {
                  setNoteModalVisible(true);
                  setOpen("others");
                }}
                icon={<GoPlus />}
                theme={"primary"}
                style={{ marginTop: "15px", width: "150px" }}
              >
                New Note
              </Button>
            )}
          </>
        )}
      </div>
    );
  };

  const RenderSelectionButtons = () => {
    if (notes && videos && notes.length > 0 && videos.length > 0) {
      return (
        <div className="video-or-note">
          <div className="video-or-note-button">
            <Button
              theme={selectedType === "videos" ? "primary" : "secondary"}
              onClick={() => setSelectedType("videos")}
            >
              Videos
            </Button>
          </div>
          <div className="video-or-note-button">
            <Button
              className={selectedType === "notes" ? "primary" : "secondary"}
              onClick={() => setSelectedType("notes")}
            >
              Notes
            </Button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const RenderSelectedType = useMemo(() => {
    if (selectedType === "videos") {
      return videos && videos?.length !== 0 ? (
        <div className="video-container">
          <VideoPlayer
            key={videoIndex}
            title={videos[videoIndex]?.video_caption}
            src={videos[videoIndex]?.video}
            cover={videos[videoIndex]?.video_image}
            height="300px"
            width="100%"
          />
          <VideoPagination
            total={videos?.length}
            pageSize={1}
            onPageChange={(page) => {
              setVideoIndex(page - 1);
            }}
          />
        </div>
      ) : null;
    } else {
      return <div>Notes</div>;
    }
  }, [selectedType, videos, videoIndex]);

  if (outlet) {
    return <Outlet />;
  }

  return (
    <>
      <CreateNotesModal />
      <LayoutContainer
        onBackPress={handleBackPress}
        label={header}
        RightComponent={<NoteButton />}
      >
        {/* <div className="label-container">
          <p className="label-header">Notes</p>
        </div> */}
        <div className="topic-chapter-container">
          {/* <RenderSelectionButtons /> */}
          {/* <RenderSelectedType /> */}
          {RenderSelectedType}
          {notesLoading ? (
            <SkeletonLoader />
          ) : notes && notes.length !== 0 ? (
            <div className="chapter-wrapper">
              <div className="chapter-items-wrapper">
                {notes.map((item, index) => {
                  return (
                    <div>
                      {item.notes_masters.map((s) => {
                        return (
                          <div
                            className="each-note"
                            onClick={() => {
                              window.open(s.note, "_blank");
                            }}
                            key={s.id}
                          >
                            <GrNotes /> Click to view note
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <NoContent />
          )}
        </div>
      </LayoutContainer>
    </>
  );
}

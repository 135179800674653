import React, { useCallback, useMemo, useState } from "react";
import { Button, LayoutContainer, Topbar, VideoPlayer } from "../../components";
import { Outlet, useLocation, useNavigate, useOutlet } from "react-router-dom";
import { convertToOrdinal } from "../../utils/convertToOrdinal";
import { useAuthStore } from "../../stores";
import { GoPlus } from "react-icons/go";
import { GrNotes } from "react-icons/gr";
import { Skeleton } from "primereact/skeleton";
import { useGetChapters } from "../../queryHooks/useGetChapters/useGetChapters";

import "./mcq.scss";
import Card from "../../components/Card/Card";
import { useGetNotes } from "../../queryHooks/useGetNotes/useGetNotes";
import NotesModal from "./modals/NotesModal/NotesModal";
import { useGetVideos } from "../../queryHooks/useGetVideos/useGetVideos";
import VideoPagination from "./components/VideoPagination/VideoPagination";
import { UseMcq } from "../../queryHooks/useMcq/useMcq";
import ContentViewer from "../contentViewer/contentViewer";
import { useQueryClient } from "react-query";
import { useDeleteMCQ } from "../../queryHooks/useMcq/useDeleteMcq";
import PasswordModal from "../../components/PasswordModal/PasswordModal";
// import ChapterModal from './modals/ChapterModal/ChapterModal';

export default function MCQS({ chapterId, index }) {
  const { state } = useLocation();
  const outlet = useOutlet();
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const isSuperAdmin = user?.admin_type === "super_admin" ? true : false;
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [open, setOpen] = useState("others");
  const [selectedType, setSelectedType] = useState("videos");
  const [videoIndex, setVideoIndex] = useState(0);
  const school_id = useAuthStore((state) => state.school_id);
  const subject_id = state?.subject_id;
  const topic_id = state?.topic_id;
  const class_id = state?.class_id;
  const chapter_id = state?.chapter_id;

  const queryClient = useQueryClient();
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const [header, setHeader] = useState("");
  const {
    deleteClass,
    isLoading: deleteClassLoading,
    error: deleteClassError,
  } = useDeleteMCQ();

  const handleDeleteClick = (header, item) => {
    setHeader("mcq");
    setDeletedItem((prevState) => {
      return {
        ...prevState,
        [header]: item,
      };
    });
    setPasswordModalVisible(true);
  };

  const onDelete = () => {
    deleteClass({
      mcq_id: deletedItem?.mcq?.mcq_id,
    }).then(() => {
      queryClient.invalidateQueries({ queryKey: ["getNotes"] });
      setDeletedItem((prevState) => {
        return {
          ...prevState,
          subject: null,
        };
      });
    });
  };

  const CreatePasswordVerifyModal = useCallback(() => {
    return (
      <PasswordModal
        visible={passwordModalVisible}
        setVisible={setPasswordModalVisible}
        header={header}
        onSuccess={onDelete}
      />
    );
  }, [passwordModalVisible, header]);

  const {
    data: videos,
    error: videoError,
    isInitialLoading: videoLoading,
    refetch: videoRefetch,
  } = UseMcq({
    chapter_id: chapter_id,
  });

  const SkeletonLoader = () => {
    return (
      <div className="teacher-wrapper">
        {Array.from(Array(10).keys()).map((_, index) => {
          return (
            <div
              className="user-item-container"
              key={index}
              style={{ backgroundColor: "transparent" }}
            >
              <Skeleton height="125px" key={index} />
            </div>
          );
        })}
      </div>
    );
  };

  const handleChange = (event) => {
    setSelectedType(event.target.value);
  };

  const NoContent = () => {
    return (
      <div className="no-content-chapter">
        <p>You haven't created any of the note(s) yet.</p>
      </div>
    );
  };

  const handleBackPress = () => {
    navigate(-1);
  };

  const CreateNotesModal = useCallback(() => {
    return (
      <NotesModal
        videoIndex={videoIndex}
        videos={videos}
        open={open}
        visible={noteModalVisible}
        setVisible={setNoteModalVisible}
        subject_id={subject_id}
        class_id={class_id}
        props={{
          class_id: class_id,
          subject_id: subject_id,
          topic_id: topic_id,
          chapter_id: chapter_id,
        }}
      />
    );
  }, [noteModalVisible]);

  const NoteButton = () => {
    return (
      <div className="">
        <>
          <Button
            onClick={() => {
              setNoteModalVisible(true);
              setOpen("video");
            }}
            icon={<GoPlus />}
            theme={"primary"}
            style={{ marginTop: "15px", width: "150px", marginRight: "20px" }}
          >
            New MCQ
          </Button>
        </>
      </div>
    );
  };

  if (outlet) {
    return <Outlet />;
  }

  return (
    <>
      <CreateNotesModal />
      <CreatePasswordVerifyModal />
      <LayoutContainer
        onBackPress={handleBackPress}
        label={state?.label}
        RightComponent={<NoteButton />}
      >
        <div className="topic-chapter-container">
          {videoLoading ? (
            <SkeletonLoader />
          ) : videos && videos.length !== 0 ? (
            <div>
              <div>
                {videos.map((item, i) => {
                  return (
                    <ContentViewer
                      key={i}
                      handleDeleteButton={() => {
                        handleDeleteClick("mcq", item);
                      }}
                      item={item}
                      type={index}
                      description={item.description}
                      title={item.name}
                      url={item?.file}
                      time="53 minutes ago"
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <NoContent />
          )}
        </div>
      </LayoutContainer>
    </>
  );
}

import { useMutation } from "react-query";
import request from "../../utils/request";
import { BASE_URL } from "../../Config";
import { useSharedToast } from "../../App";

const deleteTopics = async ({ inputs, topic_id }) => {
  const queryResponse = await request({
    url: `${BASE_URL}/api/admin/topic/delete`,
    method: `DELETE`,
    data: {
      topic_id: topic_id,
    },
  });
  return queryResponse;
};

export const useDeleteTopic = () => {
  const { toast } = useSharedToast();
  const {
    mutateAsync: deleteTopic,
    isLoading,
    error,
  } = useMutation(deleteTopics, {
    onSuccess: (data) => {
      // Success actions
      if (data.status == 200) {
        toast?.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Board deleted successfully!",
        });
      }
    },
    onError: (error) => {
      console.log(error, "error here");
      // Error actions
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Oops! there was an error deleting the Board.",
      });
    },
  });

  return {
    deleteTopic: deleteTopic,
    isLoading,
    error,
  };
};

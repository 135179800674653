import { useMutation } from "react-query";
import { BASE_URL } from "../../../Config";
import { convertToBase64Readable } from "../../../utils/base64Encode";
import request from "../../../utils/request";
import { useSharedToast } from "../../../App";

const splitName = (name = "") => {
  const [firstName, ...lastName] = name.split(" ").filter(Boolean);
  return {
    firstName: firstName,
    lastName: lastName.join(" "),
  };
};

/* 
 "first_name":"shiv",
  "address":"testing adderess",
  "middle_name":"",
  "last_name":"kumar",
  "school_id":"school_id6268bd7d0d18",
  "class_id":"class_9207d31b0cca",
  "section":"1",
  "guardian_name":"shiv ji",
  "contact_no":"8700300731",
  "id_no":"1",
  "dob":"",
  "email_id":"shivkr98188+student2@gmail.com",
  "password":"123456789",
  "gender":"Male",
  "idNo":"1",
  "avtar":"",
  "bus_no":"1"
  */

const createSchoolDetails = async ({ inputs }) => {
  const inputparam = inputs;
  inputparam["photo"] = convertToBase64Readable(inputs?.photo);
  inputparam["logo"] = convertToBase64Readable(inputs?.logo);
  const queryResponse = await request({
    url: `${BASE_URL}/api/admin/school/create`,
    method: `POST`,
    data: inputparam,
  });
  return queryResponse;
};

export const useCreateSchool = () => {
  const { toast } = useSharedToast();
  const {
    mutateAsync: createSchool,
    isLoading,
    error,
  } = useMutation(createSchoolDetails, {
    onSuccess: (data) => {
      // Success actions
      if (data.status == 200) {
        toast?.current?.show({
          severity: "success",
          summary: "Success",
          detail: "School profile created successfully!",
        });
      }
    },
    onError: (error) => {
      // Error actions
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Oops! there was an error creating the school profile.",
      });
    },
  });

  return {
    createSchool,
    isLoading,
    error,
  };
};

import React from "react";
import "./ModalWithLoader.css"; // Import your CSS for styling

function ModalWithLoader() {
  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 99,
          overflow: "hidden",
          backgroundColor: "rgba(79, 79, 79, 0.31)",
        }}
        className={`ModalPopup`}
        onMouseDown={(e) => {}}
      >
        <div className="loader-parent">
          <div className="loader"></div>
        </div>
      </div>
    </div>
  );
}

export default ModalWithLoader;

import {
  DEFAULT_CLASS_OPTIONS,
  DEFAULT_SECTION_OPTIONS,
} from "../../../Teacher/sub-pages/CreateTeacher/constants";

export const CREATE_SCHOOl_FORM = [
  {
    label: "School Name",
    type: "text",
    name: "school_name",
    placeholder: "Enter School name",
  },
  {
    label: "Board Name",
    type: "select",
    name: "board_id",
    placeholder: "Select School board",
  },
  {
    label: "Email ID",
    type: "text",
    name: "email",
    placeholder: "Enter your email ID",
  },
  {
    label: "Password",
    type: "password",
    name: "password",
    placeholder: "Enter your password",
  },
  {
    label: "Confirm Password",
    type: "password",
    name: "confirm_password",
    placeholder: "Confirm your password",
  },
  {
    label: "Upload Logo",
    type: "dragNdrop",
    name: "logo",
    placeholder: "Upload your Logo",
  },
  {
    label: "School Tagline",
    type: "text",
    name: "school_tagline",
    placeholder: "Enter your school's tagline",
  },
  {
    label: "Contact No.",
    type: "tel",
    name: "contantNo",
    width: "100%",
    placeholder: "Enter your school contact number",
  },
  {
    label: "Enter Address",
    type: "textarea",
    name: "address",
    placeholder: "Enter your address",
  },
  {
    label: "Location URL",
    type: "text",
    name: "locationURL",
    placeholder: "Add your location URL",
  },
  {
    label: "Owner's Name",
    type: "text",
    name: "ownerName",
    placeholder: "Enter the Owner's name",
  },
  {
    label: "Upload Photo",
    type: "dragNdrop",
    name: "photo",
    placeholder: "Upload your photo",
  },
];

export const DEFAULT_FORM_DATA = {
  confirm_password: "",
  school_name: "",
  address: "",
  email: "",
  password: "",
  board_id: "",
  logo: null,
  school_tagline: "",
  contantNo: "",
  locationURL: "",
  ownerName: "",
  photo: null,
};

// {
//     "school_name":"school_test1",
//     "address":"testing address",
//     "email":"",
//     "password":"",
//     "logo":"",
//     "school_tagline":"",
//     "contantNo":"",
//     "locationURL":"",
//     "ownerName":"",
//     "photo":""

// }
/*
full name
mother name
user name
photo
emergency contact
email id
class
section
bus no
id card no
DOB
gender
*/

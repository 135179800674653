export const CREATE_TEACHER_FORM = [
  {
    label: "Full Name",
    type: "text",
    name: "name",
    placeholder: "Enter your full name",
  },
  {
    label: "Email ID",
    type: "text",
    name: "email_id",
    placeholder: "Enter your email id",
  },
  {
    label: "New Password",
    type: "password",
    name: "new_password",
    placeholder: "Enter New Password",
  },
  {
    label: "Confirm Password",
    type: "password",
    name: "confirm_password",
    placeholder: "Enter New Password",
  },
  {
    label: "Gender",
    type: "select",
    options: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "others", label: "Others" },
    ],
    name: "gender",
  },
  {
    label: "ID No.",
    type: "text",
    name: "id_no",
    placeholder: "Enter ID",
  },
  {
    label: "Address",
    type: "textarea",
    name: "address",
    placeholder: "Enter Address",
  },
  {
    label: "Date of Birth",
    type: "date",
    name: "dob",
    placeholder: "Enter your Date of Birth",
  },
  {
    label: "Contact No",
    type: "tel",
    name: "contact_no",
    width:'100%',
    placeholder: "Enter your Contact",
  },
  {
    label: "Upload Photo",
		type: "file",
    name: "photo",
    placeholder: "Upload your photo",
  },
];

export const DEFAULT_FORM_DATA = {
  name: "",
  email_id: "",
  new_password: "",
  confirm_password: "",
  gender: "",
  DD: "",
  MM:"",
  id_no:"",
  YYYY:"",
  contact_no:"",
  address:"",
  photo:null,
};

export const DEFAULT_CLASS_OPTIONS = [
  {
    value:5, label:'5th Class',
  },
  {
    value:6, label:'6th Class',
  },
  {
    value:7, label:'7th Class',
  },
  {
    value:8, label:'8th Class',
  },
  {
    value:9, label:'9th Class',
  }
]
export const DEFAULT_SECTION_OPTIONS = [
  {
    value:'A', label:'A',
  },
  {
    value:'B', label:'B',
  },
  {
    value:'C', label:'C',
  },
  {
    value:'D', label:'D',
  },
  {
    value:'E', label:'E',
  }
]


export const DEFAULT_PERIOD_OPTIONS = [
  {
    value:1, label:'1st',
  },
  {
    value:2, label:'2nd',
  },
  {
    value:3, label:'3rd',
  },
  {
    value:4, label:'4th',
  },
  {
    value:5, label:'5th',
  },
  {
    value:6, label:'6th',
  },
  {
    value:7, label:'7th',
  },
  {
    value:8, label:'8th',
  }
]
export const DEFAULT_SUBJECT_OPTIONS = [
  {
    value:'maths', label:'Maths',
  },
  {
    value:'science', label:'Science',
  },
  {
    value:'english', label:'English',
  },
  {
    value:'hindi', label:'Hindi',
  },
  {
    value:'economics', label:'Economics',
  },
  {
    value:'history', label:'History',
  },
  {
    value:'political_science', label:'Political Science',
  },
  {
    value:'computer_science', label:'Computer Science',
  },
]
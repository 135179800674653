import React, { useState, useCallback } from "react";
import { Button, LayoutContainer, Pagination, Topbar } from "../../components";
import { GoPlus } from "react-icons/go";
import { Outlet, useNavigate, useOutlet } from "react-router-dom";
import "./School.scss";
import { useGetSchool } from "../../queryHooks/School/useGetSchool/useGetSchool";
import User from "../../components/User/User";
import { Skeleton } from 'primereact/skeleton';
import SchoolItem from "./components/SchoolItem/SchoolItem";
import { useDeleteSchool } from "../../queryHooks/School/useDeleteSchool/useDeleteSchool";
import { useQueryClient } from "react-query";
import ConfirmationModal from "./modals/ChapterModal/ConfirmationModal";
import { useAuthStore } from "../../stores";

export default function School() {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(200);
  const [offset, setOffset] = useState(0);
  const [schoolId, setSchoolId] = useState(null);
  const selectedSchoolId = useAuthStore((state) => state?.school_id);
  const changeSchool = useAuthStore((state) => state?.changeSchool)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const queryClient = useQueryClient()
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

   //Get Current rows
   const indexOfLastRow = currentPage * rowsPerPage;
   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

   const onSelect = (id) => {
    changeSchool(id);
   }

  const { data: schools, error: schoolError, isLoading: schoolLoading, isFetching: schoolFetching, refetch: schoolRefetch } = useGetSchool({ limit: limit, offset: 0, selected_id: selectedSchoolId })

  const loading = schoolLoading || schoolFetching

  const currentRows = schools?.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const {deleteSchool} = useDeleteSchool();
  const NoContent = () => {
    return (
      <div className="no-content">
        <p>You haven't selected any of the profile yet.</p>
      </div>
    );
  };

  const ActionButton = () => {
    return (
      <div>
        <Button
          onClick={() => {
            navigate('./new')
          }}
          icon={<GoPlus />}
          theme={"primary"}
          style={{ marginTop: "15px" }}
        >
          Add New School
        </Button>
      </div>
    );
  };

  const SkeletonLoader = () => {
    return (
      <div className='teacher-wrapper'>
      {Array.from(Array(10).keys()).map((_, index) => {
        return <div className="user-item-container" key={_ + index} style={{backgroundColor:'transparent'}}>
        <Skeleton height='50px' width="100%" key={index} />
        </div>
      })}
    </div>
    )
  }

  const handleDeletePress = (school_id) => {
    setConfirmModalVisible(true);
    setSchoolId(school_id)
  }

  const onDelete = (school_id) => {
    deleteSchool({school_id: schoolId}).then(() => {
      setSchoolId(null);
      setConfirmModalVisible(false);
      queryClient.invalidateQueries({ queryKey: ['getSchool'] })
      setTimeout(() => {
        schoolRefetch();
      }, 500)
    })
  }

  const handleItemClick = (data) => {
    navigate(`./${data.school_id}`, { state: { ...data } });
  }

  const CreateConfirmationModal = useCallback(() => {
    return (
        <ConfirmationModal
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        text={'Are you sure you want to perform this action?'}
        onDelete={onDelete}
        />
    )
}, [confirmModalVisible])


  if(outlet)
  {
    return (
      <Outlet />
    )
  }

  return (
    <>
    <CreateConfirmationModal />
      <LayoutContainer label={"School"} RightComponent={<ActionButton />}>
      <div className="teacher-container">
          {loading ?
          <SkeletonLoader />
            : schools && schools.length !== 0 ?
              <div className='school-wrapper'>
                {currentRows?.map((item, index) => {
                  const school_id = item?.school_id;
                  const selectedSchool = selectedSchoolId == item?.school_id
                  return (
                    <SchoolItem key={index} onSelect={onSelect} selected={selectedSchool} data={item} index={index} className={'school-item'} onClick={() => handleItemClick(item)} onDelete={() => handleDeletePress(school_id)}/>
                  )
                })}
              </div> : <NoContent />}
        </div>
        <div className="footer-wrapper">
            <h4 className="show-rows">
              Showing {indexOfFirstRow === 0 ? '1' : indexOfFirstRow} to{' '}
              {indexOfLastRow} of {schools?.length} Result
            </h4>
            </div>
            {rowsPerPage <= schools?.length && (
            <Pagination
              rowsPerPage={rowsPerPage}
              totalRows={schools?.length}
              paginate={paginate}
              currentPage={currentPage}
              />
            )}
      </LayoutContainer>
    </>
  );
}

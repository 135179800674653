import React, { Children } from 'react'
import { Dialog } from 'primereact/dialog';
import Button from '../Button/Button';

export default function Modal({ header, visible, onCreate, onHide, children, confirmation, width, successText, discardText, ...rest }) {

    const footerComponent = () => {
        return (
            <div style={{width:'100%', justifyContent:'center', flexDirection:'row', display:'flex'}}>
                <div style={{width:'15%', marginRight:'15px'}}>
                    <Button theme={'primary'} onClick={onCreate}>
                        {successText ? successText : confirmation ? 'Confirm' : 'Create'}
                    </Button>
                </div>
                <div style={{width:'15%', marginLeft:'15px'}}>
                    <Button theme={'cancel'} onClick={onHide}>
                        {discardText ? discardText : confirmation ? 'Cancel' : 'Discard'}
                    </Button>
                </div>
            </div>
        )
    }
    return (
        <Dialog {...rest} header={header} visible={visible} style={{ width: width ? width : '50vw' }} onHide={onHide} footer={footerComponent}>
            {children && children}
        </Dialog>
    )
}

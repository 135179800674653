import React from "react";
import ReactSelect, {components} from "react-select";
import PropTypes from "prop-types";
import { themeStyles } from "./ThemeStyles";
import "./TableSelect.scss";

const themes = {
	normal: "",
	transparent: "",
	small: "",
};

/**
 * This component is used to render a Select Option Component.
 *
 * @component
 * @example
 * const [value, setValue] = useState("option 1")
 *
 * return (
 * 	<Select
 *  	options={[
 *			{label: "Option 1", value: "option 1"},
 *			{label: "Option 2", value: "option 2"},
 *			{label: "Option 3", value: "option 3"}
 *		]}
 *		value={value}
 *		setValue={setValue}
 *	/>
 * )
 */

const TableSelect = ({
	options,
	value,
	setValue,
	theme,
	width,
	height,
	placeholder,
	menuOnTop,
	disabled,
	isSearchable,
	setAllValue,
	...rest
}) => {
	const [normalStyles, transparentStyles] = themeStyles(width, height, menuOnTop);

	themes.normal = normalStyles;
	themes.transparent = transparentStyles;

	const setSelected = selected => {
		setValue(selected.value);
		if(setAllValue && typeof setAllValue == 'function')
		{
			setAllValue(selected);
		}
	};

	let selectedOption;

	options?.forEach(option => {
		if (option.value == value) {
			selectedOption = option;
		}
	});

	const SelectMenuButton = (props) => {
		return (
			<components.MenuList  {...props}>
				{props.children}
				<button>Add new element</button>
			</components.MenuList >
		) }

	return (
		<ReactSelect
			autosize={false}
			options={options}
			getOptionValue={options => options.value}
			value={selectedOption}
			onChange={setSelected}
			styles={themes[theme]}
			placeholder={placeholder}
			isSearchable={isSearchable}
			isDisabled={disabled}
			classNamePrefix="select"
			{...rest}
		/>
	);
};



export default TableSelect;

import React, { useState, useCallback, useMemo } from 'react'
import { Modal } from '../../../../components'
import { DEFAULT_INPUT_VALUE, INPUTS } from './constants';
import Dropzone from 'react-dropzone';
import FormInput from '../../../../components/FormInput/Input';
import { getBase64 } from '../../../../utils/base64Encode';

import './ClassModal.scss';
import { useAuthStore } from '../../../../stores';
import { useCreateClass } from '../../../../queryHooks/Classes/useCreateClass/useCreateClass';
import { useSharedToast } from '../../../../App';
import { checkEmpty } from '../../../../utils/checkEmpty';
import { useQueryClient } from 'react-query';
import { useGetBoard } from '../../../../queryHooks/Board/useGetBoard/useGetBoard';

export default function ClassModal({ visible, setVisible }) {
  const [input, setInput] = useState(DEFAULT_INPUT_VALUE)
  const [fileNames, setFileNames] = useState({});
  const user = useAuthStore(state => state.user);
  const school_id = useAuthStore(state => state.school_id)

  const { data: boardOptions, error: getBoardError, isInitialLoading: boardLoading } = useGetBoard({ school_id: school_id, enabled: school_id && true })

  const { createClass, isLoading, error } = useCreateClass();
  const { toast } = useSharedToast();



  const onHide = () => {
    setVisible(false);
  }

  const queryClient = useQueryClient();

  const onCreate = (e) => {
    e.preventDefault();
    //do nothing
    const isEmpty = checkEmpty(input)
    if (isEmpty) {
      showError();
      return false;
    }
    createClass({ inputs: input, school_id: school_id }).then(() => {
      setInput(DEFAULT_INPUT_VALUE)
      queryClient.invalidateQueries({ queryKey: ['getClass'] })
      setVisible(false);
    })
  }
  const showError = () => {
    toast?.current?.show({ severity: 'error', summary: 'Invalid Form', detail: 'Please enter all the details!' });
  };

  const handleDrop = useCallback((name, e) => {
    //access file dropped.
    const fileName = e[0].name;
    getBase64(e[0]).then((value) => {
      setFileNames(prevState => {
        return {
          ...prevState,
          [name]: fileName,
        }
      })
      setInput(prevState => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    })
  }, []);


  const CreateInputs = useMemo(() => {
    return (
      <>
        <form id="class-inputs" className='class-inputs' onSubmit={onCreate}>
          <div className="class-input-container">
            {INPUTS.map(
              (
                { label, type, options, name, placeholder, uploadButton, menuOnTop, width },
                index
              ) => {
                return (
                  <div className={`modal-form-field ${name}`} key={index}>
                    <h4 style={{ flexDirection: 'row', display: 'inline-flex' }}>{label}<p style={{ color: 'red' }}>*</p></h4>
                    <div className={`${uploadButton && "upload-present"}`}>
                      {type !== "dragNdrop" && (
                        <FormInput
                          type={type}
                          name={name}
                          {...(type == 'file' && {
                            onChange: async (e) => {
                              getBase64(e.target.files[0]).then((value) => {
                                setInput(prevState => {
                                  return {
                                    ...prevState,
                                    [name]: value,
                                  };
                                });
                              })
                            }
                          })}
                          {...(type == 'tel' && {
                            maxLength: 10
                          })}
                          width={width && width}
                          options={type === "select" && name == 'board' ? boardOptions : options ? options : []}
                          {...(type !== 'file' && { value: input })}
                          {...(type == 'file' && { accept: "image/png, image/gif, image/jpeg" })}
                          setValue={setInput}
                          required={true}
                          menuOnTop={menuOnTop && menuOnTop}
                          placeholder={placeholder && placeholder}
                        />
                      )}
                      {type === "dragNdrop" && (
                        <Dropzone onDrop={(acceptedFileItems) => handleDrop(name, acceptedFileItems)}>
                          {({ getRootProps, getInputProps }) => (
                            <section className="drop-container">
                              <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <p className="drop-text">
                                  {fileNames[name] ? fileNames[name] : placeholder}
                                </p>
                                {!fileNames[name] && <p className='drop-subtext'>
                                  {`(Drag and Drop or Click to upload)`}
                                </p>}
                              </div>
                              {input[name] ? <img src={input[name]} style={{ height: '44px', width: '44px', borderRadius: '50px', marginLeft: '10px', marginRight: '10px', alignSelf: 'center' }} /> : null}
                            </section>
                          )}
                        </Dropzone>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </form>
      </>
    )
  }, [input])

  return (
    <Modal
      header={'Create New Class'}
      headerStyle={{ textAlign: 'center' }}
      headerClassName={'modal-header'}
      visible={visible}
      onHide={onHide}
      onCreate={onCreate}
    >
      <div>
        <div>
          {CreateInputs}
        </div>
      </div>
    </Modal>
  )
}

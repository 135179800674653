export const VIDEO_INPUTS = [
  {
    label: "Upload Video",
    type: "dragNdrop",
    name: "video_file",
    uploadButton: "Upload file",
  },
  {
    label: "Upload Thumbnail",
    type: "dragNdrop",
    name: "video_image",
    uploadButton: "Upload image",
    media_type: "video",
  },
  {
    label: "Video name",
    type: "text",
    name: "video_caption",
    placeholder: "Enter the video caption",
    media_type: "video",
  },
  {
    label: "Video Description",
    type: "text",
    name: "description",
    placeholder: "Enter the video caption",
    media_type: "video",
  },
  {
    label: "Index",
    type: "text",
    name: "video_index",
    placeholder: "Enter chapter index",
  },
  {
    label: "Time Required",
    type: "duration",
    name: "time_required",
    placeholder: "Enter time required",
  },
];

export const PDF_INPUTS = [
  {
    label: "File",
    type: "dragNdrop",
    name: "file_name",
    uploadButton: "Upload file",
  },
  {
    label: "Name",
    type: "text",
    name: "note_name",
    placeholder: "Enter name of the note(s)",
  },
  {
    label: "Chapter Index",
    type: "text",
    name: "index",
    placeholder: "Enter chapter index",
  },
];

export const DEFAULT_INPUT_VALUE = {
  description: null,
  time_required: null,
  video_caption: null,
  video_file: null,
  video_image: null,
  video_index: null,
};
export const DEFAULT_NOTES_INPUT_VALUE = {
  note_name: null,
  index: null,
  file_name: null,
};

import React, { useState, useCallback, useMemo } from 'react';
import { Modal } from '../../../../components';
import { DEFAULT_INPUT_VALUE, INPUTS } from './constants';
import Dropzone from 'react-dropzone';
import FormInput from '../../../../components/FormInput/Input';
import { getBase64 } from '../../../../utils/base64Encode';

import './SectionModal.scss';
import { useAuthStore } from '../../../../stores';
import { useGetClass } from '../../../../queryHooks/Classes/useGetClass/useGetClass';
import { useCreateChapter } from '../../../../queryHooks/useCreateChapter/useCreateChapter';
import { useSharedToast } from '../../../../App';
import { useQueryClient } from 'react-query';
import { checkEmpty } from '../../../../utils/checkEmpty';
import { useCreateSection } from '../../../../queryHooks/Section/useCreateSection/useCreateSection';

export default function SectionModal({ visible, setVisible, class_id, handleCreate }) {
    const [input, setInput] = useState(DEFAULT_INPUT_VALUE);
    const [fileNames, setFileNames] = useState({});
    const user = useAuthStore(state => state.user);
    const school_id = useAuthStore(state => state.school_id)

    const { data: classOptions, error: getClassError, isInitialLoading: classLoading } = useGetClass({ school_id: school_id, enabled: school_id && true });

    const { createSection, isLoading, error } = useCreateSection();

    const { toast } = useSharedToast();

    const queryClient = useQueryClient();

    const showError = () => {
        toast?.current?.show({ severity: 'error', summary: 'Invalid Form', detail: 'Please enter all the details!' });
    };

    const onHide = () => {
        setVisible(false);
    };

    const onCreate = (e) => {
        // Add your logic for creating
        e.preventDefault();
        //do nothing
        const isEmpty = checkEmpty(input)
        if (isEmpty) {
            showError();
            return false;
        }

        handleCreate({ section_name: input?.section_name, section_lock: input?.lock_section });
        setInput(DEFAULT_INPUT_VALUE)
        // createSection({inputs: input, school_id: school_id, class_id: class_id}).then(() => {
        //     setInput(DEFAULT_INPUT_VALUE)
        //     queryClient.invalidateQueries({ queryKey: ['getClass'] })
        //     setVisible(false);
        //   })
    };

    const handleDrop = useCallback((name, e) => {
        const fileName = e[0].name;
        getBase64(e[0]).then((value) => {
            setFileNames(prevState => {
                return {
                    ...prevState,
                    [name]: fileName,
                };
            });
            setInput(prevState => {
                return {
                    ...prevState,
                    [name]: value,
                };
            });
        });
    }, []);



    const CreateInputs = useMemo(() => {
        return (
            <>
                <form id="section-inputs" className='section-inputs' onSubmit={onCreate}>
                    <div className="section-input-container">
                        {INPUTS.map(
                            (
                                { label, type, options, name, placeholder, uploadButton, menuOnTop, width },
                                index
                            ) => {
                                return (
                                    <div className={`modal-form-field ${name}`} key={index}>
                                        <h4 style={{ flexDirection: 'row', display: 'inline-flex' }}>{label}<p style={{ color: 'red' }}>*</p></h4>
                                        <div className={`${uploadButton && "upload-present"}`}>
                                            {type !== "dragNdrop" && (
                                                <FormInput
                                                    type={type}
                                                    name={name}
                                                    {...(type == 'file' && {
                                                        onChange: async (e) => {
                                                            getBase64(e.target.files[0]).then((value) => {
                                                                setInput(prevState => {
                                                                    return {
                                                                        ...prevState,
                                                                        [name]: value,
                                                                    };
                                                                });
                                                            })
                                                        }
                                                    })}
                                                    {...(type == 'tel' && {
                                                        maxLength: 10
                                                    })}
                                                    width={width && width}
                                                    options={type === "select" && name == 'class' ? classOptions : options ? options : []}
                                                    {...(type !== 'file' && { value: input })}
                                                    {...(type == 'file' && { accept: "image/png, image/gif, image/jpeg" })}
                                                    setValue={setInput}
                                                    required={true}
                                                    menuOnTop={menuOnTop && menuOnTop}
                                                    placeholder={placeholder && placeholder}
                                                />
                                            )}
                                            {type === "dragNdrop" && (
                                                <Dropzone onDrop={(acceptedFileItems) => handleDrop(name, acceptedFileItems)}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section className="drop-container">
                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                <input {...getInputProps()} />
                                                                <p className="drop-text">
                                                                    {fileNames[name] ? fileNames[name] : placeholder}
                                                                </p>
                                                                {!fileNames[name] && <p className='drop-subtext'>
                                                                    {`(Drag and Drop or Click to upload)`}
                                                                </p>}
                                                            </div>
                                                            {input[name] ? <img src={input[name]} style={{ height: '44px', width: '44px', borderRadius: '50px', marginLeft: '10px', marginRight: '10px', alignSelf: 'center' }} /> : null}
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        )}
                    </div>
                </form>
            </>
        )
    }, [input])


    return (
        <Modal
            header={'Create New Section'}
            headerStyle={{ textAlign: 'center' }}
            headerClassName={'modal-header'}
            visible={visible}
            onHide={onHide}
            onCreate={onCreate}
        >
            <div>
                <div>{CreateInputs}</div>
            </div>
        </Modal>
    );
}

import React, { useState } from "react";
import { Button, LayoutContainer, Pagination, Topbar } from "../../components";
import { GoPlus } from "react-icons/go";
import { Outlet, useNavigate, useOutlet } from "react-router-dom";
import { useGetAdminList } from "../../queryHooks/useGetAdminList/useGetAdminList";
import { useAuthStore } from "../../stores";
import { Skeleton } from 'primereact/skeleton';

import "./Dashboard.scss";
import User from "../../components/User/User";
import { getUserType } from "../../utils/getUserType";
import Loading from "../Loading/Loading";

export default function Dashboard() {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const user = useAuthStore(state => state.user);
  const school_id = useAuthStore(state => state.school_id)
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(12);

   //Get Current rows
   const indexOfLastRow = currentPage * rowsPerPage;
   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

   const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  
  const { data: adminList, error: adminError, isLoading: adminLoading, refetch: adminRefetch } = useGetAdminList({ school_id: school_id })
  
  const currentRows = adminList?.slice(indexOfFirstRow, indexOfLastRow);

  const NoContent = () => {
    return (
      <div className="no-content">
        <p>You haven't selected any of the profile yet.</p>
      </div>
    );
  };

  const ActionButton = () => {
    return (
      <div>
        <Button
          onClick={() => {
            navigate('./new-admin')
          }}
          icon={<GoPlus />}
          theme={"primary"}
          style={{ marginTop: "15px" }}
        >
          Add New Admin
        </Button>
      </div>
    );
  };

  const handleItemClick = (data) => {
    navigate(`./${data.admin_id}`, {state: { ...data } });
  }



  const SkeletonLoader = () => {
    return (
      <div className='teacher-wrapper'>
        {Array.from(Array(10).keys()).map((_, index) => {
          return <div key={index} className="user-item-container" style={{ backgroundColor: 'transparent' }}>
            <Skeleton height='125px' key={index} />
          </div>
        })}
      </div>
    )
  }

  if (outlet) {
    return (
      <Outlet />
    )
  }
  
  return (
    <>
      <LayoutContainer label={"Dashboard"} RightComponent={<ActionButton />}>
        <div className="teacher-container">
          {adminLoading ?
            <SkeletonLoader />
            : adminList && adminList.length !== 0 ?
              <div className='teacher-wrapper'>
                {currentRows?.map((item, index) => {
                  const role = getUserType(item?.admin_type)
                  return (
                    <User data={item} role={`${role}`} index={index} onClick={() => handleItemClick(item)} />
                  )
                })}
              </div> : <NoContent />}
        </div>
        {adminList?.length !== 0 && <div className="footer-wrapper">
            <h4 className="show-rows">
              Showing {indexOfFirstRow === 0 ? '1' : indexOfFirstRow} to{' '}
              {indexOfLastRow} of {adminList?.length} Result
            </h4>
            </div>}
            {rowsPerPage <= adminList?.length && (
            <Pagination
              rowsPerPage={rowsPerPage}
              totalRows={adminList?.length}
              paginate={paginate}
              currentPage={currentPage}
              />
            )}
      </LayoutContainer>
    </>
  );
}

// const RenderStudentDetails = () => {
//     return SHOW_STUDENT_FORM.map((item, index) => {
//         if (state[item.name]) {
//             if (item.name == 'class_id' && state['section']) {
//                 return <LabelItem label={'Class'} value={`${classLabel} ${sectionLabel}`} />
//             }
//             return <LabelItem label={item.label} value={`${toTitleCase(state[item.name])}`} />
//         }
//     })
// }

// const RenderActionButtons = ({onSubmit}) => {
//     return (
//       <div style={{ display: 'flex', flex:0.5, alignItems:'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
//         <div style={{ width: '20%', margin: '10px' }}>
//           <Button type="submit" form="student-inputs" theme={'primary'} onClick={onSubmit}>
//             {'Edit'}
//           </Button>
//         </div>
//       </div>
//     )
//   }


// const handleEdit = () => {
//     navigate('../new', {state : {...state, mode: 'edit'}});
// }

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, DataHeader, LabelItem, LayoutContainer } from '../../../../components';
import { SHOW_STUDENT_FORM } from './constants';

import { useGetClass } from '../../../../queryHooks/Classes/useGetClass/useGetClass';
import { useAuthStore } from '../../../../stores';
import { toTitleCase } from '../../../../utils/toTitleCase';
import { useGetAssignedToTeacher } from '../../../../queryHooks/useGetTeachers/useGetAssignedToTeacher';


import './TeacherProfile.scss';
import Table from '../../components/Table/Table';
import { convertToOrdinal } from '../../../../utils/convertToOrdinal';
import { useGetSection } from '../../../../queryHooks/Section/useGetSection/useGetSection';

export default function TeacherProfile() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const user = useAuthStore(state => state.user);
    const school_id = useAuthStore(state => state.school_id);
    const fullName = `${state?.first_name} ${state?.last_name}`;
    const email_id = state?.user_master?.email_id || '-';
    const password = state?.user_master?.password.substring(0,8) || '-';
    const [classId, setClassId] = useState(state?.class_id);
    const [classLabel, setClassLabel] = useState(null);
    const [sectionLabel, setSectionLabel] = useState(null);
    const [sectionId, setSectionId] = useState(state?.section);
    const { data: classOptions, error: getClassError, isLoading: classLoading } = useGetClass({ school_id: school_id, enabled: school_id && true });
    const { data: sectionOptions, error: sectionError, isLoading: sectionLoading, refetch: sectionRefetch } = useGetSection({ class_id: classId, enabled: school_id && true });
    const { data: assigned, error: assignedError, isLoading: assignedLoading, refetch: assignedRefetch } = useGetAssignedToTeacher({ teacher_id: state?.teacher_id });

    // Calculate noOfRows based on the length of assigned.data
    const noOfRows = assigned ? assigned.length : 0;

    // Define RenderProfileRowItem function
    const RenderProfileRowItem = ({ row }) => {
        return columnHeader.map((column, i) => {
            const path = column.path;
            return (
                <td key={i}>
                    <RenderProfileDataByType row={row} type={path} />
                </td>
            );
        });
    };

    // Define RenderProfileDataByType function
    const RenderProfileDataByType = ({ row, type }) => {
        const getDataByType = (type) => {
            switch (type) {
                case 'subject':
                    return assigned[row].subject_masters ? assigned[row].subject_masters[0].subjectName || 'N/A' : 'N/A';
                case 'class':
                    const key= assigned[row].hasOwnProperty("class_master")?"class_master":"class_masters"
                    return assigned[row] ? `${assigned[row][key].class_name} Class` || 'N/A' : 'N/A';
                case 'section':
                    return assigned[row].section_masters ? assigned[row].section_masters?.section_name || 'N/A' : 'N/A';
                default:
                    return 'N/A';
            }
        };

        return <p>{getDataByType(type)}</p>;
    };

    // Define columnHeader as you provided
    const columnHeader = [
        { path: 'class', title: 'Class' },
        { path: 'section', title: 'Section' },
    ];

    const goBack = () => {
        navigate("../");
    };

    const ActionButton = () => {
        return (
            <div>
                <Button
                    onClick={goBack}
                    theme={"secondary cancel"}
                    style={{ marginTop: "15px" }}
                >
                    Exit
                </Button>
            </div>
        );
    };

    useEffect(() => {
        if (sectionOptions && classOptions) {
            handleClass().then((classData) => {
                handleSection().then((sectionData) => {
                    setSectionLabel(sectionData?.section_name);
                    setClassLabel(classData?.class_name);
                });
            });
        }
    }, [classOptions]);

    const handleClass = () => {
        return new Promise((resolve, reject) => {
            const classData = classOptions?.filter((item, index) => {
                if (item.class_id == state?.class_id) {
                    resolve(item);
                }
            });
        });
    };

    const handleSection = () => {
        return new Promise((resolve, reject) => {
            sectionOptions?.filter((item, index) => {
                if (item.section_id == state?.section) {
                    resolve(item);
                }
            });
        });
    };

    const RenderStudentDetails = () => {
        return SHOW_STUDENT_FORM.map((item, index) => {
            if (state[item.name]) {
                if (item.name == 'class_id' && state['section']) {
                    return <LabelItem label={'Class'} value={`${classLabel} ${sectionLabel}`} />;
                }
                return <LabelItem label={item.label} value={`${toTitleCase(state[item.name])}`} />;
            }
        });
    };

    const RenderActionButtons = ({ onSubmit }) => {
        return (
            <div style={{ display: 'flex', flex: 0.5, alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
                <div style={{ width: '20%', margin: '10px' }}>
                    <Button type="submit" form="student-inputs" theme={'primary'} onClick={onSubmit}>
                        {'Edit'}
                    </Button>
                </div>
            </div>
        );
    };

    if (classLoading && sectionLoading) {
        return null;
    }

    return (
        <LayoutContainer label={`${fullName}`} RightComponent={<ActionButton />}>
            <DataHeader role={'Teacher'} name={fullName} email_id={email_id} password={password} imageSrc={state.avtar}>
                {/* <div className='student-label-container'>
                <RenderStudentDetails />
                </div> */}
            </DataHeader>
            {/* <RenderActionButtons onSubmit={handleEdit}/> */}
            <div style={{
                marginTop:'20px',
            }}>
            <Table
                columnHeader={columnHeader}
                noOfRows={noOfRows}
                renderRow={(row) => <RenderProfileRowItem row={row} />}
                />
                </div>
        </LayoutContainer>
    );
}

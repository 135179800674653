import { useMutation } from 'react-query';
import { useSharedToast } from '../../../App';
import { BASE_URL } from '../../../Config';
import request from '../../../utils/request';


const createSectionDetails = async ({ board_id, section_name, section_lock, class_id, school_id}) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/class/createsection`,
        method: `POST`,
        data: {
            class_id:class_id,
            section:section_name,
            school_id:school_id,
            section_lock: section_lock,
        },
    })
    return queryResponse;
}


export const useCreateSection = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: createSection, isLoading, error } = useMutation(createSectionDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Section created successfully!' });
            }
        },
        onError: (error) => {
            console.log(error, 'error here');
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error creating the section.' });
        },
    });

    return {
        createSection: createSection,
        isLoading,
        error,
    };
};

import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';
import { useSharedToast } from '../../App';
import { convertToBase64Readable } from '../../utils/base64Encode';

const splitName = (name = '') => {
    const [firstName, ...lastName] = name.split(' ').filter(Boolean);
    return {
        firstName: firstName,
        lastName: lastName.join(' '),
    };
};

/* 
 "first_name":"shiv",
  "address":"testing adderess",
  "middle_name":"",
  "last_name":"kumar",
  "school_id":"school_id6268bd7d0d18",
  "class_id":"class_9207d31b0cca",
  "section":"1",
  "guardian_name":"shiv ji",
  "contact_no":"8700300731",
  "id_no":"1",
  "dob":"",
  "email_id":"shivkr98188+student2@gmail.com",
  "password":"123456789",
  "gender":"Male",
  "idNo":"1",
  "avtar":"",
  "bus_no":"1"
  */


const updateStudentDetails = async ({ inputs, student_id }) => {
    const fullName = inputs?.name ? splitName(inputs?.name) : null;
    const dob = inputs?.DD && inputs?.MM && inputs?.YYYY ? `${inputs?.DD}/${inputs?.MM}/${inputs?.YYYY}` : null;
    let updatedObj = {
        ...(student_id && { student_id: student_id }),
        ...(fullName.firstName && { first_name: fullName.firstName }),
        ...(fullName.lastName && { last_name: fullName.firstName }),
        ...(inputs.class && { class_id: inputs.class }),
        ...(inputs.section && { section: inputs.section }),
        ...(inputs.mother_name && { guardian_name: inputs.mother_name }),
        ...(inputs.contact_no && { contact_no: inputs.contact_no }),
        ...(inputs.id_no && { id_no: inputs.id_no }),
        ...(dob && { dob: dob }),
        ...(inputs.email_id && { email_id: inputs.email_id }),
        ...(inputs.password && { password: inputs.password }),
        ...(inputs.gender && { gender: inputs.gender }),
        ...(inputs.id_no && { idNo: inputs.id_no }),
        ...(inputs.photo && { avtar: convertToBase64Readable(inputs.photo) }),
        ...(inputs.bus_no && { bus_no: inputs.bus_no }),
    }
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/student/update`,
        method: `PATCH`,
        data: {
           ...updatedObj
        },
    })
    return queryResponse;
}


export const useUpdateStudent = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: updateStudent, isLoading, error } = useMutation(updateStudentDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status === 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Student profile Updated successfully!' });
            }
        },
        onError: (error) => {
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error updating the student profile.' });
        },
    });

    return {
        updateStudent,
        isLoading,
        error,
    };
};

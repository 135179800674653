import React from "react";
import "./InputDate.scss";
import Input from "../../Input";
import { DateData } from "../../constants";

const InputDate = ({ input, setInput, className, customDateData, ...rest }) => {
	return (
		<div className={`input-date ${className}`} {...rest}>
			<div className="input-fields">
				{!customDateData ? DateData.map(obj => {
					return (
						<div className={obj.name} key={obj.name}>
							<Input
								type="select"
								name={obj.name}
								options={obj.options}
								width="100%"
								value={input}
								setValue={setInput}
								placeholder={obj.name}
								menuOnTop
							/>
						</div>
					);
				}): customDateData.map(obj => {
					return (
						<div className={obj.name} key={obj.name}>
							<Input
								type="select"
								name={obj.name}
								options={obj.options}
								width="100%"
								value={input}
								setValue={setInput}
								placeholder={obj.name}
							/>
						</div>
					)
				})}
			</div>{" "}
		</div>
	);
};


export default InputDate;

import { useMutation } from "react-query";
import request from "../../utils/request";
import { BASE_URL } from "../../Config";
import { useSharedToast } from "../../App";

const deleteClassDetails = async ({ lessonPlan_id }) => {
  const queryResponse = await request({
    url: `${BASE_URL}/api/admin/topic/deletelessionPlan`,
    method: `DELETE`,
    data: {
      lessonPlan_id: lessonPlan_id,
    },
  });
  return queryResponse;
};

export const useDeleteLessonPlan = () => {
  const { toast } = useSharedToast();
  const {
    mutateAsync: deleteClass,
    isLoading,
    error,
  } = useMutation(deleteClassDetails, {
    onSuccess: (data) => {
      // Success actions
      if (data.status == 200) {
        toast?.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Video deleted successfully!",
        });
      }
    },
    onError: (error) => {
      console.log(error, "error here");
      // Error actions
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Oops! there was an error deleting the Class.",
      });
    },
  });

  return {
    deleteClass: deleteClass,
    isLoading,
    error,
  };
};

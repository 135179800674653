import React from "react";
import "./contentViewer.css";
import lessonVideos from "../../assets/icons/lectures/Circled Play.svg";
import presentation from "../../assets/icons/lectures/Microsoft PowerPoint.svg";
import testSeries from "../../assets/icons/lectures/Exam.svg";
import descision from "../../assets/icons/lectures/Decision.svg";
import classroom from "../../assets/icons/lectures/Classroom.svg";
import { AiOutlineDelete } from "react-icons/ai";
export default function ContentViewer({
  url = "",
  id = "",
  title = "",
  time = "",
  type = "",
  description = "",
  handleDeleteButton = () => {},
  item = null,
}) {
  const timeAgo = (date1, date2) => {
    const timestamp1 = new Date(date1).getTime();
    const timestamp2 = new Date(date2).getTime();
    const difference = Math.abs(timestamp2 - timestamp1);

    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30.44); // Approximate number of days in a month
    const years = Math.floor(days / 365.25); // Approximate number of days in a year

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
    } else {
      return "Just now";
    }
  };
  const renderIcon =
    type === 1
      ? lessonVideos
      : type === 2
      ? presentation
      : type === 3
      ? testSeries
      : type === 4
      ? descision
      : classroom;
  const showPdfView = type !== 1 && type !== 2;
  return (
    <div className="width-widow">
      <div>
        {!showPdfView && (
          <video
            src={url}
            title={title}
            height={378}
            width={693}
            className="content-viewer-dimensions"
            controls
          ></video>
        )}{" "}
      </div>
      <div className="container-main-flex-title">
        <div className="flex-normal">
          <img src={renderIcon} alt={title} />
          <div className="content-viewer-title">
            {title}
            <div className="content-time-stmap">
              {timeAgo(item.date_created, new Date())}
            </div>
          </div>
        </div>
        <div>
          <button
            className="delete-button"
            onClick={() => handleDeleteButton(item)}
          >
            <AiOutlineDelete />
          </button>
        </div>
      </div>
      <div className={` ${type !== 5 && "content-description"} `}>
        {description}
      </div>
      {showPdfView && url && (
        <iframe
          title={title}
          height={653}
          width={"100%"}
          src={`http://docs.google.com/gview?url=${url}&embedded=true`}
          frameborder="0"
        ></iframe>
      )}
    </div>
  );
}

export const INPUTS = [
  {
    label: "Topic Name",
    type: "text",
    name: "topic_name",
    placeholder: "Enter your Topic Name",
  },
  {
    label: "Topic Index",
    type: "text",
    name: "topic_index",
    placeholder: "Enter topic index",
  },
  {
    label: "Time Required",
    type: "duration",
    name: "time_required",
    placeholder: "Enter time required",
  },
];

export const DEFAULT_INPUT_VALUE = {
  topic_name: null,
  topic_index: null,
  time_required: null,
};

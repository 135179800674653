import { useMutation } from 'react-query';
import request from '../../../utils/request';
import { BASE_URL } from '../../../Config';
import { useSharedToast } from '../../../App';
import { convertToBase64Readable } from '../../../utils/base64Encode';
import { convertTo24Hour } from '../../../utils/convertTo24Hour';


const deleteBoardDetails = async ({ inputs, board_id }) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/school/deleteBoard`,
        method: `DELETE`,
        data: {
            board_id:board_id,
            // lock: inputs.lock
        },
    })
    return queryResponse;
}


export const useDeleteBoard = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: deleteBoard, isLoading, error } = useMutation(deleteBoardDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Board deleted successfully!' });
            }
        },
        onError: (error) => {
            console.log(error, 'error here');
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error deleting the Board.' });
        },
    });

    return {
        deleteBoard: deleteBoard,
        isLoading,
        error,
    };
};

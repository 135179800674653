import React from 'react';
import './Input.scss';

const Input = ({
    value,
    label,
    name,
    placeholder,
    type,
    onChange,
    required,
    variant,
    disabled,
    icon,
    ...rest
}) => {
    return (
        <div className="item-group">
            {label && <label className="label-style">{label}</label>}
            <div className='input-wrapper'>
            <input
                className={`input-style ${variant} ${disabled ? 'input-disabled' : ''}`}
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
                {...rest}
                />
           {icon && <span className='input-icon'>
                {icon}
            </span>}
                </div>
        </div>
    );
};

export default Input;

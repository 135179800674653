import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';
import { useSharedToast } from '../../App';
import { convertToBase64Readable } from '../../utils/base64Encode';
import { convertTo24Hour } from '../../utils/convertTo24Hour';

const createTopicDetails = async ({ inputs, class_id, subject_id, chapter_id }) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/topic/create`,
        method: `POST`,
        data: {
            chapter_id: chapter_id,
            class_id: class_id,
            subject_id: subject_id,
            time: inputs.time_required,
            topic_name: inputs.topic_name,
        },
    })
    return queryResponse;
}


export const useCreateTopic = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: createTopic, isLoading, error } = useMutation(createTopicDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Topic created successfully!' });
            }
        },
        onError: (error) => {
            console.log(error, 'error here');
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error creating the Topic.' });
        },
    });

    return {
        createTopic: createTopic,
        isLoading,
        error,
    };
};

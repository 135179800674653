export function convertToOrdinal(num) {
  const number = typeof num == 'string' ? Number(num) : 0;
  if (number === 0) {
    return "0";
  }

  var suffixes = ["th", "st", "nd", "rd"];
  var lastDigit = number % 10;
  var secondLastDigit = Math.floor((number % 100) / 10);

  // If the number ends in 11, 12, or 13, use "th" suffix
  if (secondLastDigit === 1 || lastDigit > 3) {
    return number + "th";
  }

  // Use appropriate suffix from the suffixes array
  var suffix = suffixes[lastDigit] || "th";
  return number + suffix;
}
export const checkEmpty = (obj) => {
    let emptyKey = 0;
    Object.values(obj).every((x) => {
        if(x === null || x === '')
        {
            emptyKey++;
        }
        return true;
    })
    return emptyKey == 0 ? false : true
}

export const checkAllNull = (obj) => {
    let emptyKey = 0;
    Object.values(obj).every((x) => {
        if(x === null || x === '')
        {
            emptyKey++;
        }
        return true;
    })
    return emptyKey == Object.keys(obj).length ? true : false
}
import React from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import './BoardItem.scss';

const BoardItem = ({ onClick, labelName, showDeleteButton, onDeleteButtonClick, selected}) => {
const classNames = `board-item ${selected ? 'selected' : ''}`;

const handleDeleteButton = (e) => {
    e.stopPropagation();
    if(onDeleteButtonClick && typeof(onDeleteButtonClick) == 'function')
    {
        onDeleteButtonClick();
    }
}
  return (
    <div className={classNames} onClick={onClick}>
      <div className="label">
        <span className="label-text">{labelName}</span>
      </div>
      {showDeleteButton && (
        <button className="delete-button" onClick={handleDeleteButton}>
          <AiOutlineDelete />
        </button>
      )}
    </div>
  );
};

export default BoardItem;

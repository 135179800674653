import React, { useMemo, useState } from 'react'
import { FiUploadCloud } from 'react-icons/fi';
import { Button, LayoutContainer } from '../../../../components';
import { useLocation, useNavigate } from 'react-router-dom';


import { useSharedToast } from '../../../../App';
import { CREATE_CIRCULAR_FORM, DEFAULT_FORM_DATA } from './constants';
import FormInput from '../../../../components/FormInput/Input';

import { checkEmpty } from '../../../../utils/checkEmpty';
import { useCreateStudent } from '../../../../queryHooks/useCreateStudent/useCreateStudent';
import { getBase64 } from '../../../../utils/base64Encode';
import { useGetClass } from '../../../../queryHooks/Classes/useGetClass/useGetClass';
import { useGetSection } from '../../../../queryHooks/Section/useGetSection/useGetSection';
import { useEffect } from 'react';
import { useAuthStore } from '../../../../stores';
import './CreateCircular.scss';
import { useCreateCircular } from '../../../../queryHooks/useCreateCircular/useCreateCircular';

export default function CreateCircular() {
  const navigate = useNavigate();
  const {state} = useLocation();
  const PROPS_DATA = state ? {
   event_type:state.event_type,
   event_date:state.event_date,
   event_time:state.event_time,
   document:state.document,
   description: state.description,
   event_name:state.event_name
  } : null;
  
  const [input, setInput] = useState(PROPS_DATA || DEFAULT_FORM_DATA);
  
  const user = useAuthStore(state => state.user);
  const school_id = useAuthStore(state => state.school_id)
  const { createCircular, isLoading, error } = useCreateCircular();

  const {toast} = useSharedToast();

  const goBack = () => {
    navigate("../");
  }
  
  const ActionButton = () => {
    return (
      <div>
        <Button
          onClick={goBack}
          theme={"secondary cancel"}
          style={{ marginTop: "15px" }}
        >
          Exit
        </Button>
      </div>
    );
  };

  const showSuccess = () => {
    toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Student profile created successfully!' });
};

  const showError = () => {
    toast?.current?.show({ severity: 'error', summary: 'Invalid Form', detail: 'Please enter all the details!' });
};

  const handleSubmit = (e) => {
    e.preventDefault();
    //do nothing
    const isEmpty = checkEmpty(input)
    if(isEmpty)
    {
      showError();
      return false;
    }
    createCircular({inputs: input, school_id: school_id}).then(() => {
      goBack();
      setInput(DEFAULT_FORM_DATA);
    })
    // setTimeout(() => {
    //   goBack();
    // }, 500)

  }

  const RenderActionButtons = ({onSubmit}) => {
    return (
      <div style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
      <div style={{ width: '20%', margin: '10px', position: 'absolute', bottom: 10, right: 10 }}>
          <Button type="submit" form="student-inputs" theme={'primary'} onClick={onSubmit}>
            {'Submit'}
          </Button>
        </div>
      </div>
    )
  }
  const CreateInputs = useMemo(() => {
    return (
      <>
      <form id="student-inputs" className='student-inputs' onSubmit={handleSubmit}>
        <div className="student-input-container">
          {CREATE_CIRCULAR_FORM.map(
            (
              { label, type, options, name, placeholder, uploadButton, menuOnTop, width, height, customDateData,},
              index
            ) => {
              return (
                <div className={`form-field ${name}`} key={index}>
                  <h4 style={{ flexDirection: 'row', display: 'inline-flex' }}>{label}<p style={{ color: 'red' }}>*</p></h4>
                  <div className={`${uploadButton && "upload-present"}`}>
                    {type !== "dragNdrop" && (
                      <FormInput
                        type={type}
                        name={name}
                        customDateData={customDateData && customDateData}
                        {...(type == 'file' && {
                          onChange: async (e) => {
                           getBase64(e.target.files[0]).then((value) => {
                             setInput(prevState => {
                               return {
                                 ...prevState,
                                 [name]: value,
                                };
                              });
                            })
                          }
                        })}
                        {...(type == 'tel' && {
                          maxLength:10
                        })}
                        width={width && width}
                        height={height && height}
                        options={type === "select" && options ? options : []}
                        {...(type !== 'file' && { value: input })}
                        {...(type == 'file' && { accept: "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"})}
                        setValue={setInput}
                        required={true}
                        menuOnTop={menuOnTop && menuOnTop}
                        placeholder={placeholder && placeholder}
                      />
                    )}
                    {type === "dragNdrop" && (
                      <div className="dragNdrop">{placeholder}</div>
                    )}
                    {uploadButton && (
                      <Button theme={"blue-outline-button"} icon={<FiUploadCloud color={'green'} />}>
                        {uploadButton}
                      </Button>
                    )}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </form>
      </>
    )
  }, [input])

  return (
    <LayoutContainer
    label={"Create New Circular"}
    RightComponent={<ActionButton />}
    >
      {CreateInputs}
    <RenderActionButtons onSubmit={handleSubmit}/>
    </LayoutContainer>
  )
}

import { useQuery } from "react-query";
import request from "../../utils/request";
import { BASE_URL } from "../../Config";


const getCirculars = async ({ queryKey }) => {
    const [_, data] = queryKey; // eslint-disable-line no-unused-vars
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/class/getevent?school_id=${data}`,
        method: 'GET',
    })
    return queryResponse;
}

export const useGetCirculars = ({ school_id, enabled }) => {
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getCirculars', school_id],
        queryFn: getCirculars,
        enabled: school_id ? true : false,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;
    return { data: responseData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
import React from "react";
import "./InputNumber.scss";
import { GoArrowSmallDown as ArrowDown } from "react-icons/go";
const InputNumber = ({
  type,
  value,
  setValue,
  name,
  width,
  height,
  onChange,
  className,
  small,
  showArrows,
  max,
  ...rest
}) => {
  const checkObj = typeof value === "object";

  const changeValue = (integer) => {
    if (checkObj) {
      if (!(value[name] <= 0 && integer === -1)) {
        setValue((prevState) => {
          return {
            ...prevState,
            [name]: parseInt(value[name]) + integer,
          };
        });
      }
    } else {
      if (!(value <= 0 && integer === -1)) {
        setValue(parseInt(value + integer));
      }
    }
  };

  return (
    <div
      className={`input-number-container ${className}`}
      style={{ width, height }}
    >
      <input
        value={checkObj ? value[name] : value}
        min="0"
        max={max && max}
        onChange={onChange}
        name={name}
        type={type}
        className={`input ${small && "small"}`}
        {...rest}
      />
      {/* {showArrows && (
        <>
          <button
            className={`arrow-up ${small && "small"}`}
            onClick={() => {
              changeValue(1);
            }}
          >
            <ArrowDown
              style={{ transform: "rotate(180deg)" }}
              color={"#7A7A9D"}
            />
          </button>
          <button
            className={`arrow-down ${small && "small"}`}
            onClick={() => {
              changeValue(-1);
            }}
          >
            <ArrowDown color={"#7A7A9D"} />
          </button>
        </>
      )} */}
    </div>
  );
};

export default InputNumber;

export  const getUserType = (type) => {
    switch (type) {
      case 'STUDENT':
        return 'Student'
      case 'ADMIN':
        return 'Admin'
      case 'super_admin':
        return 'Super Admin'
      default:
        return 'Supervisor Admin'
    }
  }
import { DEFAULT_CLASS_OPTIONS, DEFAULT_SECTION_OPTIONS } from "../../../Teacher/sub-pages/CreateTeacher/constants";

export const CREATE_BUS_OPERATOR_FORM = [
    {
        label: "Full Name",
        type: "text",
        name: "name",
        placeholder: "Enter Full name",
    },
    {
        label: "Enter username",
        type: "text",
        name: "username",
        placeholder: "Enter username name",
    },
    {
        label: "Select Bus No.",
        type: "select",
        name: "bus_no",
        placeholder: "Select Bus No",
    },
    {
        label: "Gender",
        type: "select",
        options: [
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
            { value: "others", label: "Others" },
        ],
        name: "gender",
    },
    {
        label: "Enter ID No.",
        type: "text",
        name: "idno",
        placeholder: "Enter ID No",
    },
    {
        label: "Address",
        type: "textarea",
        name: "address",
        placeholder: "Enter Address",
    },
    {
        label: "Upload Photo",
        type: "dragNdrop",
        name: "photo",
        placeholder: "Upload your photo",
		uploadButton: "Upload Photo",
    },
    {
        label: "Driving License No",
        type: "text",
        name: "Dl_no",
        placeholder: "Enter DL No.",
    },
    {
        label: "Email ID",
        type: "text",
        name: "email_id",
        placeholder: "Enter Email ID",
    },
    {
        label: "Password",
        type: "password",
        name: "password",
        placeholder: "Enter Password",
    },
    {
        label: "Contact No",
        type: "tel",
        name: "contact_no",
        placeholder: "Enter your Contact",
        width:'100%'
    },
    {
        label: "Emergency Contact No",
        type: "tel",
        name: "emergency_no",
        placeholder: "Enter your Emergency Contact",
        width:'100%'
    },
];

export const DEFAULT_FORM_DATA = {
    name: "",
    username:"",
    bus_no:"",
    address: "",
    gender: null,
    idno:'',
    address:'',
    photo:null,
    Dl_no:'',
    password:'',
    email_id:'',
    contact_no:'',
    emergency_no:'',
};

export const FILE_INPUT_NAME = 'photo';

/*
full_name
username
bus_no
gender
idno
address
avtar
Dl_no
email_id
password
contact_no
emergency_no
*/
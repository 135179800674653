import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes as InnerRoute,
  RouterProvider,
  createBrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "../pages/Login/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import { Sidebar } from "../components";
import { useAuthStore } from "../stores";
import Teacher from "../pages/Teacher/Teacher";
import Classes from "../pages/Classes/Classes";
import Student from "../pages/Student/Student";
import { AnimatePresence } from "framer-motion";
import CreateTeacher from "../pages/Teacher/sub-pages/CreateTeacher/CreateTeacher";
import { decodeJwt } from "../utils/decodeJwt";
import CreateAdmin from "../pages/Admin/CreateAdmin/CreateAdmin";
import CreateStudent from "../pages/Student/sub-pages/CreateStudent/CreateStudent";
import Circular from "../pages/Circular/Circular";
import StudentProfile from "../pages/Student/sub-pages/StudentProfile/StudentProfile";
import TeacherProfile from "../pages/Teacher/sub-pages/TeacherProfile/TeacherProfile";
import CreateCircular from "../pages/Circular/sub-pages/CreateCircular.js/CreateCircular";
import CircularProfile from "../pages/Circular/sub-pages/CircularProfile/CircularProfile";
import { useGetAdminProfile } from "../queryHooks/useGetAdminProfile/useGetAdminProfile";
import School from "../pages/School/School";
import CreateSchool from "../pages/School/sub-pages/CreateSchool/CreateSchool";
import SchoolProfile from "../pages/School/sub-pages/SchoolProfile/SchoolProfile";
import BusOperator from "../pages/BusOperator/BusOperator";
import CreateBusOperator from "../pages/BusOperator/sub-pages/CreateBusOperator/CreateBusOperator";
import Bus from "../pages/Bus/Bus";
import CreateBus from "../pages/Bus/sub-pages/CreateBus/CreateBus";
import BusProfile from "../pages/Bus/sub-pages/BusProfile/BusProfile";
import BusOperatorProfile from "../pages/BusOperator/sub-pages/CreateBusOperator/BusOperatorProfile/BusOperatorProfile";
import Lecture from "../pages/Lecture/Lecture";
import Chapters from "../pages/Chapters/Chapters";
import AdminProfile from "../pages/Dashboard/sub-pages/AdminProfile/AdminProfile";
import Loading from "../pages/Loading/Loading";
import Topics from "../pages/Topics/Topics";
import ClassModule from "../pages/ClassModule/ClassModule";
import loaderStore from "../stores/useLoader";
import ModalWithLoader from "../components/Modal/loaderModal";
import LessonFlow from "../pages/lesson_flow/lesson_flow";

export default function Routes(props) {
  const authenticated = useAuthStore((state) => state.authenticated);
  const location = useLocation();
  const { isLoading: loading } = useGetAdminProfile({
    token: localStorage.getItem("accessToken"),
  });
  const selectedSchool = useAuthStore((state) => state.school_id);

  const loader = loaderStore((state) => state.loader);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
  ]);

  if (loading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <AnimatePresence mode="wait">
      {loader && <ModalWithLoader />}
      {authenticated ? (
        <>
          <Sidebar />
          <InnerRoute location={location} key={location.pathname}>
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="new-admin" element={<CreateAdmin />} />
              <Route path=":adminId" element={<AdminProfile />} />
            </Route>
            <Route path="lecture" element={<Lecture />}>
              <Route path=":subject" element={<Classes />} />
              <Route path=":subject/:chapter" element={<Chapters />} />
              <Route path=":subject/:chapter/:topic" element={<LessonFlow />} />
              {/* <Route path=":subject/:chapter/:topic" element={<Topics />} /> */}
              {/* <Route path=":subject:chapter:topic" element={<CircularProfile />} /> */}
            </Route>
            <Route path="school" element={<School />}>
              <Route path=":schoolId" element={<SchoolProfile />} />
              <Route path="new" element={<CreateSchool />} />
            </Route>
            {selectedSchool !== "null" && (
              <>
                <Route path="teacher" element={<Teacher />}>
                  <Route path=":teacherId" element={<TeacherProfile />} />
                  <Route path="new" element={<CreateTeacher />} />
                </Route>
                <Route path="student" element={<Student />}>
                  <Route path=":studentId" element={<StudentProfile />} />
                  <Route path="new" element={<CreateStudent />} />
                </Route>
                <Route path="operator" element={<BusOperator />}>
                  <Route path=":busOpId" element={<BusOperatorProfile />} />
                  <Route path="new" element={<CreateBusOperator />} />
                </Route>
                <Route path="bus" element={<Bus />}>
                  <Route path=":busId" element={<BusProfile />} />
                  <Route path="new" element={<CreateBus />} />
                </Route>
                <Route path="class" element={<ClassModule />}>
                  <Route path=":id" element={<ClassModule />} />
                </Route>
                <Route path="circular" element={<Circular />}>
                  <Route path=":circularId" element={<CircularProfile />} />
                  <Route path="new" element={<CreateCircular />} />
                </Route>
              </>
            )}

            <Route path="*" element={<Navigate to="/dashboard" />} />
          </InnerRoute>
        </>
      ) : (
        <>
          <InnerRoute location={location} key={location.pathname}>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
          </InnerRoute>
        </>
      )}
    </AnimatePresence>
  );
}

import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';

const assign = async ({ teacher_id, class_id, section_id,period,subject_id}) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/teacher/assign_class`,
        method: `POST`,
        data: {
            teacher_id: teacher_id,
            class_id: class_id,
            section_id: section_id,
            period:period.toString(),
            subject_id
        },
    })
    return queryResponse;
}

export const useAssignToTeacher = () => {
    const { mutate: assignToTeacher, isLoading, error } = useMutation(assign, {
        onSuccess: (data) => {
            // Success actions
            if(data.status == 200)
            {
                return data;
            }
            throw {data: data};
        },
        onError: (error) => {
            console.log(error, 'assign teacher error');
            // Error actions
            throw error
        },
    });

    return {
        assignToTeacher,
        isLoading, 
        error,
    };
};

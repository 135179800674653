import React, { useState } from 'react'
import { Button, LayoutContainer, Pagination, Topbar } from '../../components'
import { GoPlus } from 'react-icons/go';
import { Outlet, useNavigate, useOutlet } from 'react-router-dom';
import { useGetCirculars } from '../../queryHooks/useGetCirculars/useGetCirculars';
import { useAuthStore } from '../../stores';

import './Circular.scss';
import moment from 'moment';

export default function Circular() {
  const navigate = useNavigate();
  const outlet = useOutlet();

  const user = useAuthStore(state => state.user);
  const school_id = useAuthStore(state => state.school_id)
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(6);

  //Get Current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const { data: circulars, error: circularError, isLoading: circularLoading, refetch: circularRefetch } = useGetCirculars({ school_id: school_id })
  const currentRows = circulars?.slice(indexOfFirstRow, indexOfLastRow);

  const navigateToCreate = () => {
    navigate(`./new`)
  }
  const ActionButton = () => {
    return (
      <div>
        <Button
          onClick={navigateToCreate}
          icon={<GoPlus />}
          theme={"primary"}
          style={{ marginTop: "15px" }}
        >
          Create Circular
        </Button>
      </div>
    );
  };
  const handleItemClick = (data) => {
    navigate(`./${data.event_Id}`, { state: { ...data } });
  }

  const CircularItem = ({ item, index, onClick }) => {
    return (
      <div className={'circular-item-container'} key={index} onClick={onClick}>
        <div>
          <div className={`badge-item ${item.event_type == 'Extra Curricular' ? 'extra' : 'holiday'}`}>
            <p>{item.event_type}</p>
          </div>
        </div>
        <div>
          <p className='circular-item-date'>{moment(item.event_date).format('DD MMMM, YYYY')}</p>
        </div>
        <div>
          <p className='circular-item-desc'>{item.description}</p>
        </div>
      </div>
    )
  }
  if (outlet) {
    return <Outlet />
  }

  return (
    <LayoutContainer label={"Circular"} RightComponent={<ActionButton />}>
      <div className='circular-container'>
        {circulars && circulars.length !== 0 ? <div className='circular-wrapper'>
          {currentRows?.map((item, index) => {
            return (
              <CircularItem item={item} index={index} onClick={() => handleItemClick(item)} />
            )
          })}
        </div>
          :
          null}
      </div>
      {circulars?.length !== 0 && <div className="footer-wrapper">
        <h4 className="show-rows">
          Showing {indexOfFirstRow === 0 ? '1' : indexOfFirstRow} to{' '}
          {indexOfLastRow} of {circulars?.length} Result
        </h4>
      </div>}
      {rowsPerPage <= circulars?.length && (
        <Pagination
          rowsPerPage={rowsPerPage}
          totalRows={circulars?.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </LayoutContainer>
  )
}

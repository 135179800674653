import React from "react";
import Select from "../../Select/Select";
import InputNumber from "./InputNumber/InputNumber";
import InputTime from "./InputTime/InputTime";
import InputDate from "./InputDate/InputDate";
import { Calendar } from 'primereact/calendar';
import InputDuration from "./InputDuration/InputDuration";

const InputMultiple = ({ type, value, setValue, name, width, className, small, ...rest }) => {
	const onChange = e => {
		setValue(prevState => {
			return {
				...prevState,
				[e.target.name]: e.target.value,
			};
		});
	};
	
	const timeOnChange = e => {
		setValue(prevState => {
			return {
				...prevState,
				[name]: e.target.value,
			};
		});
	}
	const setSelectValue = value => {
		setValue(prevState => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	switch (type) {
		case "select":
			return <Select name={name} width={width} value={value[name]} setValue={setSelectValue} {...rest} />;
		case "textarea":
			return (
				<textarea
					style={{ width }}
					value={value[name]}
					onChange={onChange}
					name={name}
					className={`input ${className}`}
					{...rest}
				/>
			);
		case "number":
			return (
				<InputNumber
					type={type}
					value={value}
					setValue={setValue}
					name={name}
					width={width}
					className={className}
					small={small}
					onChange={onChange}
					{...rest}
				/>
			);
		case "tel":
			return (
				<InputNumber
					type={type}
					value={value}
					setValue={setValue}
					name={name}
					width={width}
					className={className}
					small={small}
					onChange={onChange}
					{...rest}
				/>
			);
		case "time":
			return <Calendar value={value[name]} onChange={timeOnChange} timeOnly hourFormat={'24'} {...rest}/>

			return <InputTime input={value} setInput={setValue} className={className} name={name} {...rest} />;

		case "date":
			return <InputDate name={name} input={value} setInput={setValue} className={className} {...rest} />;
		case "duration":
			return <InputDuration input={value} setInput={setValue} className={className} name={name} {...rest} />;

		default:
			return (
				<input
					style={{ width }}
					value={value[name]}
					onChange={onChange}
					name={name}
					type={type}
					className={`input ${className} ${small && "small"}`}
					{...rest}
				/>
			);
	}
};

export default InputMultiple;

import axios from "axios";
import { BASE_URL } from "../Config";
import { useState } from "react";


const useLogin = () => {
    const [loading, setLoading] = useState(false);

    const login = ({ email, password }) => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            axios({
                url: `${BASE_URL}/api/admin/onboard/loginadmin`,
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                },
                data: {
                    email: email,
                    password: password
                },
            })
                .then((response) => {
                    setLoading(false);
                    resolve(response.data)
                    //   resolve(response.data);
                })
                .catch((err) => {
                    setLoading(false);
                    reject(err);
                });
        })
    }

    return {
        login,
        loading,
    };
};

export default useLogin;

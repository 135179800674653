import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, DataHeader, LabelItem, LayoutContainer } from '../../../../components';
import { SHOW_STUDENT_FORM } from './constants';
import { useGetSection } from '../../../../queryHooks/Section/useGetSection/useGetSection';
import { useGetClass } from '../../../../queryHooks/Classes/useGetClass/useGetClass';
import { useAuthStore } from '../../../../stores';
import { toTitleCase } from '../../../../utils/toTitleCase';


import './StudentProfile.scss';

export default function StudentProfile() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const user = useAuthStore(state => state.user);
    const school_id = useAuthStore(state => state.school_id)
    const fullName = `${state?.first_name} ${state?.last_name}`
    const email_id = state?.email_id || '-'
    const password = '-';
    const [classId, setClassId] = useState(state?.class_id);
    const [classLabel, setClassLabel] = useState(null);
    const [sectionLabel, setSectionLabel] = useState(null);
    const [sectionId, setSectionId] = useState(state?.section)
    const { data: classOptions, error: getClassError, isLoading: classLoading } = useGetClass({ school_id: school_id, enabled: school_id && true })

    const { data: sectionOptions, error: sectionError, isLoading: sectionLoading, refetch: sectionRefetch } = useGetSection({ class_id: classId, enabled: school_id && true })


    const goBack = () => {
        navigate("../");
    }

    const ActionButton = () => {
        return (
            <div>
                <Button
                    onClick={goBack}
                    theme={"secondary cancel"}
                    style={{ marginTop: "15px" }}
                >
                    Exit
                </Button>
            </div>
        );
    };

    const handleClass = () => {
        return new Promise((resolve, reject) => {
            const classData = classOptions?.filter((item, index) => {
                if (item.class_id == state?.class_id) {
                    resolve(item);
                }
            })
        })
    }

    const handleSection = () => {
        return new Promise((resolve, reject) => {
            sectionOptions?.filter((item, index) => {
                if (item.section_id == state?.section) {
                    resolve(item);
                }
            })
        })
    }
    useEffect(() => {
        if (sectionOptions && classOptions) {
           handleClass().then((classData) => {
            handleSection().then((sectionData) => {
                setSectionLabel(sectionData?.section_name);
                setClassLabel(classData?.class_name);
            })
           })
        }
    }, [classOptions])


    const RenderStudentDetails = () => {
        return SHOW_STUDENT_FORM.map((item, index) => {
            if (state[item.name]) {
                if (item.name == 'class_id' && state['section']) {
                    return <LabelItem label={'Class'} value={`${classLabel} ${sectionLabel}`} />
                }
                return <LabelItem label={item.label} value={`${toTitleCase(state[item.name])}`} />
            }
        })
    }

    const RenderActionButtons = ({onSubmit}) => {
        return (
            <div style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
            <div style={{ width: '20%', margin: '10px', position: 'absolute', bottom: 10, right: 10 }}>
              {/* <Button type="submit" form="student-inputs" theme={'primary'} onClick={onSubmit}>
                {'Edit'}
              </Button> */}
            </div>
          </div>
        )
      }


    const handleEdit = () => {
        navigate('../new', {state : {...state, mode: 'edit'}});
    }

    if(classLoading && sectionLoading)
    {
        return null;
        // null reu
    }
    return (
        <LayoutContainer label={`${fullName}`} RightComponent={<ActionButton />}>
            <DataHeader role={'Student'} name={fullName} email_id={email_id} password={password} imageSrc={state.avtar}>
                <div className='student-label-container'>
                <RenderStudentDetails />
                </div>
            </DataHeader>
            <div style={{flex:1,height:'15vh'}}/>
            <RenderActionButtons onSubmit={handleEdit}/>
        </LayoutContainer>
    )
}

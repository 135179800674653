import { useMutation } from "react-query";
import request from "../../../utils/request";
import { BASE_URL } from "../../../Config";
import { useSharedToast } from "../../../App";
import { convertToBase64Readable } from "../../../utils/base64Encode";
import { convertTo24Hour } from "../../../utils/convertTo24Hour";

const deleteSubjectDetails = async ({ subject_id }) => {
  const queryResponse = await request({
    url: `${BASE_URL}/api/admin/subject/deleteSubject`,
    method: `DELETE`,
    data: {
      subject_id: subject_id,
    },
  });
  return queryResponse;
};

export const useDeleteSubject = () => {
  const { toast } = useSharedToast();
  const {
    mutateAsync: deleteSubject,
    isLoading,
    error,
  } = useMutation(deleteSubjectDetails, {
    onSuccess: (data) => {
      // Success actions
      if (data.status == 200) {
        toast?.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Subject deleted successfully!",
        });
      }
    },
    onError: (error) => {
      console.log(error, "error here");
      // Error actions
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Oops! there was an error deleting the Subject.",
      });
    },
  });

  return {
    deleteSubject: deleteSubject,
    isLoading,
    error,
  };
};

export const CREATE_ADMIN_FORM = [
  {
    label: "Full Name",
    type: "text",
    name: "name",
    placeholder: "Enter your full name",
  },
  {
    label: "Email ID",
    type: "text",
    name: "email_id",
    placeholder: "Enter your email id",
  },
  {
    label: "New Password",
    type: "password",
    name: "new_password",
    placeholder: "Enter New Password",
  },
  {
    label: "Confirm Password",
    type: "password",
    name: "confirm_password",
    placeholder: "Enter New Password",
  },
  {
    label: "Designation",
    type: "text",
    name: "designation",
    placeholder: "Enter Designation",
  },
  {
    label: "Role",
    type: "select",
    options: [
      { value: "super_admin", label: "Super Admin" },
    ],
    name: "role",
  },
  {
    label: "Contact No",
    type: "tel",
    name: "contact_no",
    placeholder: "Enter your Contact",
    width:'100%'
  },
  {
    label: "Upload Photo",
    type: "file",
    name: "photo",
    placeholder: "Upload your photo",
  },
];

export const DEFAULT_FORM_DATA = {
  name: "",
  email_id: "",
  new_password: "",
  confirm_password: "",
  designation: "",
  role: "",
  contact_no: "",
  photo: null,
};

import { create } from "zustand";

const useAuthStore = create((set) => ({
  authenticated: false,
  user: {},
  token: null,
  school_id: localStorage.getItem('org_id') || null,
  setUser: (data) => set(() => ({ user: data })),
  setSchoolId: (data) => {
  const existingSchoolId = localStorage.getItem('org_id');
  if(existingSchoolId && existingSchoolId !== null && existingSchoolId !== undefined)
  {
    set(() => ({ school_id: existingSchoolId}))
  }
  else {
    localStorage.setItem('org_id', data);
    set(() => ({school_id: data}));
  }
  },
  changeSchool: (data) => {
    localStorage.setItem('org_id', data);
    set(() => ({school_id: data}));
  },
  setToken: (token) => {
    localStorage.setItem('accessToken', token)
    set({ token: token })
  },
  setAuthenticated: () => set(() => ({ authenticated: true })),
  signOut: () => {
    set({ authenticated: false })
    localStorage.removeItem('accessToken');
    localStorage.removeItem('org_id');
    set(() => ({ user: {} }))
    set({ token: null })
  }
}))

export default useAuthStore;

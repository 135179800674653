import { useQuery } from "react-query";
import request from "../../utils/request";
import { BASE_URL } from "../../Config";


const getTopics = async ({ queryKey }) => {
    const [_, data] = queryKey;   // eslint-disable-line no-unused-vars
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/topic/get_topic?chapter_id=${data}`,
        method: 'GET',
    })
    return queryResponse;
}

export const useGetTopics = ({ chapter_id, enabled }) => {
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getTopics', chapter_id],
        queryFn: getTopics,
        enabled: chapter_id ? true : false,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;
    const formattedData = responseData?.map((item, index) => {
        let obj = {
            label: item.topic_name,
            value:item.topic_id
        }
        return {...item, ...obj}
    })
    const sortedData = formattedData?.sort((a,b) => Number(a?.id) - Number(b?.id))

    return { data: sortedData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
import { useQuery } from "react-query";
import request from "../../../utils/request";
import { BASE_URL } from "../../../Config";


const getClass = async ({ queryKey }) => {
    
    const [_, school_id] = queryKey;   // eslint-disable-line no-unused-vars
    let str=`${BASE_URL}/api/admin/class/getclass?board_id=${school_id.board_id}&active=1`
    const changeRoute=queryKey[1].isAdmin ?true:false
    if (changeRoute)
    {
       str= `${BASE_URL}/api/admin/class/getclass`
    }
    const queryResponse = await request({
        url: str,
        method: 'GET',
    })
    return queryResponse;
}

export const useGetClass = ( school_id, board_id,enabled, refetch,isAdmin=false ) => {
    const { data, error, isError, isLoading, isInitialLoading, isFetching } = useQuery({
        queryKey: ['getClass',  {school_id,board_id,isAdmin}],
        queryFn: getClass,
        enabled: enabled,
        // staleTime: 10 * (60 * 1000), // 5 mins
        // cacheTime: 15 * (60 * 1000), // 15 mins 
        // retry: 2,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;
    const formattedData = responseData?.map((item, index) => {
        let obj = {
            label: item.class_name,
            value:item.class_id
        }
        return {...item, ...obj}
    })
    const sortedData = formattedData?.sort((a,b) => Number(a?.class_name) - Number(b?.class_name))
    return { data: sortedData, error, isError, isLoading, isInitialLoading, isFetching };
}
import { useQuery, useQueryClient } from "react-query";
import request from "../../utils/request";
import { BASE_URL } from "../../Config";


const getBuses = async ({ queryKey }) => {
    const [_, data] = queryKey;
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/school/get_bus_list?school_id=${data}`,
        method: 'GET',
    })
    return queryResponse;
}

export const useGetBuses = ({ school_id, enabled }) => {
    const queryClient = useQueryClient();
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getBuses', school_id],
        queryFn: getBuses,
        enabled: school_id ? true : false,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;
    const formattedData = responseData?.map((item, index) => {
        let obj = {
            label: item.name,
            value:item.bus_id
        }
        return {...item, ...obj}
    })
    return { data: formattedData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
export const SHOW_ADMIN_NAME = [
    {
        label: "Operator Name",
        name: "full_name",
    },
    {
        label: "Gender",
        name: "gender",
    },
    {
        label: "Address",
        name: "address",
    },
    {
        label: "Driving License No",
        name: "Dl_no",
    },
    {
        label: "Contact No",
        name: "contact_no",
    },
    {
        label: "Emergency No",
        name: "emergency_no",
    },
]
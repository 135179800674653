// class_icon
// class_name
// class_index
// select_board
// lock_class

export const INPUTS = [
    {
        label: "Class Icon",
        type: "dragNdrop",
        name: "icon",
        placeholder: "Upload class icon",
    },
    {
        label: "Class Name",
        type: "text",
        name: "class_name",
        placeholder: "Enter your class Name",
    },
    {
        label: "Class Index",
        type: "text",
        name: "index",
        placeholder: "Enter class index",
    },
    {
        label: "Select Board",
        type: "select",
        name: "board",
        options: [],
        menuOnTop:true,
    },
    {
        label: "Lock Class",
        type: "select",
        name: "lock_class",
        menuOnTop:true,
        options: [
            { value: true, label: "Lock" },
            { value: false, label: "Unlock" },
        ],
    },
]

export const DEFAULT_INPUT_VALUE = {
    icon: null,
    class_name: null,
    index: null,
    board: null,
    lock_class: null,
}
import React from 'react'
import './SchoolHeader.scss';
import DefaultAvatar from '../../assets/images/default_avatar.png'

export default function SchoolHeader({name, className, imageSrc,role, username, email_id, password, children, selected, rightComponent}) {
  return (
    <>
    <div className={`school-header-container ${className ?? ''}`}>
        <div className={`school-header-info-container ${selected ? 'selected' : ''}`}>
          <div className="school-header-info-wrapper">
            <div className="school-header-avatar-wrapper">
              <img src={imageSrc ? imageSrc:DefaultAvatar} className="school-header-avatar" />
              <div className="school-header-text">
                <p className="school-header-label">{name}</p>
                <p className="school-header-subtext">{role ?? ''}</p>
              </div>
            </div>
            <div className="email-container">
              <div className="school-header-text">
                <p className="school-header-label">{`${username && username !== '-' ? 'Username' : 'Email'}`}</p>
                <p className="school-header-subtext">{username && username !== '-' ? email_id : email_id}</p>
              </div>
            </div>
            <div className="password-container">
              <div className="school-header-text">
                <p className="school-header-label">{'Password'}</p>
                <p className="school-header-subtext">{password ? password.substring(0,8) : '-'}</p>
              </div>
            </div>
          {rightComponent && rightComponent}
          </div>
        </div>
        {children && children}
      </div>
    </>
  )
}

import React, { useState } from 'react'
import 'vidstack/styles/defaults.css';
import 'vidstack/styles/community-skin/video.css';
import { MediaCommunitySkin, MediaOutlet, MediaPlayer, MediaPoster } from '@vidstack/react';

import './VideoPlayer.scss';

const VideoPlayer = ({ src, title, cover }) => {
    //   const [isPlaying, setIsPlaying] = useState(false);

    //   const handlePlay = () => {
    //     setIsPlaying(true);
    //   };

    //   const handlePause = () => {
    //     setIsPlaying(false);
    //   };

    //   return (
    //     <div className="video-player">
    //       <video
    //         autoPlay={isPlaying}
    //         loop={true}
    //         src={src}
    //         poster={cover}
    //         controls
    //       />
    //       <div className="video-controls">
    //         <button onClick={handlePlay}>
    //           Play
    //         </button>
    //         <button onClick={handlePause}>
    //           Pause
    //         </button>
    //       </div>
    //     </div>
    //   )
    return (
        <div className='video-player'>
        <MediaPlayer
            title={title}
            src={src}
            poster={cover}
            thumbnails={cover}
            aspectRatio={16 / 9}
            // crossorigin={""}
        >
            {/* <MediaOutlet>
    <MediaPoster
      alt="Girl walks into sprite gnomes around her friend on a campfire in danger!"
    />
   <track
      src="https://media-files.vidstack.io/sprite-fight/subs/english.vtt"
      label="English"
      srcLang="en-US"
      kind="subtitles"
      default
    />
    <track
      src="https://media-files.vidstack.io/sprite-fight/chapters.vtt"
      srcLang="en-US"
      kind="chapters"
      default
    />
  </MediaOutlet> */}
            <MediaOutlet />
            <MediaCommunitySkin />
        </MediaPlayer>
        </div>
    )
}

export default VideoPlayer

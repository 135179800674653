import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, DataHeader, LabelItem, LayoutContainer } from '../../../../components';
import { SHOW_SCHOOL_FORM, SHOW_STUDENT_FORM } from './constants';
import { useGetSection } from '../../../../queryHooks/Section/useGetSection/useGetSection';
import { useGetClass } from '../../../../queryHooks/Classes/useGetClass/useGetClass';
import { useAuthStore } from '../../../../stores';
import { toTitleCase } from '../../../../utils/toTitleCase';


import './SchoolProfile.scss';

export default function SchoolProfile() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const user = useAuthStore(state => state.user);
    const school_id = useAuthStore(state => state.school_id)
    const fullName = `${state.school_name}`
    const email_id = state?.email_id || '-'
    const password = '-';


    const goBack = () => {
        navigate("../");
    }

    const ActionButton = () => {
        return (
            <div>
                <Button
                    onClick={goBack}
                    theme={"secondary cancel"}
                    style={{ marginTop: "15px" }}
                >
                    Exit
                </Button>
            </div>
        );
    };

    const RenderSchoolProfile = () => {
        return SHOW_SCHOOL_FORM.map((item, index) => {
                return <LabelItem label={item.label} value={`${toTitleCase(state[item.name])}`} />
            })
        }


    const RenderActionButtons = ({onSubmit}) => {
        return (
          <div style={{ display: 'flex', flex:0.5, alignItems:'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
            <div style={{ width: '20%', margin: '10px' }}>
              <Button type="submit" form="student-inputs" theme={'primary'} onClick={onSubmit}>
                {'Update'}
              </Button>
            </div>
          </div>
        )
      }


    const handleEdit = () => {
        navigate('../new', {state : {...state, mode: 'edit'}});
    }

    return (
        <LayoutContainer label={`${fullName}`} RightComponent={<ActionButton />}>
            <DataHeader role={'School'} name={fullName} email_id={email_id} password={password} imageSrc={state?.logo}>
                <div className='student-label-container'>
                <RenderSchoolProfile />
                </div>
            </DataHeader>
            <div style={{flex:1,height:'1vh'}}/>
            <RenderActionButtons onSubmit={handleEdit}/>
        </LayoutContainer>
    )
}

import React, { useCallback, useMemo, useState } from 'react'
import { GoPlus } from 'react-icons/go';
import { Outlet, useNavigate, useOutlet } from 'react-router-dom';
import { useAuthStore } from '../../../../stores';
import { checkEmpty } from '../../../../utils/checkEmpty';
import FormInput from '../../../../components/FormInput/Input';
import { Button, LayoutContainer } from '../../../../components';
import Table from '../../../Teacher/components/Table/Table';
import { useSharedToast } from '../../../../App';

import './CreateBus.scss'
import { useCreateBus } from '../../../../queryHooks/useCreateBus/useCreateBus';
const columnHeader = [
    { path: 'bus_name', title: 'Bus Name' },
    // { path: 'period', title: 'Period' },
    { path: 'bus_number', title: 'Bus Number' },
]


export default function CreateBus() {
    const [input, setInput] = useState([]);
    const [busName, setBusName] = useState();
    const [busNo, setBusNo] = useState();
    const user = useAuthStore(state => state.user);
    const school_id = useAuthStore(state => state.school_id)
    const navigate = useNavigate();
    const toast = useSharedToast();
    const {createBus} = useCreateBus();

    const goBack = () => {
        navigate("../");
    }
    const ActionButton = () => {
        return (
            <div>
                <Button
                    onClick={goBack}
                    theme={"secondary cancel"}
                    style={{ marginTop: "15px" }}
                >
                    Exit
                </Button>
            </div>
        );
    };

    const showError = () => {
        toast?.current?.show({ severity: 'error', summary: 'Invalid Form', detail: 'Please enter all the details!' });
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        //do nothing
        const input = {
            bus_name: busName,
            bus_number: busNo,
        }
        const isEmpty = checkEmpty(input)
        if (isEmpty) {
            showError();
            return false;
        }
        createBus({ inputs: input, school_id: school_id }).then(() => {
            goBack();
        }).catch((err) => {
        })
    }

    const RenderActionButtons = ({ onSubmit }) => {
        return (
            <div style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
                <div style={{ width: '20%', margin: '10px', position:'absolute', bottom:10, right:10 }}>
                    <Button type="submit" form="profile-inputs" theme={'primary'} onClick={onSubmit}>
                        {'Submit'}
                    </Button>
                </div>
            </div>
        )
    }

    const RenderColumnByType = useCallback(({ type, row }) => {
        if (type == 'bus_name') {
            return (
                <FormInput
                    type={'text'}
                    style={{ border: 'none' }}
                    name={'bus_name'}
                    {...(type !== 'file' && { value: busName })}
                    width={'100%'}
                    setValue={setBusName}
                    required={true}
                    placeholder={'Enter Bus Name'}
                />
            )
        }
        else if (type == 'bus_number') {
            return (
                <FormInput
                    type={'text'}
                    style={{ border: 'none' }}
                    name={'bus_number'}
                    width={'100%'}
                    {...(type !== 'file' && { value: busNo })}
                    setValue={setBusNo}
                    required={true}
                    placeholder={'Enter Bus Number'}
                />
            )
        }
        else {
            //
        }
    }, [])

    const RenderBusSelection = ({ row }) => {
        return columnHeader.map((column, i) => {
            const path = column.path;
            return (
                <td key={i}>
                    {<RenderColumnByType row={row} type={path} />}
                </td>
            )
        }
        )
    }

    const BUS_INPUTS = useMemo(() => {
        return (<div className='bus-input-container'>
            <Table columnHeader={columnHeader} noOfRows={1} renderRow={(row) => <RenderBusSelection row={row} />} />
        </div>
        )
    }, [input])

    return (
        <LayoutContainer label={"Students"} RightComponent={<ActionButton />}>
            {BUS_INPUTS}
            <div className='student-container'>
                <RenderActionButtons onSubmit={handleSubmit} />
            </div>
        </LayoutContainer>
    )
}

import React, { useState, useCallback, useMemo } from 'react';
import { Modal } from '../../../../components';
import { DEFAULT_INPUT_VALUE, INPUTS } from './constants';
import Dropzone from 'react-dropzone';
import FormInput from '../../../../components/FormInput/Input';
import { getBase64 } from '../../../../utils/base64Encode';

import './ConfirmationModal.scss';

export default function ConfirmationModal({ visible, setVisible, text, onDelete }) {

    const onHide = () => {
        setVisible(false);
    };

    return (
        <Modal
            header={'Delete School'}
            headerStyle={{ textAlign: 'center' }}
            headerClassName={'modal-header'}
            visible={visible}
            onHide={onHide}
            confirmation={true}
            onCreate={onDelete}
            width={'45vw'}
        >
            <div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p>{text && text}</p></div>
            </div>
        </Modal>
    );
}

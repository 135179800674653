import { useQuery, useQueryClient } from "react-query";
import request from "../../utils/request";
import { BASE_URL } from "../../Config";
import { useAuthStore } from "../../stores";


const getAdmin = async ({ queryKey }) => {
    const [_, token] = queryKey;
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/onboard/getProfile_admin`,
        method: 'GET',
        Authorization: `Bearer ${token}`
    })
    return queryResponse;
}

export const useGetAdminProfile = ({ token, enabled }) => {
    const queryClient = useQueryClient();
    const setUser = useAuthStore(state => state.setUser)
    const setAuthenticated = useAuthStore(state => state.setAuthenticated)
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getAdminProfile', token],
        queryFn: getAdmin,
        enabled: !!token,
        useErrorBoundary: (error) => error?.response?.status >= 500,
        onSuccess: (data) => {
            if(data?.data?.data?.User)
            {
                setAuthenticated();
                setUser(data?.data?.data?.User)
            }
        },
    })
    const responseData = data?.data?.data.User;
    return { data: responseData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
export const MERIDIAN_TYPE_OPTIONS = [
  { label: "AM", value: "AM" },
  { label: "PM", value: "PM" },
];

export const MONTH_TYPE_OPTIONS = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];


export const YEAR_DOB_TYPE_OPTIONS = Array.from(Array(110).keys()).map((item) => {
  return {
      label: `${new Date().getFullYear() - item}`,
      value: `${new Date().getFullYear() - item}`,
  }
});
export const YEAR_TYPE_OPTIONS = [
  {
    label: `${new Date().getFullYear()}`,
    value: `${new Date().getFullYear()}`,
  },
  {
    label: `${new Date().getFullYear() + 1}`,
    value: `${new Date().getFullYear() + 1}`,
  },
  {
    label: `${new Date().getFullYear() + 2}`,
    value: `${new Date().getFullYear() + 2}`,
  },
];

export const DATE_TYPE_OPTIONS = [
  { label: "1", value: "01" },
  { label: "2", value: "02" },
  { label: "3", value: "03" },
  { label: "4", value: "04" },
  { label: "5", value: "05" },
  { label: "6", value: "06" },
  { label: "7", value: "07" },
  { label: "8", value: "08" },
  { label: "9", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
];

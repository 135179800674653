import React, { useState } from "react";

import "./Bus.scss";
import { GoPlus } from "react-icons/go";
import { Button, LayoutContainer, Pagination, Topbar } from "../../components";
import { Outlet, useNavigate, useOutlet } from "react-router-dom";
import { useAuthStore } from "../../stores";
import { useGetTeachers } from "../../queryHooks/useGetTeachers/useGetTeachers";
import User from "../../components/User/User";
import { Skeleton } from 'primereact/skeleton';
import { useGetBuses } from "../../queryHooks/useGetBuses/useGetBuses";
import DefaultBusImage from '../../assets/images/default_bus_image.png'

export default function Bus() {
  const user = useAuthStore(state => state.user);
  const school_id = useAuthStore(state => state.school_id)
  const outlet = useOutlet();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

   //Get Current rows
   const indexOfLastRow = currentPage * rowsPerPage;
   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

   const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const { data: buses, error: busError, isLoading: busLoading, refetch: busRefetch } = useGetBuses({ school_id: school_id })
  const currentRows = buses?.slice(indexOfFirstRow, indexOfLastRow);


  const handleItemClick = (data) => {
    navigate(`./${data.bus_id}`, { state: { ...data } });
  }


  const NoContent = () => {
    return (
      <div className="no-content">
        <p>You haven't selected any of the profile yet.</p>
      </div>
    );
  };

  const ActionButton = () => {
    return (
      <div>
        <Button
          onClick={() => {
            navigate('./new')
          }}
          icon={<GoPlus />}
          theme={"primary"}
          style={{ marginTop: "15px" }}
        >
          Create New Bus Profile
        </Button>
      </div>
    );
  };

  const SkeletonLoader = () => {
    return (
      <div className='teacher-wrapper'>
      {Array.from(Array(10).keys()).map((_, index) => {
        return <div className="user-item-container" style={{backgroundColor:'transparent'}}>
        <Skeleton height='125px' key={index} />
        </div>
      })}
    </div>
    )
  }

  if (outlet) {
    return (
      <Outlet />
    )
  }

  return (
    <>
      <LayoutContainer label={"Buses"} RightComponent={<ActionButton />}>
        <div className="teacher-container">
          {busLoading ?
          <SkeletonLoader />
            : buses && buses.length !== 0 ?
              <div className='teacher-wrapper'>
                {currentRows?.map((item, index) => {
                  return (
                    <User image={DefaultBusImage} customName={item.name} role={item.bus_number} index={index} onClick={() => handleItemClick(item)} />
                  )
                })}
              </div> : <NoContent />}
        </div>
        {buses?.length !== 0 && <div className="footer-wrapper">
            <h4 className="show-rows">
              Showing {indexOfFirstRow === 0 ? '1' : indexOfFirstRow} to{' '}
              {indexOfLastRow} of {buses?.length} Result
            </h4>
            </div>}
            {rowsPerPage <= buses?.length && (
            <Pagination
              rowsPerPage={rowsPerPage}
              totalRows={buses?.length}
              paginate={paginate}
              currentPage={currentPage}
              />
            )}
      </LayoutContainer>
    </>
  );
}

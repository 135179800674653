import { useQuery, useQueryClient } from "react-query";
import request from "../../utils/request";
import { BASE_URL } from "../../Config";


const getTeachers = async ({ queryKey }) => {
    const [_, school_id] = queryKey;
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/teacher/get?school_id=${school_id}`,
        method: 'GET',
    })
    return queryResponse;
}

export const useGetTeachers = ({ school_id, enabled }) => {
    const queryClient = useQueryClient();
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getTeachers', school_id],
        queryFn: getTeachers,
        staleTime: 2 * (60 * 1000), // 2 mins
        cacheTime: 5 * (60 * 1000), // 5 mins 
        enabled: !!school_id,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;

    return { data: responseData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
import React from 'react'
import './DataHeader.scss';
import DefaultAvatar from '../../assets/images/default_avatar.png'

export default function DataHeader({name, className, imageSrc,role, username, email_id, password, children, rightComponent}) {
  return (
    <>
    <div className={`profile-container ${className ?? ''}`}>
        <div className="profile-info-container">
          <div className="profile-info-wrapper">
            <div className="profile-avatar-wrapper">
              <img src={imageSrc ? imageSrc:DefaultAvatar} className="profile-avatar" />
              <div className="profile-text">
                <p className="profile-label">{name}</p>
                <p className="profile-subtext">{role ?? ''}</p>
              </div>
            </div>
            <div className="email-container">
              <div className="profile-text">
                <p className="profile-label">{`${username && username !== '-' ? 'Username' : 'Email'}`}</p>
                <p className="profile-subtext">{username && username !== '-' ? email_id : email_id}</p>
              </div>
            </div>
            <div className="password-container">
              <div className="profile-text">
                <p className="profile-label">{'Password'}</p>
                <p className="profile-subtext">{password}</p>
              </div>
            </div>
          {rightComponent && rightComponent}
          </div>
        </div>
        {children && children}
      </div>
    </>
  )
}

import { useMutation } from 'react-query';
import request from '../../../utils/request';
import { BASE_URL } from '../../../Config';
import { useSharedToast } from '../../../App';
import { convertToBase64Readable } from '../../../utils/base64Encode';
import { convertTo24Hour } from '../../../utils/convertTo24Hour';


const createBoardDetails = async ({ inputs, school_id }) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/school/createBoard`,
        method: `POST`,
        data: {
            board_name:inputs?.board_name,
            // school_id: school_id
            // lock: inputs.lock
        },
    })
    return queryResponse;
}


export const useCreateBoard = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: createBoard, isLoading, error } = useMutation(createBoardDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Board created successfully!' });
            }
        },
        onError: (error) => {
            console.log(error, 'error here');
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error creating the Board.' });
        },
    });

    return {
        createBoard: createBoard,
        isLoading,
        error,
    };
};

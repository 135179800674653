import React, { useState } from 'react'
import { Button, LayoutContainer, Pagination, Topbar } from '../../components'
import { GoPlus } from 'react-icons/go';
import { Outlet, useNavigate, useOutlet } from 'react-router-dom';
import { useGetStudents } from '../../queryHooks/useGetStudents/useGetStudents';
import { useGetSection } from '../../queryHooks/Section/useGetSection/useGetSection';
import TableSelect from '../../components/TableSelect/TableSelect';
import Table from '../Teacher/components/Table/Table';
import { useAuthStore } from '../../stores';
import StudentItem from './components/StudentItem/StudentItem';

import './Student.scss';
import User from '../../components/User/User';
import { useGetClass } from '../../queryHooks/Classes/useGetClass/useGetClassSuperAdmin';

const columnHeader = [
  { path: 'class', title: 'Class' },
  // { path: 'period', title: 'Period' },
  { path: 'section', title: 'Section' },
]


export default function Student() {
  const [classValue, setClassValue] = useState('');
  const [sectionValue, setSectionValue] = useState('');
  const [enabled, setEnabled] = useState(true);
  const user = useAuthStore(state => state.user);
  const school_id = useAuthStore(state => state.school_id)
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

   //Get Current rows
   const indexOfLastRow = currentPage * rowsPerPage;
   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

   const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const { data: classOptions, error: getClassError, isInitialLoading: classLoading } = useGetClass(school_id,"",enabled,true )

  const { data: sectionOptions, error: sectionError, isInitialLoading: sectionLoading, refetch: sectionRefetch } = useGetSection({ class_id: classValue, enabled: school_id && true })

  const { data: studentData, error: studentError, isInitialLoading: studentLoading, refetch: studentRefetch } = useGetStudents({ class_id: classValue, section_id: sectionValue, school_id: school_id, enabled: enabled })

  const currentRows = studentData?.slice(indexOfFirstRow, indexOfLastRow);

  const outlet = useOutlet();

  const navigateToCreate = () => {
    navigate(`./new`)
  }

  const handleItemClick = (data) => {
    navigate(`./${data.student_id}`, { state: { ...data } });
  }

  const ActionButton = () => {
    return (
      <div>
        <Button
          onClick={navigateToCreate}
          icon={<GoPlus />}
          theme={"primary"}
          style={{ marginTop: "15px" }}
        >
          Create Student Profile
        </Button>
      </div>
    );
  };

  const handleSubmit = () => {
    if(enabled)
    {
      // studentRefetch();
    }
    setEnabled(true);
    setTimeout(() => {
      setEnabled(false)
    }, 100)
  }

  const RenderActionButtons = ({ onSubmit }) => {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <div style={{ width: '20%', margin: '10px' }}>
          <Button type="submit" form="profile-inputs" theme={'primary'} onClick={onSubmit}>
            {'Next'}
          </Button>
        </div>
      </div>
    )
  }

  const RenderColumnByType = ({ type, row }) => {
    switch (type) {
      case 'class':
        return <TableSelect
          options={classOptions}
          theme={'transparent'}
          value={classValue}
          setValue={setClassValue}
        />
      case 'section':
        return <TableSelect
          options={sectionOptions}
          theme={'transparent'}
          value={sectionValue}
          setValue={setSectionValue} />
    }
  }

  const RenderStudentSelection = ({ row }) => {
    return columnHeader.map((column, i) => {
      const path = column.path;
      return (
        <td key={i}>
          {<RenderColumnByType row={row} type={path} />}
        </td>
      )
    }
    )
  }

  if (outlet) {
    return <Outlet />
  }

  return (
    <LayoutContainer label={"Students"} RightComponent={<ActionButton />}>
      <Table columnHeader={columnHeader} noOfRows={1} renderRow={(row) => <RenderStudentSelection row={row} />} />
      <div className='student-container'>
      {studentData?.length !==0 ? <div className='student-wrapper'>
      {currentRows?.map((item, index) => {
        return (
          <User data={item} index={index} role={'Student'} onClick={() => handleItemClick(item)}/>
          )
        })}
      </div>
        :
        null}
        </div>
        {studentData?.length !== 0 && <div className="footer-wrapper">
            <h4 className="show-rows">
              Showing {indexOfFirstRow === 0 ? '1' : indexOfFirstRow} to{' '}
              {indexOfLastRow} of {studentData?.length} Result
            </h4>
            </div>}
            {rowsPerPage <= studentData?.length && (
            <Pagination
              rowsPerPage={rowsPerPage}
              totalRows={studentData?.length}
              paginate={paginate}
              currentPage={currentPage}
              />
            )}
      {/* <RenderActionButtons onSubmit={handleSubmit} /> */}
    </LayoutContainer>
  )
}

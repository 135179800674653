import Button from "./Button/Button";
import Input from "./Input/Input";
import Sidebar from "./global/Sidebar/Sidebar";
import Avatar from "./Avatar/Avatar";
import Topbar from "./global/Topbar/Topbar";
import LayoutContainer from "./global/LayoutContainer/LayoutContainer";
import DataHeader from "./DataHeader/DataHeader";
import LabelItem from "./LabelItem/LabelItem";
import Modal from "./Modal/Modal";
import SchoolHeader from "./SchoolHeader/SchoolHeader";
import Pagination from "./Pagination/Pagination";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
export {
    Button,
    Input,
    Sidebar,
    Avatar,
    Topbar,
    LayoutContainer,
    DataHeader,
    LabelItem,
    Modal,
    SchoolHeader,
    Pagination,
    VideoPlayer,
}
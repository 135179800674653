import React from 'react';
import DefaultChapter from '../../../assets/images/default_chapter.png';
import { MdOutlineTimer } from 'react-icons/md';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';

import './ChapterItem.scss';
import moment from 'moment';

export default function ChapterItem({
  src,
  label,
  index,
  selected,
  time,
  topics,
  creationDate,
  editAndDelete,
  onClick,
  onEditPress,
  onDeletePress,
}) {
  const handleEditClick = (e) => {
    e.stopPropagation(); // Prevent the card click event from firing when the Edit button is clicked
    onEditPress(); // Call the onEditPress function when the Edit button is clicked
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation(); // Prevent the card click event from firing when the Delete button is clicked
    onDeletePress(); // Call the onDeletePress function when the Delete button is clicked
  };

  return (
    <div key={index} className={`chapter-container ${selected ? 'selected' : ''}`} onClick={onClick}>
      <div style={{paddingTop:'10px'}}>
        <img src={src ?? DefaultChapter} className='chapter-image' alt={label} />
      </div>
      <div className='chapter-text-container'>
        <div className='chapter-label'>
          {label && label}
        </div>
        <div className='chapter-info'>
          <div className='chapter-info-inner'>
            <MdOutlineTimer /> <p className='chapter-inner-text'> {time}</p>
          </div>
          <div className='chapter-info-inner'>
            <div className='vl' />
          </div>
          <div className='chapter-info-inner'>
            <p className='chapter-inner-text'>{`${topics} Topic`}</p>
          </div>
        </div>
        <div className='chapter-initial-date-container'>
          <p className='chapter-inner-text creation-date'>{`Initiated on ${moment(creationDate).format('DD/MM/YYYY')}`}</p>
        </div>
        {editAndDelete && (
          <div className='chapter-buttons'>
            {/* <div className='edit-button-chapter' onClick={handleEditClick}>
              <AiOutlineEdit className='edit-icon' />
              <span className='button-text'>Edit</span>
            </div> */}
            <div className='delete-button-chapter' onClick={handleDeleteClick}>
              <AiOutlineDelete className='delete-icon' />
              <span className='button-text'>Delete</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

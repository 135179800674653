import StulyfeLogo from '../../assets/logos/stulyfe_logo.png';
import EducationLogo from '../../assets/logos/e-learning_logo.png';
import Onboarding1 from '../../assets/onboarding/onboarding_1.png'
import Onboarding2 from '../../assets/onboarding/onboarding_2.png'
import Onboarding3 from '../../assets/onboarding/onboarding_3.png'
import Onboarding4 from '../../assets/onboarding/onboarding_4.png'

export const LOGO_ITEMS = [
    {
        label: 'Stulyfe',
        src: StulyfeLogo,
    },
    {
        label: 'E-Learning',
        src: EducationLogo
    }
]


export const ONBOARDING_ITEMS = [
    {
        key:'onboarding_1',
        title: 'Complete School Management',
        text: 'Management of all the parameters including Attendance, Lectures, Circulars, Results, Report Analysis, Dashboards, etc. ',
        src:Onboarding1,
    },
    {
        key:'onboarding_2',
        title: 'Digital  Classrooms',
        text:'Access to all the audio-video 2D, 3D animated video lectures with detailed explanation about the topics. ',
        src:Onboarding2,
    },
    {
        key:'onboarding_3',
        title: 'Digital  Classrooms',
        text:'Access to all the audio-video 2D, 3D animated video lectures with detailed explanation about the topics. ',
        src:Onboarding3,
    },
    {
        key:'onboarding_4',
        title: 'Digital  Classrooms',
        text:'Access to all the audio-video 2D, 3D animated video lectures with detailed explanation about the topics. ',
        src:Onboarding4,
    }
]



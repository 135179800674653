import React from 'react'

import './SchoolItem.scss';
import { Button, DataHeader } from '../../../../components';
import SchoolHeader from '../../../../components/SchoolHeader/SchoolHeader';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'

export default function SchoolItem({ data, className, index, onClick, onDelete, onSelect, selected }) {
  const handleDelete = (e) => {
    e.stopPropagation()
    onDelete();
  }
  const RightComponent = () => {
    return (
      <div className='school-item-button-container' key={index}>
        <div className='school-button'>
          <Button type="submit" theme={'edit'} icon={<AiOutlineEdit size={15} />} onClick={onClick}>
            {'Edit'}
          </Button>
        </div>
        <div>
          <Button type="submit" theme={'cancel'} icon={<AiOutlineDelete size={15} />} onClick={handleDelete}>
            {'Delete'}
          </Button>
        </div>
      </div>
    )
  }

  const handleSelect = () => {
    onSelect(data?.school_id);
  }

  return (
    <div className={`item-hover`} onClick={handleSelect}>
      {selected && 
      <div className='selected-school-badge-container'>
      <div className='selected-school-badge'>
        <p className='selected-school-text'>Current</p>
        </div>
        </div>}
      <SchoolHeader selected={selected} email_id={data?.admin_master?.email} password={data?.admin_master?.password} imageSrc={data?.logo} name={data?.school_name} role={data?.school_tagline} className={className} rightComponent={<RightComponent />} />
    </div>
  )
}

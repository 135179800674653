export const getBase64 = (file, cb) => {
    return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        // const res = reader.result.split(',')[1]
         resolve(reader.result)
    };
    reader.onerror = function (error) {
        reject(error);
        console.log('Error: ', error);
    };
})
}

export const convertToBase64Readable = (res) => {
    return res.split(',')[1];
}
import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';

const assign = async ({ bus_id, student_id}) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/school/assign_student`,
        method: `POST`,
        data: {
            bus_id: bus_id,
            student_id: student_id,
        },
    })
    return queryResponse;
}

export const useAssignToBus = () => {
    const { mutate: assignToBus, isLoading, error } = useMutation(assign, {
        onSuccess: (data) => {
            // Success actions
            if(data.status == 200)
            {
                return data;
            }
            else {
                throw {data: data};
            }
        },
        onError: (error) => {
            console.log(error, 'assign student to bus error');
            // Error actions
            throw error
        },
    });

    return {
        assignToBus,
        isLoading, 
        error,
    };
};

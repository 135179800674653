import { DEFAULT_CLASS_OPTIONS, DEFAULT_SECTION_OPTIONS } from "../../../Teacher/sub-pages/CreateTeacher/constants";

export const CREATE_STUDENT_FORM = [
    {
        label: "Full Name",
        type: "text",
        name: "name",
        placeholder: "Enter full name",
    },
    {
        label: "Mother Name",
        type: "text",
        name: "mother_name",
        placeholder: "Enter Mother's name",
    },
    {
        label: "Password",
        type: "password",
        name: "password",
        placeholder: "Enter Password",
    },
    {
        label: "Upload Photo",
        type: "file",
        name: "photo",
        placeholder: "Upload your photo",
    },
    {
        label: "Emergency Contact No",
        type: "tel",
        name: "contact_no",
        placeholder: "Enter Emergency Contact",
        width:'100%',
    },
    {
        label: "Email ID",
        type: "text",
        name: "email_id",
        placeholder: "Enter your email id",
    },
    {
        label: "Class",
        type: "select",
        options: [
            ...DEFAULT_CLASS_OPTIONS
        ],
        name: "class",
    },
    {
        label: "Section",
        type: "select",
        options: [
            ...DEFAULT_SECTION_OPTIONS
        ],
        name: "section",
    },
    // {
    //     label: "Bus No",
    //     type: "text",
    //     name: "bus_no",
    //     placeholder: "Enter Bus No.",
    // },
    {
        label: "ID No.",
        type: "text",
        name: "id_no",
        placeholder: "Enter ID No.",
    },
    {
        label: "Date of Birth",
        type: "date",
        name: "dob",
        placeholder: "Enter your Date of Birth",
    },
    {
        label: "Gender",
        type: "select",
        options: [
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
            { value: "others", label: "Others" },
        ],
        name: "gender",
        menuOnTop: true,
    },
];

export const DEFAULT_FORM_DATA = {
    name: "",
    mother_name: "",
    password:"",
    photo: null,
    contact_no: "",
    email_id: "",
    class: "",
    section: "",
    // bus_no: "",
    id_no: "",
    DD: "",
    MM: "",
    YYYY: "",
    gender: "",
};

/*
full name
mother name
user name
photo
emergency contact
email id
class
section
bus no
id card no
DOB
gender
*/
import React, { useState } from 'react'
import './ListContainer.scss';
import DefaultAvatar from '../../../../../../assets/images/default_avatar.png'
import { toTitleCase } from '../../../../../../utils/toTitleCase';
import { FiPlusSquare, FiMinusSquare } from 'react-icons/fi';

const DEFAULT_LIMIT = 4;
const ListItem = ({ src, name, role, index }) => {
    return (
        <div className="list-avatar-wrapper" key={index}>
            <img src={src ?? DefaultAvatar} className="list-avatar" />
            <div className="list-text">
                <p className="list-label">{name}</p>
                <p className="list-subtext">{role ?? ''}</p>
            </div>
        </div>
    )
}
export default function ListContainer({ label, data, role, className }) {
    const [limit, setLimit] = useState(DEFAULT_LIMIT);
    const [clicked, setClicked] = useState(false);
    const handleClick = () => {
        if(clicked)
        {
            setClicked(false)
            setLimit(DEFAULT_LIMIT);
        }
        else if(data && data.length!==0 && data.length > 4)
        {
            setLimit(data.length)
            setClicked(true)
        }
    }
    return (
        <>
            <div className={`list-container ${className ?? ''}`}>
                <div className="list-info-container">
                <div className='list-header'>
                    <p>{`${label} (${data?.length ? data.length : 0})`}</p>
                    {data && data.length > DEFAULT_LIMIT && <div className='list-plus-icon' onClick={handleClick}>
                        {!clicked ? <FiPlusSquare size={20}/> : <FiMinusSquare size={20} />}
                    </div>}
                </div>
                <div className="list-info-wrapper">
                    {data?.length !== 0 && data?.slice(0, limit).map((item, index) => {
                        const student = item?.student_master
                        const name = `${toTitleCase(student?.first_name)} ${toTitleCase(student?.last_name)}`;
                        const src = student.avtar;
                        return <ListItem name={name} src={src} index={index} role={role} />
                    })}
                </div>
                </div>
            </div>
        </>
    )
}

import React, { useMemo, useState } from "react";
import { FiUploadCloud } from "react-icons/fi";
import { Button, LayoutContainer } from "../../../../components";
import { useLocation, useNavigate } from "react-router-dom";

import { useSharedToast } from "../../../../App";
import { CREATE_STUDENT_FORM, DEFAULT_FORM_DATA } from "./constants";
import FormInput from "../../../../components/FormInput/Input";

import "./CreateStudent.scss";
import { checkEmpty } from "../../../../utils/checkEmpty";
import { useCreateStudent } from "../../../../queryHooks/useCreateStudent/useCreateStudent";
import { getBase64 } from "../../../../utils/base64Encode";
import { useGetSection } from "../../../../queryHooks/Section/useGetSection/useGetSection";
import { useEffect } from "react";
import { useAuthStore } from "../../../../stores";
import { useUpdateStudent } from "../../../../queryHooks/useUpdateStudent/useUpdateStudent";
import { useGetClass } from "../../../../queryHooks/Classes/useGetClass/useGetClassSuperAdmin";

export default function CreateStudent() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const mode = state?.mode;
  const [day, month, year] = state?.dob ? state?.dob.split("/") : "00/00/0000";
  const PROPS_DATA = state
    ? {
        name: `${state?.first_name} ${state?.last_name}`,
        mother_name: `${state?.guardian_name}`,
        password: "",
        photo: state?.avtar,
        contact_no: state?.contact_no,
        email_id: state?.email_id,
        class: state?.class_id,
        section: state?.section,
        // bus_no: state?.bus_no,
        id_no: state?.id_no,
        DD: day ?? null,
        MM: month ?? null,
        YYYY: year ?? null,
        gender: state?.gender,
      }
    : null;
  const [input, setInput] = useState(PROPS_DATA || DEFAULT_FORM_DATA);
  const user = useAuthStore((state) => state.user);
  const school_id = useAuthStore((state) => state.school_id);
  const [enabled, setEnabled] = useState(true);

  const { createStudent, isLoading, error } = useCreateStudent();
  const {
    updateStudent,
    isLoading: updateLoading,
    error: updateError,
  } = useUpdateStudent();

  const {
    data: classOptions,
    error: getClassError,
    isInitialLoading: classLoading,
  } = useGetClass(school_id, "", enabled, true);

  const {
    data: sectionOptions,
    error: sectionError,
    isInitialLoading: sectionLoading,
    refetch: sectionRefetch,
  } = useGetSection({ class_id: input.class, enabled: school_id && true });

  const { toast } = useSharedToast();

  const goBack = () => {
    navigate("../");
  };

  useEffect(() => {
    if (!state) {
      setInput((prevState) => {
        return {
          ...prevState,
          section: "",
        };
      });
    }
  }, [input.class]);

  const ActionButton = () => {
    return (
      <div>
        <Button
          onClick={goBack}
          theme={"secondary cancel"}
          style={{ marginTop: "15px" }}
        >
          Exit
        </Button>
      </div>
    );
  };

  const showSuccess = () => {
    toast?.current?.show({
      severity: "success",
      summary: "Success",
      detail: "Student profile created successfully!",
    });
  };

  const showError = () => {
    toast?.current?.show({
      severity: "error",
      summary: "Invalid Form",
      detail: "Please enter all the details!",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //do nothing
    const isEmpty = checkEmpty(input);
    if (state) {
      let obj = {};
      Object.keys(input).map((item) => {
        if (input[item] == PROPS_DATA[item]) {
          return {};
        } else {
          return (obj[item] = input[item]);
        }
      });
      updateStudent({ inputs: obj, student_id: state?.student_id }).then(() => {
        // goBack();
        setInput(DEFAULT_FORM_DATA);
      });
      return false;
    }
    if (isEmpty) {
      showError();
      return false;
    }
    createStudent({ inputs: input, school_id: school_id }).then(() => {
      goBack();
      setInput(DEFAULT_FORM_DATA);
    });
    // setTimeout(() => {
    //   goBack();
    // }, 500)
  };

  const RenderActionButtons = ({ onSubmit }) => {
    return (
      <div
        style={{
          flex: 1,
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            width: "20%",
            margin: "10px",
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        >
          <Button
            type="submit"
            form="student-inputs"
            theme={"primary"}
            onClick={onSubmit}
          >
            {"Submit"}
          </Button>
        </div>
      </div>
    );
  };
  const CreateInputs = useMemo(() => {
    return (
      <>
        <form
          id="student-inputs"
          className="student-inputs"
          onSubmit={handleSubmit}
        >
          <div className="student-input-container">
            {CREATE_STUDENT_FORM.map(
              (
                {
                  label,
                  type,
                  options,
                  name,
                  placeholder,
                  uploadButton,
                  menuOnTop,
                  width,
                },
                index
              ) => {
                return (
                  <div className={`form-field ${name}`} key={index}>
                    <h4
                      style={{ flexDirection: "row", display: "inline-flex" }}
                    >
                      {label}
                      <p style={{ color: "red" }}>*</p>
                    </h4>
                    <div className={`${uploadButton && "upload-present"}`}>
                      {type !== "dragNdrop" && (
                        <FormInput
                          type={type}
                          name={name}
                          {...(type == "file" && {
                            onChange: async (e) => {
                              getBase64(e.target.files[0]).then((value) => {
                                setInput((prevState) => {
                                  return {
                                    ...prevState,
                                    [name]: value,
                                  };
                                });
                              });
                            },
                          })}
                          {...(type == "tel" && {
                            maxLength: 10,
                          })}
                          width={width && width}
                          options={
                            type === "select" && name == "class"
                              ? classOptions
                              : name == "section"
                              ? sectionOptions
                              : options
                              ? options
                              : []
                          }
                          {...(type !== "file" && { value: input })}
                          {...(type == "file" && {
                            accept: "image/png, image/gif, image/jpeg",
                          })}
                          setValue={setInput}
                          required={true}
                          menuOnTop={menuOnTop && menuOnTop}
                          placeholder={placeholder && placeholder}
                        />
                      )}
                      {type === "dragNdrop" && (
                        <div className="dragNdrop">{placeholder}</div>
                      )}
                      {uploadButton && (
                        <Button
                          theme={"blue-outline-button"}
                          icon={<FiUploadCloud color={"green"} />}
                        >
                          {uploadButton}
                        </Button>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </form>
      </>
    );
  }, [input, classOptions, sectionOptions]);

  return (
    <LayoutContainer
      label={"Create Student Profile"}
      RightComponent={<ActionButton />}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {CreateInputs}
        <RenderActionButtons onSubmit={handleSubmit} />
      </div>
    </LayoutContainer>
  );
}

import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';
import { useSharedToast } from '../../App';
import { convertToBase64Readable } from '../../utils/base64Encode';
import { convertTo24Hour } from '../../utils/convertTo24Hour';


const createChapterDetails = async ({ inputs, class_id, subject_id }) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/chapter/create`,
        method: `POST`,
        data: {
            chapter_name:inputs.name,
            board_id:inputs.board_id,
            class_id:class_id,
            chapter_image:convertToBase64Readable(inputs.icon),
            subject_id:subject_id,
            no_topic:inputs.no_topic,
            total_time:inputs.duration
        },
    })
    return queryResponse;
}


export const useCreateChapter = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: createChapter, isLoading, error } = useMutation(createChapterDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Chapter created successfully!' });
            }
        },
        onError: (error) => {
            console.log(error, 'error here');
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error creating the Chapter.' });
        },
    });

    return {
        createChapter: createChapter,
        isLoading,
        error,
    };
};

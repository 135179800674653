import React from 'react';
import './Pagination.scss';
import {FiChevronLeft as LeftIcon, FiChevronRight as RightIcon} from 'react-icons/fi'

function Pagination({ rowsPerPage, totalRows, paginate, currentPage }) {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleLeft = (number) => {
    if (number > 1) {
      paginate(number - 1);
    }
  };

  const handleRight = (number) => {
    if (number < totalPages) {
      paginate(number + 1);
    }
  };

  return (
    <div className="page-pagination-container">
      <ul className="page-pagination">
        <li className="btn" onClick={() => handleLeft(currentPage)}>
          <LeftIcon />
        </li>
        {pageNumbers.map((number) => {
          var highlight = currentPage === number ? 'active' : '';
          return (
            <li key={number}>
              <button
                onClick={() => paginate(number)}
                className={`page-link ${highlight}`}
              >
                {number}
              </button>
            </li>
          );
        })}
        <li className="btn" onClick={() => handleRight(currentPage)}>
          <RightIcon />
        </li>
      </ul>
    </div>
  );
}

export default Pagination;

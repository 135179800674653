import React, { useState, useContext, useRef } from 'react';
import './Form.scss';
import { Button, Input } from '../../../../components';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { Checkbox } from 'primereact/checkbox';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../../stores';
import useLogin from '../../../../services/useLogin';
import { Toast } from 'primereact/toast';
import { useSharedToast } from '../../../../App';
import {BiArrowBack} from 'react-icons/bi'
function Form() {
    const auth = null;
    const navigate = useNavigate();
    const setAuthenticated = useAuthStore((state) => state.setAuthenticated)
    const setToken = useAuthStore((state) => state.setToken)
    const setUser = useAuthStore((state) => state.setUser)
    const setSchoolId = useAuthStore((state) => state.setSchoolId)
    const { login, loading: buttonLoading } = useLogin();
    const {toast} = useSharedToast();

    const showSuccess = () => {
        toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Logged in successfully!' });
    };

    const showError = () => {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Please enter the correct credentials.' });
    };

    const handleForgotPassword = () => {
        setForgotPassword(prevState => !prevState);
    }

    const handleSubmit = async (event, email, password) => {
        event.preventDefault();
        if (email && password) {
            login({ email: email, password: password }).then((response) => {
                //alert
                const data = response.data;
                showSuccess();
                setToken(data.token);
                setSchoolId(data?.admin?.org_id);
                setUser(data.admin)
                    setAuthenticated();
                        window.location.reload()
                 
            }).catch((err) => {
                showError();
                setPassword('');
            })
        }
    };
    /* */
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordType, setPasswordType] = useState('password')
    const [phoneNumber, setPhoneNumber] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [OTPEnter, setOTPEnter] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('');


    const handleRememberMe = () => {
        setRememberMe(prevState => !prevState)
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const PasswordIcon = () => {
        if (passwordType == 'password') {
            return <AiOutlineEye />
        }
        else {
            return <AiOutlineEyeInvisible />
        }
    }

    const handleNumberInput = (e, phoneNumber) => {
        e.preventDefault();
        if(phoneNumber)
        {
            setOTPEnter(true);
        }
    }
    const handleOTPInput = (e, otp) => {
        e.preventDefault();
        if(otp)
        {
            
        }
    }

    const handleOTPBack = () => {
        setOTPEnter(false);
        setForgotPassword(true);
    }

    if(OTPEnter)
    {
        return (
            <div className="form-wrapper">
            {/* <Toast ref={toast} /> */}
            <div className='forgot-container'>
                <BiArrowBack className='back-button' onClick={handleOTPBack}/>
                <p className='text-forgot'>Forgot Password</p>
                <p className='text-forgot'></p>
                </div>
            <form
                className="form-container"
                onSubmit={(e) => {
                    handleOTPInput(e, otp);
                }}
            >
                <h2 style={{fontWeight:600}}>Enter your OTP</h2>
                <Input
                    label=""
                    name="otp-enter"
                    placeholder="Enter OTP sent to mobile no."
                    type="tel"
                    maxLength={4}
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    variant={'large'}
                />
                <p className='info-text'>{`A 4 digit code  has been sent to +91 ${phoneNumber}`}</p>
                {/* <div className='form-bottom-container'>
                </div> */}
                <div className='empty-space'>
                    </div>
                <div className="signin-container">
                    <Button loading={buttonLoading} theme={'primary'} type="submit" onClick={(e) => handleOTPInput(e, otp)}>
                        Next
                    </Button>
                </div>
            </form>
            <div className='form-terms-and-conditions-container'>
                <p className='link'></p>
            </div>
        </div>
        )
    }

    if(forgotPassword)
    {
        return (
            <div className="form-wrapper">
            {/* <Toast ref={toast} /> */}
            <div className='forgot-container'>
                <BiArrowBack className='back-button' onClick={handleForgotPassword}/>
                <p className='text-forgot'>Forgot Password</p>
                <p className='text-forgot'></p>
                </div>
            <form
                className="form-container"
                onSubmit={(e) => {
                    handleNumberInput(e, phoneNumber);
                }}
            >
                <h2 style={{fontWeight:600}}>Enter your mobile no</h2>
                <Input
                    label=""
                    name="phone-number"
                    placeholder="Enter your registered mobile no."
                    type="tel"
                    maxLength={10}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    variant={'large'}
                />
                {/* <div className='form-bottom-container'>
                </div> */}
                <div className='empty-space'>
                    </div>
                <div className="signin-container">
                    <Button loading={buttonLoading} theme={'primary'} type="submit" onClick={(e) => handleNumberInput(e, phoneNumber)}>
                        Next
                    </Button>
                </div>
            </form>
            <div className='form-terms-and-conditions-container'>
                <p>By signing up you accept the <a className="link" href='#'>Terms of Service</a> and <a className="link" href='#'>Privacy Policy</a></p>
            </div>
        </div>
        )
    }
    return (
        <div className="form-wrapper">
            {/* <Toast ref={toast} /> */}
            <div className='form-welcome-container'>
                <p>👋</p>
                <p className='text'>Login</p>
            </div>
            <form
                className="form-container"
                onSubmit={(e) => {
                    handleSubmit(e, email, password);
                }}
            >
                <Input
                    label="E-mail"
                    name="E-mail"
                    placeholder="Enter your registered e-mail"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant={'large'}
                />
                <Input
                    label="Password"
                    name="password"
                    placeholder="Enter password"
                    type={passwordType}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant={'large'}
                    icon={
                        <div onClick={togglePassword} style={{ justifyContent: 'center', display: 'flex' }}>
                            <PasswordIcon />
                        </div>
                    }
                />
                <div className="form-error">{auth?.error && auth?.error?.message}</div>
                <div className='form-bottom-container'>
                    <div className="remember-me-container">
                        <Checkbox inputId="remember_me1" name="Remember me" value={'rememberMe'} size={10} onChange={handleRememberMe} checked={rememberMe} />
                        <label htmlFor="remember_me1" className="remember-me">Remember me</label>
                    </div>
                    {/* <div onClick={handleForgotPassword} className='forgot-password-container'>
                        <p>Forgot Password?</p>
                    </div> */}
                </div>
                <div className="signin-container">
                    <Button loading={buttonLoading} theme={'primary'} type="submit" onClick={(e) => handleSubmit(e, email, password)}>
                        Log In
                    </Button>
                </div>
            </form>
            <div className='form-terms-and-conditions-container'>
                <p>By signing up you accept the <a className="link" href='#'>Terms of Service</a> and <a className="link" href='#'>Privacy Policy</a></p>
            </div>
        </div>
    );
}

export default Form;

import React, { useMemo, useState, useCallback } from "react";
import { FiUploadCloud } from "react-icons/fi";
import { Avatar, Button, LayoutContainer } from "../../../../components";
import { useLocation, useNavigate } from "react-router-dom";

import { useSharedToast } from "../../../../App";
import {
  CREATE_SCHOOl_FORM,
  CREATE_STUDENT_FORM,
  DEFAULT_FORM_DATA,
} from "./constants";
import FormInput from "../../../../components/FormInput/Input";

import { checkEmpty } from "../../../../utils/checkEmpty";
import { getBase64 } from "../../../../utils/base64Encode";
import { useGetClass } from "../../../../queryHooks/Classes/useGetClass/useGetClass";
import { useGetSection } from "../../../../queryHooks/Section/useGetSection/useGetSection";
import { useEffect } from "react";
import { useAuthStore } from "../../../../stores";
import "./CreateSchool.scss";
import { useCreateSchool } from "../../../../queryHooks/School/useCreateSchool/useCreateSchool";
import Dropzone, { useDropzone } from "react-dropzone";
import { useGetBoard } from "../../../../queryHooks/Board/useGetBoard/useGetBoard";

export default function CreateSchool() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [input, setInput] = useState(DEFAULT_FORM_DATA);
  const [fileNames, setFileNames] = useState({});
  const user = useAuthStore((state) => state.user);
  const school_id = useAuthStore((state) => state.school_id);
  const { createSchool, isLoading, error } = useCreateSchool();

  const {
    data: boards,
    error: getBoardError,
    isInitialLoading: boardLoading,
  } = useGetBoard({ school_id: true, enabled: true });

  const { toast } = useSharedToast();

  const goBack = () => {
    navigate("../");
  };

  const ActionButton = () => {
    return (
      <div>
        <Button
          onClick={goBack}
          theme={"secondary cancel"}
          style={{ marginTop: "15px" }}
        >
          Exit
        </Button>
      </div>
    );
  };

  const showSuccess = () => {
    toast?.current?.show({
      severity: "success",
      summary: "Success",
      detail: "School profile created successfully!",
    });
  };

  const showError = () => {
    toast?.current?.show({
      severity: "error",
      summary: "Invalid Form",
      detail: "Please enter all the details!",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //do nothing
    const isEmpty = checkEmpty(input);
    if (isEmpty) {
      showError();
      return false;
    }
    if (input.password !== input.confirm_password) {
      toast?.current?.show({
        severity: "warn",
        summary: "Invalid",
        detail: "Password does not match! please re-enter",
      });
      // setInput((prevState) => {
      //   return {
      //     ...prevState,
      //     password: "",
      //     confirm_password: "",
      //   };
      // });
      return false;
    }


    createSchool({ inputs: input }).then(() => {
      goBack();
      setInput(DEFAULT_FORM_DATA);
    });
    setTimeout(() => {
      goBack();
    }, 500)
  };

  const handleDrop = useCallback((name, e) => {
    //access file dropped.
    const fileName = e[0].name;
    getBase64(e[0]).then((value) => {
      setFileNames((prevState) => {
        return {
          ...prevState,
          [name]: fileName,
        };
      });
      setInput((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    });
  }, []);

  const RenderActionButtons = ({ onSubmit }) => {
    return (
      <div
        style={{
          flex: 1,
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            width: "20%",
            margin: "10px",
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        >
          <Button
            type="submit"
            form="school-inputs"
            theme={"primary"}
            onClick={onSubmit}
          >
            {"Submit"}
          </Button>
        </div>
      </div>
    );
  };
  const CreateInputs = useMemo(() => {
    return (
      <>
        <form
          id="school-inputs"
          className="school-inputs"
          onSubmit={handleSubmit}
        >
          <div className="school-input-container">
            {CREATE_SCHOOl_FORM.map(
              (
                {
                  label,
                  type,
                  options,
                  name,
                  placeholder,
                  uploadButton,
                  menuOnTop,
                  width,
                },
                index
              ) => {
                return (
                  <div className={`form-field ${name}`} key={index}>
                    <h4
                      style={{ flexDirection: "row", display: "inline-flex" }}
                    >
                      {label}
                      <p style={{ color: "red" }}>*</p>
                    </h4>
                    <div className={`${uploadButton && "upload-present"}`}>
                      {type !== "dragNdrop" && (
                        <FormInput
                          type={type}
                          name={name}
                          {...(type == "file" && {
                            onChange: async (e) => {
                              getBase64(e.target.files[0]).then((value) => {
                                setInput((prevState) => {
                                  return {
                                    ...prevState,
                                    [name]: value,
                                  };
                                });
                              });
                            },
                          })}
                          {...(type == "tel" && {
                            maxLength: 10,
                          })}
                          width={width && width}
                          options={
                            type === "select" && name === "board_id"
                              ? boards
                              : type === "select" && options
                              ? options
                              : []
                          }
                          {...(type !== "file" && { value: input })}
                          {...(type == "file" && {
                            accept: "image/png, image/gif, image/jpeg",
                          })}
                          setValue={setInput}
                          required={true}
                          menuOnTop={menuOnTop && menuOnTop}
                          placeholder={placeholder && placeholder}
                        />
                      )}
                      {type === "dragNdrop" && (
                        <Dropzone
                          onDrop={(acceptedFileItems) =>
                            handleDrop(name, acceptedFileItems)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section className="drop-container">
                              <div {...getRootProps({ className: "dropzone" })}>
                                <input {...getInputProps()} />
                                <p className="drop-text">
                                  {fileNames[name]
                                    ? fileNames[name]
                                    : placeholder}
                                </p>
                                {!fileNames[name] && (
                                  <p className="drop-subtext">
                                    {`(Drag and Drop or Click to upload)`}
                                  </p>
                                )}
                              </div>
                              {input[name] ? (
                                <img
                                  src={input[name]}
                                  style={{
                                    height: "44px",
                                    width: "44px",
                                    borderRadius: "50px",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    alignSelf: "center",
                                  }}
                                />
                              ) : null}
                            </section>
                          )}
                        </Dropzone>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </form>
      </>
    );
  }, [input]);

  return (
    <LayoutContainer
      label={"Create New School"}
      RightComponent={<ActionButton />}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {CreateInputs}
        <RenderActionButtons onSubmit={handleSubmit} />
      </div>
    </LayoutContainer>
  );
}

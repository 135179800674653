import React, { useCallback, useState } from 'react'


import './ClassModule.scss';
import { LayoutContainer, Pagination } from '../../components';
import { Skeleton } from 'primereact/skeleton';
import { useAuthStore } from '../../stores';
import { Outlet, useNavigate, useOutlet } from 'react-router-dom';
import ClassListItem from './components/ClassListItem/ClassListItem';
import SectionModal from './modals/SectionModal/SectionModal';
import { useQueryClient } from 'react-query';
import { useCreateSection } from '../../queryHooks/Section/useCreateSection/useCreateSection';
import { useGetClass } from '../../queryHooks/Classes/useGetClass/useGetClassSuperAdmin';

export default function ClassModule() {
    const outlet = useOutlet();
    const navigate = useNavigate();
    const user = useAuthStore(state => state.user);
    const isSuperAdmin=user?.admin_type === "super_admin" ?true:false
    const school_id = useAuthStore(state => state.school_id)
    const [sectionsModalVisible, setSectionsModalVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedBoard, setSelectedBoard] = useState(null);
    const enabled=isSuperAdmin?school_id !== "null"?true:false:true

    //Get Current rows
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const { data: classes, error: classError, isLoading: classLoading, refetch: classRefetch } = useGetClass(school_id,"",enabled,true )

    const { createSection, isLoading, error } = useCreateSection();

    const currentRows = classes?.slice(indexOfFirstRow, indexOfLastRow);

    const queryClient = useQueryClient();

    const NoContent = () => {
        return (
            <div className="no-content">
                <p>You haven't created any of the classes yet.</p>
            </div>
        );
    };



    const SkeletonLoader = () => {
        return (
            <div className='teacher-wrapper'>
                {Array.from(Array(10).keys()).map((_, index) => {
                    return <div key={index} className="user-item-container" style={{ backgroundColor: 'transparent' }}>
                        <Skeleton height='125px' key={index} />
                    </div>
                })}
            </div>
        )
    }

    const handleCreateSection = async ({ section_name, section_lock }) => {
        createSection({ board_id: selectedBoard, section_name: section_name, section_lock: section_lock, school_id: school_id, class_id: selectedClass }).then(() => {
            queryClient.invalidateQueries({
                queryKey: ['getSection', selectedClass]
            })
            setSelectedBoard(null)
            setSelectedClass(null)
            setSectionsModalVisible(false);
        })
    }
    const CreateSectionsModal = useCallback(() => {
        return (
            <SectionModal
                visible={sectionsModalVisible}
                setVisible={setSectionsModalVisible}
                class_id={selectedClass}
                handleCreate={handleCreateSection}
            />
        )
    }, [sectionsModalVisible, selectedClass])

    const handleAddButtonPress = (class_id, board_id) => {
        setSelectedClass(class_id)
        setSelectedBoard(board_id)
        setSectionsModalVisible(true);
    }

    if (outlet) {
        return (
            <Outlet />
        )
    }

    return (
        <>
            <CreateSectionsModal />
            <LayoutContainer label={"Classes"} >
                <div className="teacher-container">
                    {classLoading ?
                        <SkeletonLoader />
                        : classes && classes.length !== 0 ?
                            <div className='class-module-wrapper-main'>
                                {currentRows?.map((item, index) => {
                                    return (
                                        <ClassListItem
                                            class_id={item?.class_id}
                                            item={item}
                                            school_id={item?.school_id}
                                            class_name={item?.class_name}
                                            index={index}
                                            onClick={handleAddButtonPress}
                                        />
                                    )
                                })}
                            </div> : <NoContent />}
                </div>
                {classes?.length !== 0 && <div className="footer-wrapper">
                    <h4 className="show-rows">
                        Showing {indexOfFirstRow === 0 ? '1' : indexOfFirstRow} to{' '}
                        {indexOfLastRow} of {classes?.length} Result
                    </h4>
                </div>}
                {rowsPerPage <= classes?.length && (
                    <Pagination
                        rowsPerPage={rowsPerPage}
                        totalRows={classes?.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                )}
            </LayoutContainer>
        </>
    )
}

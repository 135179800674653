// {
//     "id": 5,
//     "student_id": "8gzVBlyf5PvjEMpgCaBBx",
//     "first_name": "Jake",
//     "active": 1,
//     "address": null,
//     "middle_name": "",
//     "last_name": "Peralta",
//     "school_id": "1",
//     "class_id": "class_9207d31b0cca",
//     "section": "2",
//     "guardian_name": "Ginny Peralta",
//     "contact_no": "9910995379",
//     "id_no": "6",
//     "dob": "03/04/2000",
//     "user_id": "user_b4bc8f6b48d2",
//     "gender": "male",
//     "avtar": "https://devunitbucket.s3.ap-south-1.amazonaws.com/student/1685474864381.png",
//     "date_created": "2023-05-30",
//     "date_modified": "2023-05-30"
// }

export const SHOW_STUDENT_FORM = [
    {
        label: "Mother Name",
        name: "guardian_name",
    },
    {
        label: "Emergency Contact No.",
        name: "contact_no",
    },
    {
        label: "Class",
        name: "class_id",
    },
    {
        label: "Date of Birth",
        name: "dob",
    },
    {
        label: "Gender",
        name: "gender",
    },
]
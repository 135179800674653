import { useQuery, useQueryClient } from "react-query";
import request from "../../utils/request";
import { BASE_URL } from "../../Config";


const getChapters = async ({ queryKey }) => {
    const [_, data] = queryKey; 
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/chapter/getchapter?subject_id=${data}`,
        method: 'GET',
    })
    return queryResponse;
}

export const useGetChapters = ({ subject_id, enabled }) => {
    const queryClient = useQueryClient();
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getChapters', subject_id],
        queryFn: getChapters,
        enabled: subject_id ? true : false,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;
    const formattedData = responseData?.map((item, index) => {
        let obj = {
            label: item.chapter_name,
            value:item.chapter_id
        }
        return {...item, ...obj}
    })
    const sortedData = formattedData?.sort((a,b) => Number(a?.id) - Number(b?.id))

    return { data: sortedData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
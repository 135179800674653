import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';
import { useSharedToast } from '../../App';
import { convertToBase64Readable } from '../../utils/base64Encode';
import { convertTo24Hour } from '../../utils/convertTo24Hour';


const createCircularDetails = async ({ inputs, school_id }) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/class/createEvent`,
        method: `POST`,
        data: {
            event_name:inputs.event_name,
            event_date:`${inputs.DD}-${inputs.MM}-${inputs.YYYY}`,
            event_time:convertTo24Hour(inputs.event_time),
            event_type:inputs.event_type,
            document:convertToBase64Readable(inputs.document),
            description:inputs.description,
            school_id:school_id
        },
    })
    return queryResponse;
}


export const useCreateCircular = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: createCircular, isLoading, error } = useMutation(createCircularDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Circular created successfully!' });
            }
        },
        onError: (error) => {
            console.log(error, 'error here');
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error creating the Circular.' });
        },
    });

    return {
        createCircular: createCircular,
        isLoading,
        error,
    };
};

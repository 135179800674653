import React from 'react'
import Loader from '../../components/Loader/Loader'
import './Loading.scss'

export default function Loading() {
    return (
        <div className='loading-container'
        ><Loader primary={true} /></div>
    )
}

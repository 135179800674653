import { useQuery, useQueryClient } from "react-query";
import request from "../../utils/request";
import { BASE_URL } from "../../Config";


const getStudent = async ({ queryKey }) => {
    const [_, data] = queryKey;
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/student/get?class_id=${data.class_id}&school_id=${data.school_id}&section=${data.section_id}`,
        method: 'GET',
    })
    return queryResponse;
}

export const useGetStudents = ({ class_id, section_id, school_id, enabled }) => {
    const queryClient = useQueryClient();
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getStudents', {class_id, section_id, school_id}],
        queryFn: getStudent,
        enabled: (class_id && section_id && enabled) ? true : false,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;
    return { data: responseData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
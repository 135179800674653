import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Modal } from "../../../../components";
import {
  DEFAULT_INPUT_VALUE,
  DEFAULT_NOTES_INPUT_VALUE,
  PDF_INPUTS,
  VIDEO_INPUTS,
} from "./constants";
import Dropzone from "react-dropzone";
import FormInput from "../../../../components/FormInput/Input";
import {
  convertToBase64Readable,
  getBase64,
} from "../../../../utils/base64Encode";

import "./NotesModal.scss";
import { useAuthStore } from "../../../../stores";
import { useCreateClass } from "../../../../queryHooks/Classes/useCreateClass/useCreateClass";
import { useSharedToast } from "../../../../App";
import { checkEmpty } from "../../../../utils/checkEmpty";
import { useQueryClient } from "react-query";
import request from "../../../../utils/request";
import { BASE_URL } from "../../../../Config";
import { BiUpload } from "react-icons/bi";
import { ProgressBar } from "primereact/progressbar";

export default function NotesModal({
  visible,
  setVisible,
  props,
  open = "",
  videos,
  videoIndex,
}) {
  const [input, setInput] = useState(DEFAULT_INPUT_VALUE);
  const [noteInput, setNoteInput] = useState(DEFAULT_NOTES_INPUT_VALUE);
  const [fileName, setFileName] = useState(DEFAULT_INPUT_VALUE);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [noteFileName, setNoteFileName] = useState(DEFAULT_NOTES_INPUT_VALUE);
  const [noteFile, setNoteFile] = useState(null);
  const [file, setFile] = useState(null);
  const user = useAuthStore((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("video");
  const school_id = useAuthStore((state) => state.school_id);
  const { toast } = useSharedToast();
  const { class_id, subject_id, topic_id, chapter_id } = props;

  useEffect(() => {
    setSelectedItem(open);
  }, []);

  const TYPES = [
    {
      title: "Upload Video",
      type: "video",
    },
    {
      title: "Upload PDF / PPT",
      type: "others",
    },
  ];

  const onHide = () => {
    setVisible(false);
    queryClient.invalidateQueries({ queryKey: ["getNotes"] });
    queryClient.invalidateQueries({ queryKey: ["getVideos"] });
  };

  const queryClient = useQueryClient();

  const showError = () => {
    toast?.current?.show({
      severity: "error",
      summary: "Invalid Form",
      detail: "Please enter all the details!",
    });
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const isEmpty =
        selectedItem === "video" ? checkEmpty(input) : checkEmpty(noteInput);
      if (isEmpty) {
        showError();
        return false;
      }
      let data = new FormData();
      const videoImage = await getBase64(input?.video_image);
      const video = input?.video_file;
      data.append("video", video);
      data.append("video_image", convertToBase64Readable(videoImage));
      setShowProgress(true);
      var response = await request({
        url: `${BASE_URL}/api/admin/topic/createPresentation?class_id=${class_id}&subject_id=${subject_id}&chapter_id=${chapter_id}&name=${input?.video_caption}&time=${input?.time_required}&description=${input?.description}&index=${input?.video_index}`,
        method: `POST`,
        data: data,
        onUploadProgress: (progressEvent) => {
          let percentComplete = progressEvent.loaded / progressEvent.total;
          percentComplete = parseInt(percentComplete * 100);
          setProgress(percentComplete);
        },
      });
      // console.log('data', response.data);
      if (response.status === 200) {
        setShowProgress(false);
        setLoading(false);
        setFile(null);
        setFileName("");
        toast?.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Video Uploaded successfully!",
        });
        onHide();
      } else {
        setShowProgress(false);
        setLoading(false);
        setProgress(0);
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: "There was an error uplaoding video. please try again!",
        });
      }
    } catch (err) {
      setShowProgress(false);
      setLoading(false);
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: "There was an error creating Note(s). please try again!",
      });
    }
  };

  const handleDrop = useCallback(
    (name, e) => {
      //access file dropped.
      if (selectedItem == "video") {
        const fileName = e[0].name;
        var output = fileName.substr(0, fileName.lastIndexOf(".")) || fileName; //remove extension
        setFileName((prevState) => {
          return {
            ...prevState,
            [name]: output,
          };
        });
        setInput((prevState) => {
          return {
            ...prevState,
            [name]: e[0],
          };
        });
      } else {
        const fileName = e[0].name;
        var output = fileName.substr(0, fileName.lastIndexOf(".")) || fileName; //remove extension
        setNoteFileName((prevState) => {
          return {
            ...prevState,
            [name]: output,
          };
        });
        setNoteInput((prevState) => {
          return {
            ...prevState,
            [name]: e[0],
          };
        });
      }
    },
    [noteInput, input, fileName, noteFileName]
  );

  const handleType = (type) => {
    setSelectedItem(type);
  };

  const RenderTypes = () => {
    return (
      <div className="note-type-container">
        {TYPES.map((item, index) => {
          return (
            <div
              className={`note-type-item`}
              key={index}
              onClick={() => handleType(item.type)}
            >
              <div style={{ marginBottom: 10 }}>
                <BiUpload size={25} />
              </div>
              <p className="note-title">{item.title}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const CreateInputs = useMemo(() => {
    if (selectedItem == "video") {
      return (
        <>
          <form
            id="class-inputs"
            className="class-inputs"
            onSubmit={handleUpload}
          >
            <div className="class-input-container">
              {VIDEO_INPUTS.map(
                (
                  {
                    label,
                    type,
                    options,
                    name,
                    placeholder,
                    uploadButton,
                    menuOnTop,
                    width,
                    media_type,
                  },
                  index
                ) => {
                  return (
                    <div className={`modal-form-field ${name}`} key={index}>
                      <h4
                        style={{ flexDirection: "row", display: "inline-flex" }}
                      >
                        {label}
                        <p style={{ color: "red" }}>*</p>
                      </h4>
                      <div className={`${uploadButton && "upload-present"}`}>
                        {type !== "dragNdrop" && (
                          <FormInput
                            type={type}
                            name={name}
                            {...(type == "dragNdrop" && {
                              onChange: async (e) => {
                                getBase64(e.target.files[0]).then((value) => {
                                  setInput((prevState) => {
                                    return {
                                      ...prevState,
                                      [name]: value,
                                    };
                                  });
                                });
                              },
                            })}
                            {...(type == "tel" && {
                              maxLength: 10,
                            })}
                            width={width && width}
                            options={
                              type === "select" && options ? options : []
                            }
                            {...(type !== "file" && { value: input })}
                            {...(type == "file" && {
                              accept: "video/*, image/*",
                            })}
                            setValue={setInput}
                            required={true}
                            menuOnTop={menuOnTop && menuOnTop}
                            placeholder={placeholder && placeholder}
                          />
                        )}
                        {type === "dragNdrop" && (
                          <Dropzone
                            onDrop={(acceptedFileItems) =>
                              handleDrop(name, acceptedFileItems)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section className="drop-container">
                                <div
                                  {...getRootProps({ className: "dropzone" })}
                                >
                                  <input {...getInputProps()} />
                                  <p className="drop-text">
                                    {fileName[name]
                                      ? fileName[name]
                                      : placeholder}
                                  </p>
                                  {!fileName[name] && (
                                    <p className="drop-subtext">
                                      {`(Drag and Drop or Click to upload)`}
                                    </p>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </form>
        </>
      );
    } else {
      return (
        <>
          <form
            id="class-inputs"
            className="class-inputs"
            onSubmit={handleUpload}
          >
            <div className="class-input-container">
              {PDF_INPUTS.map(
                (
                  {
                    label,
                    type,
                    options,
                    name,
                    placeholder,
                    uploadButton,
                    menuOnTop,
                    width,
                    media_type,
                  },
                  index
                ) => {
                  if (media_type && media_type !== selectedItem) {
                    return false;
                  }
                  return (
                    <div className={`modal-form-field ${name}`} key={index}>
                      <h4
                        style={{ flexDirection: "row", display: "inline-flex" }}
                      >
                        {label}
                        <p style={{ color: "red" }}>*</p>
                      </h4>
                      <div className={`${uploadButton && "upload-present"}`}>
                        {type !== "dragNdrop" && (
                          <FormInput
                            type={type}
                            name={name}
                            {...(type == "file" && {
                              onChange: async (e) => {
                                getBase64(e.target.files[0]).then((value) => {
                                  setNoteInput((prevState) => {
                                    return {
                                      ...prevState,
                                      [name]: value,
                                    };
                                  });
                                });
                              },
                            })}
                            {...(type == "tel" && {
                              maxLength: 10,
                            })}
                            width={width && width}
                            options={
                              type === "select" && options ? options : []
                            }
                            {...(type !== "file" && { value: noteInput })}
                            {...(type == "file" && {
                              accept: ".pptx, .pdf, .ppt, .doc",
                            })}
                            setValue={setNoteInput}
                            required={true}
                            menuOnTop={menuOnTop && menuOnTop}
                            placeholder={placeholder && placeholder}
                          />
                        )}
                        {type === "dragNdrop" && (
                          <Dropzone
                            onDrop={(acceptedFileItems) =>
                              handleDrop(name, acceptedFileItems)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section className="drop-container">
                                <div
                                  {...getRootProps({ className: "dropzone" })}
                                >
                                  <input {...getInputProps()} />
                                  <p className="drop-text">
                                    {noteFileName[name]
                                      ? noteFileName[name]
                                      : placeholder}
                                  </p>
                                  {!noteFileName[name] && (
                                    <p className="drop-subtext">
                                      {`(Drag and Drop or Click to upload) [Video, PPT, PDF]`}
                                    </p>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </form>
        </>
      );
    }
  }, [input, selectedItem, noteInput, noteFileName, fileName]);

  return (
    <Modal
      header={"Update Presentation Video"}
      headerStyle={{ textAlign: "center" }}
      headerClassName={"modal-header"}
      visible={visible}
      onHide={onHide}
      onCreate={handleUpload}
    >
      {showProgress ? (
        <div>
          <ProgressBar color={`#C467FF`} value={progress} />
          <div>
            <p>Uploading ...</p>
          </div>
        </div>
      ) : selectedItem == "" ? (
        <RenderTypes />
      ) : (
        <div>
          <div>{CreateInputs}</div>
        </div>
      )}
    </Modal>
  );
}

import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';
import { useSharedToast } from '../../App';
import { convertToBase64Readable } from '../../utils/base64Encode';

const splitName = (name = '') => {
    const [firstName, ...lastName] = name.split(' ').filter(Boolean);
    return {
        firstName: firstName,
        lastName: lastName.join(' '),
    };
};

/* 
 "first_name":"shiv",
  "address":"testing adderess",
  "middle_name":"",
  "last_name":"kumar",
  "school_id":"school_id6268bd7d0d18",
  "class_id":"class_9207d31b0cca",
  "section":"1",
  "guardian_name":"shiv ji",
  "contact_no":"8700300731",
  "id_no":"1",
  "dob":"",
  "email_id":"shivkr98188+student2@gmail.com",
  "password":"123456789",
  "gender":"Male",
  "idNo":"1",
  "avtar":"",
  "bus_no":"1"
  */


const createStudentDetails = async ({ inputs, school_id }) => {
    const fullName = splitName(inputs.name)
    const dob = `${inputs.DD}/${inputs.MM}/${inputs.YYYY}`
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/student/create`,
        method: `POST`,
        data: {
            first_name: fullName.firstName,
            middle_name: "",
            last_name: fullName.lastName,
            school_id: school_id.toString(),
            class_id: inputs.class,
            section: inputs.section,
            guardian_name: inputs.mother_name,
            contact_no: inputs.contact_no,
            id_no: inputs.id_no,
            dob: dob,
            email_id: inputs.email_id,
            password: inputs.password,
            gender: inputs.gender,
            idNo: inputs.id_no,
            avtar: convertToBase64Readable(inputs.photo),
            // bus_no: inputs.bus_no
        },
    })
    return queryResponse;
}


export const useCreateStudent = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: createStudent, isLoading, error } = useMutation(createStudentDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Student profile created successfully!' });
            }
        },
        onError: (error) => {
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error creating the student profile.' });
        },
    });

    return {
        createStudent,
        isLoading,
        error,
    };
};

import axios from "axios";
import { BASE_URL } from "../Config";
import loaderStore from "../stores/useLoader";

// optionaly add base url
const client = axios.create({ baseURL: `${BASE_URL}` });

const request = async ({ ...options }) => {
  const token = localStorage.getItem("accessToken");
  if (
    options?.url?.includes("create") ||
    // options?.method?.toLowerCase() === "delete" ||
    options?.method?.toLowerCase() === "put"
  ) {
    loaderStore.setState({ loader: true });
  }
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
  const onSuccess = (response) => {
    loaderStore.setState({ loader: false });
    return response;
  };
  const onError = (error) => {
    loaderStore.setState({ loader: false });
    return error;
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;

import React, { useCallback, useMemo, useState } from "react";
import { Button, DataHeader, Input, LayoutContainer } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { FiEdit, FiPlus } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { CREATE_TEACHER_FORM, DEFAULT_CLASS_OPTIONS, DEFAULT_FORM_DATA, DEFAULT_PERIOD_OPTIONS, DEFAULT_SECTION_OPTIONS, DEFAULT_SUBJECT_OPTIONS } from "./constants";
import FormInput from "../../../../components/FormInput/Input";
import { FiUploadCloud } from 'react-icons/fi';
import { AiOutlineCheck } from "react-icons/ai";
import './CreateTeacher.scss'
import Table from "../../components/Table/Table";
import TableSelect from "../../../../components/TableSelect/TableSelect";
import { getCurrentDimension } from "../../../../utils/getCurrentDimension";
import { useAuthStore } from "../../../../stores";
import { useGetSubject } from "../../../../queryHooks/Subject/useGetSubject/useGetSubject";
import { useSharedToast } from "../../../../App";
import { checkEmpty } from "../../../../utils/checkEmpty";
import { useCreateTeacher } from "../../../../queryHooks/useCreateTeacher/useCreateTeacher";
import { useGetSection } from "../../../../queryHooks/Section/useGetSection/useGetSection";
import { getBase64 } from "../../../../utils/base64Encode";
import { useQueryClient } from "react-query";
import { useGetClass } from "../../../../queryHooks/Classes/useGetClass/useGetClassSuperAdmin";


const columnHeader = [
  { path: 'class', title: 'Class' },
  { path: 'section', title: 'Section' },
  { path: 'period', title: 'Period' },
  { path: 'subject', title: 'Subject' },
]


const handleSelectionValues = (rowId, data, value, setValue, cb) => {
  let arr = value;
  arr[rowId] = data;
  setValue(arr);
  if (cb && typeof cb == 'function') {
    cb();
  }
}

const handleAllSelectionValues = (rowId, data, value, setValue) => {
  let arr = value;
  arr[rowId] = data;
  setValue(arr);
}

const TableSelection = ({ rowId, options, value, type, setValue, setAllValues }) => {
  return <TableSelect
    options={options}
    theme={'transparent'}
    value={value[rowId]}
    setAllValue={(data) => setAllValues(data, rowId, type)}
    setValue={(data) => setValue(data, rowId, type)}
  />
}

const RowSelectComponent = ({ setClassValues, setSectionValues, setAllSectionValues, setAllClassValues, allSubjectValues, allClassValues, setAllSubjectValues, setSubjectValues, classValues, subjectValues, allSectionValues, sectionValues, rowId ,periodValues, setPeriodValues}) => {
  const user = useAuthStore(state => state.user);
  const school_id = useAuthStore(state => state.school_id)
  const [classId, setClassId] = useState(null);
  const { data: classOptions, error: getClassError, isInitialLoading: classLoading } = useGetClass(school_id,"",true)
  const { data: subjectOptions, error: subjectError, isInitialLoading: subjectLoading, refetch: subjectRefetch } = useGetSubject({ class_id: classValues[rowId], rowId: rowId, school_id: school_id, enabled: school_id && true })

  const { data: sectionOptions, error: sectionError, isInitialLoading: sectionLoading, refetch: sectionRefetch } = useGetSection({ class_id: classValues[rowId], rowId: rowId, enabled: school_id && true })

  const periods=DEFAULT_PERIOD_OPTIONS

  const HandleSelectionValueByType = (data, rowId, type) => {
    switch (type) {
      case 'subject':
        return handleSelectionValues(rowId, data, subjectValues, setSubjectValues)
      case 'class':
        return handleSelectionValues(rowId, data, classValues, setClassValues, () => {
          subjectRefetch();
        })
      case 'period':
        return handleSelectionValues(rowId, data, periodValues, setPeriodValues)
      case 'section':
        return handleSelectionValues(rowId, data, sectionValues, setSectionValues)
      default:
        return () => {
          // nothing
        }
    }
  }
  const _handleAllSelectionValues = (data, rowId, type) => {
    switch (type) {
      case 'subject':
        return handleAllSelectionValues(rowId, data, allSubjectValues, setAllSubjectValues)
      case 'class':
        return handleAllSelectionValues(rowId, data, allClassValues, setAllClassValues)
      case 'section':
        return handleAllSelectionValues(rowId, data, allSectionValues, setAllSectionValues)
      case 'period':
          return handleAllSelectionValues(rowId, data, periodValues, setPeriodValues)
      default:
        return () => {
          // nothing
        }
    }
  }

  const RenderSelectionByType = ({ rowId, type }) => {
    switch (type) {
      case 'subject':
        return <TableSelection rowId={rowId} options={subjectOptions} value={subjectValues} type={type} setValue={HandleSelectionValueByType} setAllValues={_handleAllSelectionValues} />
      case 'class':
        return <TableSelection rowId={rowId} options={classOptions} value={classValues} type={type} setValue={HandleSelectionValueByType} setAllValues={_handleAllSelectionValues} />
      case 'section':
        return <TableSelection rowId={rowId} options={sectionOptions} value={sectionValues} type={type} setValue={HandleSelectionValueByType} setAllValues={_handleAllSelectionValues} />
      case 'period':
        return <TableSelection rowId={rowId} options={periods} value={periodValues.map(s=>{return s.value})} type={type} setValue={HandleSelectionValueByType} setAllValues={_handleAllSelectionValues} />
      default:
        return null
    }
  }


  const RenderRowItem = ({ row }) => {
    return columnHeader.map((column, i) => {
      const path = column.path;
      return (
        <td key={i}>
          {<RenderSelectionByType rowId={row} type={path} />}
        </td>
      )
    }
    )
  }
  return <RenderRowItem row={rowId} />
}

export default function CreateTeacher() {
  const { height, width } = getCurrentDimension();
  const user = useAuthStore(state => state.user);
  const school_id = useAuthStore(state => state.school_id)
  const isSuperAdmin=user?.admin_type === "super_admin" ?true:false
  const { toast } = useSharedToast();
  const navigate = useNavigate();
  const [curStep, setCurStep] = useState(0);
  const [input, setInput] = useState(DEFAULT_FORM_DATA)
  const [percent, setPercent] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(-1);
  const [noOfRows, setNoOfRows] = useState(1);
  const [classValues, setClassValues] = useState([]);
  const [allClassValues, setAllClassValues] = useState([]);
  const [periodValues, setPeriodValues] = useState([]);
  const [subjectValues, setSubjectValues] = useState([]);
  const [allSubjectValues, setAllSubjectValues] = useState([]);
  const [sectionValues, setSectionValues] = useState([]);
  const [allSectionValues, setAllSectionValues] = useState([]);
  const { createTeacher, isLoading, error } = useCreateTeacher();
  

  const STEPS = [
    {
      title: "Create Profile",
      icon: (color) => <FiEdit color={color ?? "#C467FF"} size={20} />,
      onClick: () => { },
    },
    {
      title: "Add class and subject",
      icon: (color) => (
        <HiOutlinePlusCircle color={color ?? "#C467FF"} size={20} />
      ),
      onClick: () => { },
    },
    {
      title: "Review",
      icon: (color) => <AiOutlineEye color={color ?? "#C467FF"} size={20} />,
      onClick: () => { },
    },
  ];

  const RenderSteps = () => {
    return STEPS.map((item, i) => {
      return (
        <Step>
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? "accomplished" : ""}`}
            >
              {index + 1}
            </div>
          )}
        </Step>
      );
    });
  };

  const goBack = () => {
    navigate("../")
  }
  const ActionButton = () => {
    return (
      <div>
        <Button
          onClick={goBack}
          theme={"secondary cancel"}
          style={{ marginTop: "15px" }}
        >
          Exit
        </Button>
      </div>
    );
  };

  const handleTableEntriesNext = (e) => {
    e.preventDefault();
    let flag = false;
    let i = 0;
    while (i < noOfRows) {
      if (subjectValues[i] && classValues[i] && sectionValues[i]) {

      }
      else {
        flag = true;
        break;
      }
      i++;
    }
    if (flag) {
      toast?.current?.show({ severity: 'warn', summary: 'Invalid', detail: 'Please select all the details!' });
      return false;
    }
    setPercent(100);
    setCompletedSteps(1);
    handleNextStep();
  }


  const handleCreateProfileSubmit = (e) => {
    e.preventDefault();
    const isEmpty = checkEmpty(input);
    if (!isEmpty) {
      if (input.new_password !== input.confirm_password) {
        toast?.current?.show({ severity: 'warn', summary: 'Invalid', detail: 'Password does not match! please re-enter' });
        setInput(prevState => {
          return {
            ...prevState,
            new_password: '',
            confirm_password: '',
          }
        })
        return false;
      }
      setPercent(50)
      setCompletedSteps(0);
      handleNextStep();
    }
    else {
      toast?.current?.show({ severity: 'warn', summary: 'Invalid', detail: 'Please select all the details!' });
    }
  }

  const handlePrevStep = () => {
    setCurStep((index) => (index > 0 ? index - 1 : index));
  };

  const handleNextStep = () => {
    setCurStep((index) =>
      index < STEPS.length ? index + 1 : index
    );
  };

  // const TableSelection = ({rowId, options, value}) => {
  //     return <TableSelect 
  //     options={options}
  //     theme={'transparent'}
  //     value={value[rowId]}
  //     setValue={(data) => handleSubjectSelection(rowId, data)}
  //     />
  //   }

  const queryClient = useQueryClient();
  const handleFinalSubmit = () => {
    if(isSuperAdmin && school_id === "null")
    {
      toast?.current?.show({ severity: 'error', summary: 'Invalid', detail: 'Please select school first!' });
      return 
    }

    createTeacher({
      inputs: { ...input, school_id: school_id },
      classDetails: classValues,
      sectionDetails: sectionValues,
      periods:periodValues,
      subjectValues:subjectValues
    }).then((data) => {
      queryClient.invalidateQueries({ queryKey: ['getTeachers'] })
      goBack();
    }).catch(err => console.log('create teacher err', err));
  }

  const RenderActionButtons = ({ onNext }) => {
    return (
      <div style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
      <div style={{ width: '40%', margin: '10px', position: 'absolute', bottom: 10, right: 10 }}>
        <div style={{display:'flex', flexDirection:'row'}}>
        {(curStep > 0 && curStep < STEPS.length) ?
          <Button type="submit" form="profile-inputs" theme={'secondary'} style={{marginRight:10}} onClick={handlePrevStep}>
            Previous
          </Button>: null}
          <Button type="submit" form="profile-inputs" theme={'primary'} onClick={onNext}>
            {(curStep == (STEPS.length - 1)) ? 'Submit' : 'Next'}
          </Button>
        </div>
        </div>
      </div>
    )
  }

  const CreateTableInput = () => {
    if (curStep !== 1) {
      return null;
    }
    return (
      <div className="table-input-container">
        <Table actionButton={<RowButtons />} columnHeader={columnHeader} noOfRows={noOfRows} renderRow={(row) => <RowSelectComponent setClassValues={setClassValues} setSubjectValues={setSubjectValues} classValues={classValues} subjectValues={subjectValues} setAllClassValues={setAllClassValues} setAllSubjectValues={setAllSubjectValues} allClassValues={allClassValues} allSubjectValues={allSubjectValues} sectionValues={sectionValues} setSectionValues={setSectionValues} allSectionValues={allSectionValues} setAllSectionValues={setAllSectionValues} rowId={row}  periodValues={periodValues} setPeriodValues={setPeriodValues} />} />
        <RenderActionButtons onNext={handleTableEntriesNext}/>
      </div>
    )
  }
  const CreateProfileInputs = useMemo(() => {
    if (curStep !== 0) {
      return null;
    }
    return (
      <>
        <div className="input-container">
          {CREATE_TEACHER_FORM.map(
            (
              { label, type, options, name, placeholder, uploadButton, width },
              index
            ) => {
              return (
                <div className={`form-field ${name}`} key={index}>
                  <h4 style={{ flexDirection: 'row', display: 'inline-flex' }}>{label}<p style={{ color: 'red' }}>*</p></h4>
                  <div className={`${uploadButton && "upload-present"}`}>
                    {type !== "dragNdrop" && (
                      <FormInput
                        type={type}
                        name={name}
                        width={width && width}
                        {...(type == 'file' && {
                          onChange: async (e) => {
                            getBase64(e.target.files[0]).then((value) => {
                              setInput(prevState => {
                                return {
                                  ...prevState,
                                  [name]: value,
                                };
                              });
                            })
                          }
                        })}
                        {...(type == 'tel' && {
                          maxLength: 10
                        })}
                        options={type === "select" && options}
                        {...(type !== 'file' && { value: input })}
                        {...(type == 'file' && { accept: "image/png, image/gif, image/jpeg"})}
                        setValue={setInput}
                        required={true}
                        placeholder={placeholder && placeholder}
                      />
                    )}
                    {type === "dragNdrop" && (
                      <div className="dragNdrop">{placeholder}</div>
                    )}
                    {index === CREATE_TEACHER_FORM.length-1 &&
                    <div style={{marginBottom:"50px"}}/>}
                    {uploadButton && (
                      <Button theme={"blue-outline-button"} icon={<FiUploadCloud color={'green'} />}>
                        {uploadButton}
                      </Button>
                    )}
                  </div>
                </div>
              );
            }
          )}
        </div>
        <RenderActionButtons onNext={handleCreateProfileSubmit} />
      </>
    )
  }, [input, curStep])


  const RenderNextStep = useCallback(() => {

    switch (curStep) {
      case 0:
        return CreateProfileInputs
      case 1:
        return <CreateTableInput />
      case 2:
        return <ReviewProfile />
      default:
        return CreateProfileInputs
    }
  }, [input, subjectValues, classValues])

  const RenderProfileDataByType = ({ row, type }) => {
    switch (type) {
      case 'subject':
        const subjectName = allSubjectValues[row]?.value == subjectValues[row] ? allSubjectValues[row]?.label : ''
        return <p>{subjectName}</p>
      case 'class':
        const className = allClassValues[row]?.value == classValues[row] ? allClassValues[row]?.label : ''
        return <p>{className}</p>
      case 'section':
        const sectionName = allSectionValues[row]?.value == sectionValues[row] ? allSectionValues[row]?.label : ''
        return <p>{sectionName}</p>
      case 'period':
          const periodName = periodValues[row]?.value == periodValues[row].value ? periodValues[row]?.label : ''
          return <p>{periodName}</p>
      default:
        return '';
    }
  }
  const RenderProfileRowItem = ({ row }) => {
    return columnHeader.map((column, i) => {
      const path = column.path;
      return (
        <td key={i}>
          {<RenderProfileDataByType row={row} type={path} />}
        </td>
      )
    }
    )
  }


  const ReviewProfile = () => {
    if (curStep !== 2) {
      return null;
    }
    return (
      <DataHeader className={'teacher-profile-container'} imageSrc={input.photo} name={input.name} email_id={input.email_id} password={input.new_password}>
        <>
        <Table columnHeader={columnHeader} noOfRows={noOfRows} renderRow={(row) => <RenderProfileRowItem row={row} />} />
        <RenderActionButtons onNext={handleFinalSubmit} />
      </>
      </DataHeader>
    )
  }

  const addRow = () => {
    setNoOfRows(row => row + 1)
  }
  const removeRow = () => {
    setNoOfRows((index) => (index > 0 ? index - 1 : index));
  }
  const RowButtons = () => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        {noOfRows > 1 && <div style={{ width: '15%', margin: '10px' }}>
          <Button theme={"secondary cancel"} onClick={removeRow}>
            Delete Row
          </Button>
        </div>}
        <div style={{ width: '15%', margin: '10px' }}>
          <Button theme={'primary-light'} onClick={addRow} icon={<FiPlus style={{ height: '15px', width: '15px' }} />}>
            Add Row
          </Button>
        </div>
      </div>
    )
  }

  return (
    <LayoutContainer
      label={"Create new teacher profile"}
      RightComponent={<ActionButton />}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: 'center',
          marginTop: "2rem",
          flexDirection: 'column'
        }}
      >
        <ProgressBar
          percent={percent}
          height={5}
          width={"80%"}
          filledBackground="#4ED442"
          unfilledBackground="#F3DFFF"
        >
          {STEPS.map((item, i) => {
            return (
              <Step key={i}>
                {({ accomplished, index }) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: completedSteps >= index ? "#4ED442" : accomplished ? "#C467FF" : "#F3DFFF",
                        borderRadius: "50px",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                        width: "60px",
                        height: "60px",
                        display: "flex",
                      }}
                    >
                      {completedSteps >= index ? <AiOutlineCheck color={'#ffffff'} /> : item.icon(accomplished ? "#ffffff" : null)}
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontStyle: "Roboto",
                        fontWeight: "500",
                        fontSize: "14px",
                        paddingTop: "10px",
                        color: completedSteps >= index ? "#4ED442" : accomplished ? "#C467FF" : "#8C8C8C",
                      }}
                    >
                      <p>{item.title}</p>
                    </div>
                  </div>
                )}
              </Step>
            );
          })}
        </ProgressBar>
      </div>
      {CreateProfileInputs}
      <CreateTableInput />
      <ReviewProfile />
      {/* <RenderNextStep /> */}
    </LayoutContainer>
  );
}

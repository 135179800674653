import React from 'react'
import './Table.scss';

export default function Table({ actionButton, columnHeader, selectOption, noOfRows, renderRow }) {
    
    const RenderRowItem = ({ row }) => {
        return columnHeader.map((column, i) => {
            const path = column.path;
            return (
                <td key={i}>
                    {selectOption(row, path)}
                </td>
            )
        }
        )
    }
    return (
        <div className="table-wrapper">
            <div className="table-top"></div>
            <table className="tbl">
                <thead className="tbl-header">
                    <tr>
                        {columnHeader.map(({ path, title }) => (
                            <th key={path}>
                                {title}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="tbl-body">
                    {renderRow && Array.from(Array(noOfRows).keys()).map((_, index) => {
                        return (<tr key={index}>
                            {renderRow(index)}
                        </tr>)
                    })}
                </tbody>
            </table>
            {actionButton}
        </div>
    )
}

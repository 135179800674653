import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';
import { useSharedToast } from '../../App';


const verifyPasswordDetails = async ({ inputs, school_id }) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/onboard/verifyPassword`,
        method: `POST`,
        data: {
            password:inputs?.password,
        },
    })
    return queryResponse;
}


export const useVerifyPassword = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: verifyPassword, isLoading, error } = useMutation(verifyPasswordDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status === 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'password verified successfully!' });
            }
            else {
             toast?.current?.show({ severity: 'error', summary: 'Error', detail: `${data?.response?.data?.message}` });
            }
        },
        onError: (error) => {
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Authentication failed. please enter correct password.' });
        },
    });

    return {
        verifyPassword: verifyPassword,
        isLoading,
        error,
    };
};

import React from 'react'
import './User.scss';
import DefaultAvatar from '../../assets/images/default_avatar.png'

export default function User ({data, index,selected, customName, image, role, rightComponent, onClick}) {
  return (
    <div className={`user-item-container ${selected ? 'selected' : ''} ${index ?? ''}`} key={index} onClick={onClick}>
        <div className='user-avatar-container'>
            <img src={image ?? data.avtar ?? DefaultAvatar} className='user-item-avatar' />
        </div>
        <div className='user-text-container'>
        {customName ?<p className='user-item-name'>{customName}</p>:
         <p className='user-item-name'>{data.first_name} {data?.last_name}</p>}
        <p className='user-item-role'>{role}</p>
        </div>
    {rightComponent && rightComponent}
    </div>
  )
}

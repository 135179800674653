import {ReactComponent as TeacherIcon}  from "../assets/icons/teacher.svg";
import {ReactComponent as StudentIcon} from "../assets/icons/student.svg";
import {ReactComponent as BusesIcon} from "../assets/icons/buses.svg";
import {ReactComponent as CircularIcon} from "../assets/icons/circular.svg";
import {ReactComponent as ClassesIcon} from "../assets/icons/classes.svg";
import {ReactComponent as FeedIcon} from "../assets/icons/feed.svg";
import {ReactComponent as LectureIcon} from "../assets/icons/lecture.svg";
import {HiOutlineBuildingLibrary} from 'react-icons/hi2'
import {GiPlainCircle} from 'react-icons/gi';
export const SUPER_ADMIN_MODULES = [
    {
        label: 'School',
        name:'school',
        icon: <HiOutlineBuildingLibrary />,
        route: '/school',
        permission: ["super_admin"],
    },
    {
        label: 'Lecture Videos',
        name:'lecture',
        icon: <StudentIcon />,
        route: '/lecture',
        permission: ['super_admin']
    },
]

const MODULES = [
    {
        label: 'Teacher',
        name:'teacher',
        icon: <TeacherIcon />,
        route: '/teacher'
    },
    {
        label: 'Student',
        name:'student',
        icon: <StudentIcon />,
        route: '/student'
    },
    {
        label: 'Classes',
        name:'class',
        icon: <ClassesIcon />,
        route: '/class'
    },
    {
        label: 'Buses',
        name:'buses',
        icon: <BusesIcon />,
        route: '/bus',
        sub_menu: true,
        sub_menu_items : [
            {
        label: 'Bus Operator',
        name:'operator',
        icon: <GiPlainCircle size={10}/>,
        route: '/operator',
            },
            {
        label: 'School Bus',
        name:'bus',
        icon: <GiPlainCircle size={10}/>,
        route: '/bus',
            }
        ]

    },
    {
        label: 'Lecture Videos',
        name:'lecture',
        icon: <StudentIcon />,
        route: '/lecture',
    },
    // {
    //     label: 'Feed',
    //     name:'feed',
    //     icon: <FeedIcon />,
    //     route: '/feed'
    // },
    {
        label: 'Circular',
        name:'circular',
        icon: <CircularIcon />,
        route: '/circular'
    },
]

export default MODULES;
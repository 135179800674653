import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, DataHeader, LabelItem, LayoutContainer } from '../../../../components';
import { SHOW_CIRCULAR_FORM, SHOW_STUDENT_FORM } from './constants';
import { useGetSection } from '../../../../queryHooks/Section/useGetSection/useGetSection';
import { useGetClass } from '../../../../queryHooks/Classes/useGetClass/useGetClass';
import { useAuthStore } from '../../../../stores';
import { toTitleCase } from '../../../../utils/toTitleCase';


import './CircularProfile.scss';
import moment from 'moment';

export default function CircularProfile() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const user = useAuthStore(state => state.user);
    const school_id = useAuthStore(state => state.school_id)
    const fullName = `${state?.first_name} ${state?.last_name}`
    const email_id = state?.email_id || '-'
    const password = '-';


    const goBack = () => {
        navigate("../");
    }

    const ActionButton = () => {
        return (
            <div>
                <Button
                    onClick={goBack}
                    theme={"secondary cancel"}
                    style={{ marginTop: "15px" }}
                >
                    Exit
                </Button>
            </div>
        );
    };

    const RenderCircularDetails = () => {
        return SHOW_CIRCULAR_FORM.map((item, index) => {
            if (state[item.name]) {
                if (item.name == 'event_date') {
                    return <LabelItem label={item.label} value={`${moment(state[item.name]).format('DD MMMM, YYYY')}`} />
                }
                if (item.name == 'event_time') {
                    return <LabelItem label={item.label} value={`${moment(state[item.name], 'hh:mm:ss').format('HH:mm A')}`} />
                }
                return <LabelItem label={item.label} value={`${toTitleCase(state[item.name])}`} typeFile={item.name == 'document'}/>
            }
        })
    }

    const RenderActionButtons = ({ onSubmit }) => {
        return (
            <div style={{ display: 'flex', flex: 0.5, alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
                <div style={{ width: '20%', margin: '10px' }}>
                    <Button type="submit" form="student-inputs" theme={'primary'} onClick={onSubmit}>
                        {'Edit'}
                    </Button>
                </div>
            </div>
        )
    }


    const handleEdit = () => {
        navigate('../new', { state: { ...state, mode: 'edit' } });
    }

    return (
        <LayoutContainer label={`View Circular`} RightComponent={<ActionButton />}>
            <div className='student-label-container'>
                <RenderCircularDetails />
            </div>
            <div style={{ flex: 1, height: '15vh' }} />
            <RenderActionButtons onSubmit={handleEdit} />
        </LayoutContainer>
    )
}

import { useQuery, useQueryClient } from "react-query";
import request from "../../../utils/request";
import { BASE_URL } from "../../../Config";


const getBoard = async ({ queryKey }) => {
    const [_, obj] = queryKey;
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/school/getBoard`,
        //&school_id=${obj.school_id}
        method: 'GET',
    })
    return queryResponse;
}

export const useGetBoard = ({ school_id, enabled }) => {
    const queryClient = useQueryClient();
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getBoard', school_id],
        queryFn: getBoard,
        staleTime: 10 * (60 * 1000), // 5 mins
        cacheTime: 15 * (60 * 1000), // 15 mins 
        enabled: school_id ? true : false,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;
    const formattedData = responseData?.map((item, index) => {
        let obj = {
            label: item.board_name,
            value: item.id
        }
        return { ...item, ...obj }
    })
    return { data: formattedData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
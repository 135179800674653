export const themeStyles = (width, height, menuOnTop) => {
	const normalStyles = {
		control: current => ({
			...current,
			// width,
			height:'44px',
			marginTop:'1px',
						// height,
			borderColor: "#8C8C8C",
			backgroundColor: "#ffffff",
			cursor: "pointer",
			fontWeight: "500",
			boxShadow: "none",
			minHeight: "initial",
		}),
		dropdownIndicator: (current, { selectProps: { menuIsOpen } }) => ({
			...current,
			color: "#170F49",
			transition: "0.5s",
			...(menuIsOpen && {
				transform: "rotate(180deg)",
			}),
		}),
		indicatorSeparator: current => ({
			...current,
			display: "none",
		}),
		menu: current => ({
			...current,
			// width,
			zIndex: "2",
			...(menuOnTop && { bottom: "40px", top: "unset" }),
		}),
		menuList: current => ({
			...current,
			padding: 0,
			maxHeight: "135px",
		}),
		option: (current, { isSelected, isFocused }) => ({
			...current,
			...(isSelected && { backgroundColor: "#C467FF" }),
			...(isFocused && {
				backgroundColor: isSelected ? "#C467FF": "#edf2f7",
				color: isSelected ? "#ffffff" : "#000000",
			}),
			cursor: "pointer",
		}),
	};

	const transparentStyles = {
		control: current => ({
			...current,
			width,
			border: "none",
			backgroundColor: "none",
			cursor: "pointer",
			fontWeight: "500",
			boxShadow: "none",
		}),
		dropdownIndicator: (current, { selectProps: { menuIsOpen } }) => ({
			...current,
			color: "#C467FF",
			transition: "0.5s",
			...(menuIsOpen && {
				transform: "rotate(180deg)",
			}),
		}),
		indicatorSeparator: current => ({
			...current,
			display: "none",
		}),
		menu: current => ({
			...current,
			cursor: "pointer",
			top: "30px",
			zIndex: "2",
			...(menuOnTop && { bottom: "30px", top: "unset" }),
		}),
		menuList: current => ({
			...current,
			padding: 0,
			maxHeight: "135px",
		}),
		option: (current, { isSelected, isFocused }) => ({
			...current,
			...(isSelected && { backgroundColor: "#C467FF" }),
			...(isFocused && {
				backgroundColor: isSelected ? "#C467FF": "#edf2f7",
				color: isSelected ? "#ffffff" : "#000000",
			}),
			cursor: "pointer",
		}),
		singleValue: current => ({
			...current,
			right: 0,
			textOverflow: "visible",
		}),
	};

	return [normalStyles, transparentStyles];
};

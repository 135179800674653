import React from 'react'


import './LabelItem.scss';

export default function LabelItem({ label, value, typeFile }) {
    return (
        <div className={'label-item-container'} key={value}>
            <div className='label-item-label'>
                <p className='item-label'>{label}</p>
            </div>
            <div className='label-item-value'>
                <p className='item-value'>{!typeFile ? value : <a href={value}>Click to View Document</a>}</p>
            </div>
        </div>
    )
}

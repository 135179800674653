import React, { useState } from "react";
import { Button, LayoutContainer, Pagination, Topbar } from "../../components";
import { GoPlus } from "react-icons/go";
import { Outlet, useNavigate, useOutlet } from "react-router-dom";


import "./BusOperator.scss";
import User from "../../components/User/User";
import { useGetBusOperator } from "../../queryHooks/useGetBusOperator/useGetBusOperator";
import { useAuthStore } from "../../stores";
import { Skeleton } from 'primereact/skeleton';


export default function BusOperator() {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const user = useAuthStore(state => state.user);
  const school_id = useAuthStore(state => state.school_id)
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(12);

   //Get Current rows
   const indexOfLastRow = currentPage * rowsPerPage;
   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

   const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const { data: busOperators, error: error, isLoading: busOperatorLoading, isFetching: busOperatorFetching, refetch: busOperatorRefetch } = useGetBusOperator({ school_id: school_id })

  const currentRows = busOperators?.slice(indexOfFirstRow, indexOfLastRow);

  const loading = busOperatorLoading || busOperatorFetching


  const SkeletonLoader = () => {
    return (
      <div className='teacher-wrapper'>
      {Array.from(Array(10).keys()).map((_, index) => {
        return <div className="user-item-container" style={{backgroundColor:'transparent'}}>
        <Skeleton height='125px' key={index} />
        </div>
      })}
    </div>
    )
  }

  const handleItemClick = (data) => {
    navigate(`./${data.bus_operater_id}`, { state: { ...data, bus_name:data.bus_master.bus_number } });
  }

  const NoContent = () => {
    return (
      <div className="no-content">
        <p>You haven't selected any of the profile yet.</p>
      </div>
    );
  };

  const ActionButton = () => {
    return (
      <div>
        <Button
          onClick={() => {
            navigate('./new')
          }}
          icon={<GoPlus />}
          theme={"primary"}
          style={{ marginTop: "15px" }}
        >
          Create New Operator Profile
        </Button>
      </div>
    );
  };

  if(outlet)
  {
    return (
      <Outlet />
    )
  }

  return (
    <>
      <LayoutContainer label={"Bus Operator"} RightComponent={<ActionButton />}>
      <div className="teacher-container">
          {loading ?
          <SkeletonLoader />
            : busOperators && busOperators.length !== 0 ?
              <div className='teacher-wrapper'>
                {currentRows?.map((item, index) => {
                  return (
                    <User data={item} customName={item.full_name} role={'Bus Operator'} index={index} onClick={() => handleItemClick(item)} />
                  )
                })}
              </div> : <NoContent />}
        </div>
        {busOperators?.length !== 0 && <div className="footer-wrapper">
            <h4 className="show-rows">
              Showing {indexOfFirstRow === 0 ? '1' : indexOfFirstRow} to{' '}
              {indexOfLastRow} of {busOperators?.length} Result
            </h4>
            </div>}
            {rowsPerPage <= busOperators?.length && (
            <Pagination
              rowsPerPage={rowsPerPage}
              totalRows={busOperators?.length}
              paginate={paginate}
              currentPage={currentPage}
              />
            )}
      </LayoutContainer>
    </>
  );
}

import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState();
  const [isAdmin, setAdmin] = useState(false);

  useEffect(() => {
    //   setCurrentUser({ name: name, email: email, userId: user_id });
    //   setAdmin(true);
    // setLoading(false);
  }, []);

  const signIn = async (email, password) => {
    //SIGN IN
    if (!email || !password) {
      alert('Please enter your email and password');
      return;
    } else {
          setAdmin(true);
          setCurrentUser({ name: 'Test', email: email, userId: 'xyz1' });
          setAuthenticated(true);
      }
  };

  const signOut = () => {
    //SIGN OUT
    try {
      setCurrentUser({});
      setAuthenticated(false);
    } catch (err) {
      setError(err);
      console.log('error', err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
        authenticated,
        error,
        isAdmin,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

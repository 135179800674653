import React, { useState } from 'react'
import './VideoPagination.scss';

const VideoPagination = ({ total, pageSize, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pages = Math.ceil(total / pageSize);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);
  };

  return (
    <div className="pagination">
      <button
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        {'<'}
      </button>
      {Array.from(Array(pages)).map((page, index) => {
        return (
        <button
          key={index}
          className={`video-pagination-button ${currentPage === index + 1 ? 'active': ''}`}
          active={currentPage === index + 1}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </button>
      )}
      )}
      <button
        disabled={currentPage === pages}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        {'>'}
      </button>
    </div>
  )
}

export default VideoPagination

import { useQuery } from "react-query";
import request from "../../utils/request";
import { BASE_URL } from "../../Config";


const getVideos = async ({ queryKey }) => {
    const [_, data] = queryKey;   // eslint-disable-line no-unused-vars
    const class_id = data?.class_id
    const subject_id = data?.subject_id
    const chapter_id = data?.chapter_id
    const topic_id = data?.topic_id
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/video/getvideo?class_id=${class_id}&subject_id=${subject_id}&chapter_id=${chapter_id}&topic_id=${topic_id}`,
        method: 'GET',
    })
    return queryResponse;
}

export const useGetVideos = ({ chapter_id, class_id, subject_id, topic_id, enabled }) => {
    const _enabled = chapter_id || class_id || subject_id || topic_id || enabled
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getVideos', {topic_id, class_id, subject_id, chapter_id}],
        queryFn: getVideos,
        enabled: !!_enabled,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;
    const formattedData = responseData;
    return { data: formattedData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
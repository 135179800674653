import React from "react";
import Select from "../../Select/Select";
import InputNumber from "./InputNumber/InputNumber";
import InputTime from "./InputTime/InputTime";

const InputSingle = ({ type, value, setValue, name, width, className, small, ...rest }) => {
	const onChange = e => {
		setValue(e.target.value);
	};

	switch (type) {
		case "select":
			return <Select name={name} width={width} value={value} setValue={setValue} {...rest} />;
		case "textarea":
			return (
				<textarea
					style={{ width }}
					value={value}
					onChange={onChange}
					name={name}
					className={`input ${className}`}
					{...rest}
				/>
			);
		case "number":
			return (
				<InputNumber
					width={width}
					value={value}
					setValue={setValue}
					onChange={onChange}
					name={name}
					type={type}
					small={small}
					className={className}
					{...rest}
				/>
			);
		case "time":
			return <InputTime input={value} setInput={setValue} className={className} {...rest} />;
		default:
			return (
				<input
					style={{ width }}
					value={value}
					onChange={onChange}
					name={name}
					type={type}
					className={`input ${className} ${small && "small"}`}
					{...rest}
				/>
			);
	}
};



export default InputSingle;

import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';
import { useAssignToTeacher } from './useAssignToTeacher';
import { useSharedToast } from '../../App';
import { convertToBase64Readable } from '../../utils/base64Encode';

const splitName = (name = '') => {
    const [firstName, ...lastName] = name.split(' ').filter(Boolean);
    return {
        firstName: firstName,
        lastName: lastName.join(' '),
    };
};

const createTeacherAndAssignClass = async ({ inputs, classDetails, sectionDetails ,periods,subjectValues}) => {
    return createTeacher({ inputs: inputs }).then((data) => {
        //create teacher
        const teacher_id = data?.data?.data?.teacher_id
        return { teacher_id, classDetails, sectionDetails, status: data.status,periods,subjectValues };
    }).catch((err) => {
        throw err;
    })
};

const createTeacher = async ({ inputs }) => {
    const fullName = splitName(inputs.name)
    const dob = `${inputs.DD}/${inputs.MM}/${inputs.YYYY}`
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/teacher/create`,
        method: `POST`,
        data: {
            school_id: inputs.school_id.toString(),
            email_id: inputs.email_id,
            password: inputs.new_password,
            mobile: inputs.contact_no,
            first_name: fullName.firstName,
            middle_name: "",
            last_name: fullName.lastName,
            dob: dob,
            address: inputs.address,
            gender: inputs.gender,
            idsNo: inputs.id_no,
            avtar: convertToBase64Readable(inputs.photo)
        },
    })
    // return false;
    return queryResponse;
}

export const useCreateTeacher = () => {
    const { assignToTeacher } = useAssignToTeacher();
    const { toast } = useSharedToast();
    const { mutateAsync: createTeacher, isLoading, error } = useMutation(createTeacherAndAssignClass, {
        onSuccess: (data, error) => {
            const teacher_id = data.teacher_id;
            const classDetails = data.classDetails;
            const sectionDetails = data.sectionDetails;
            const periods=data.periods;
            const subjects=data.subjectValues

            if(data.status == 200)
            {
                classDetails.forEach((item, index) => {
                    assignToTeacher({ teacher_id: teacher_id, class_id: item, section_id: sectionDetails[index],period:periods[index].value,subject_id:subjects[index] }, {
                        onSuccess: (data, error, variables, context) => {
                            toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Teacher profile created successfully!' });
                        },
                        onError: (data) => {
                            console.log(data, 'assign teacher error');
                            throw data;
                        }
                    })
                })
            }
            else {
                throw data;
            }
            // Success actions

        },
        onError: (error) => {
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error creating the student profile.' });
            // Error actions
        },
    });

    return {
        createTeacher,
        isLoading,
        error,
    };
};


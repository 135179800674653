import React from "react";
import ButtonThemes from "./Themes.js";
import { ProgressSpinner } from 'primereact/progressspinner';
import "./Button.scss";
import Loader from "../Loader/Loader.js";

const Button = ({ children, type, className, icon, loading, theme, ...rest }) => {
  return (
    <button
      type={type}
      className={`themed-button ${theme} ${className}`}
      {...rest}
    >
      {loading && <div className={`button-icon ${theme}`}><Loader /></div>}
      {icon && <div className={`button-icon ${theme}`}>{icon}</div>}
      {children}
    </button>
  );
};

export default Button;
export const THEMES = ButtonThemes;

export const INPUTS = [
    {
        label: "Confirm with password-key",
        type: "password",
        name: "password",
        placeholder: "Enter Password",
    },
]

export const DEFAULT_INPUT_VALUE = {
    password: null,
}
import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';
import { useSharedToast } from '../../App';
import { convertToBase64Readable } from '../../utils/base64Encode';
import { useAssignToBus } from './useAssignToBus';

const getAllStudents = async ({ bus_id, students }) => {
  return {bus_id: bus_id, students: students, status: 200};
};

export const useAssignStudentToBus = () => {
    const { assignToBus } = useAssignToBus();
    const { toast } = useSharedToast();
    const { mutateAsync: assignStudentsToBus, isLoading, error } = useMutation(getAllStudents, {
        onSuccess: (data, error) => {
            const bus_id = data.bus_id;
            const students = data.students;
            if(data.status == 200)
            {
                students.forEach((student_id, index) => {
                    assignToBus({ bus_id: bus_id, student_id: student_id }, {
                        onSuccess: (data, error, variables, context) => {
                            toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Student(s) assigned to bus successfully!' });
                        },
                        onError: (data) => {
                            throw data;
                        }
                    })
                })
            }
            else {
                throw data;
            }
            // Success actions

        },
        onError: (error) => {
            console.log(error)
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error assigning the student profile.' });
            // Error actions
        },
    });

    return {
        assignStudentsToBus,
        isLoading,
        error,
    };
};


import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, DataHeader, LabelItem, LayoutContainer } from '../../../../components';
import { SHOW_STUDENT_FORM } from './constants';
import { useGetSection } from '../../../../queryHooks/Section/useGetSection/useGetSection';
import { useAuthStore } from '../../../../stores';
import { toTitleCase } from '../../../../utils/toTitleCase';


import './BusProfile.scss';
import { checkAllNull, checkEmpty } from '../../../../utils/checkEmpty';
import Table from '../../../Teacher/components/Table/Table';
import TableSelect from '../../../../components/TableSelect/TableSelect';
import User from '../../../../components/User/User';
import { useGetStudents } from '../../../../queryHooks/useGetStudents/useGetStudents';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { useGetStudentsByBus } from '../../../../queryHooks/useGetStudentsByBus/useGetStudentsByBus';
import ListContainer from './components/ListContainer/ListContainer';
import { useAssignStudentToBus } from '../../../../queryHooks/useAssignStudentToBus/useAssignStudentToBus';
import { useQueryClient } from 'react-query';
import { useGetClass } from '../../../../queryHooks/Classes/useGetClass/useGetClassSuperAdmin';

export default function BusProfile() {
    const { state } = useLocation();
    const busOperator = state?.bus_orperater_master;
    const navigate = useNavigate();
    const user = useAuthStore(state => state.user);
    const school_id = useAuthStore(state => state.school_id)
    const fullName = `${state.name}`
    const email_id = state?.email_id || '-'
    const password = '-';
    const [classValue, setClassValue] = useState([])
    const [sectionValue, setSectionValue] = useState([])
    const [selectedStudents, setSelectedStudents] = useState([]);
    const enabled = true;
    const queryClient = useQueryClient()

    const { data: classOptions, error: getClassError, isLoading: classLoading } = 
    useGetClass(school_id,"",enabled,true )
    // useGetClass({ school_id: school_id, enabled: school_id && true })

    const { data: sectionOptions, error: sectionError, isLoading: sectionLoading, refetch: sectionRefetch } = useGetSection({ class_id: classValue, enabled: school_id && true })

    const { data: studentData, error: studentError, isInitialLoading: studentLoading, refetch: studentRefetch } = useGetStudents({ class_id: classValue, section_id: sectionValue, school_id: school_id, enabled: enabled })

    const { data: assignedStudents, error: assignedStudentError, isInitialLoading: assignedStudentLoading, refetch: assignedStudentRefresh } = useGetStudentsByBus({ enabled: true, bus_id: state?.bus_id })

    const { assignStudentsToBus } = useAssignStudentToBus();
    const goBack = () => {
        navigate("../");
    }

    const ActionButton = () => {
        return (
            <div>
                <Button
                    onClick={goBack}
                    theme={"secondary cancel"}
                    style={{ marginTop: "15px" }}
                >
                    Exit
                </Button>
            </div>
        );
    };

    useEffect(() => {

    }, [])


    const onSubmit = (e) => {
        e.preventDefault();
        if (selectedStudents.length !== 0) {
            assignStudentsToBus({ bus_id: state?.bus_id, students: selectedStudents }).then(() => {
                queryClient.invalidateQueries({ queryKey: ['getStudentsByBus'] })
                setSelectedStudents([]);
                setTimeout(() => {
                    assignedStudentRefresh();
                }, 500)
            }).catch(err => {
                console.log(err, 'err in assign to bus');
            })
        }
    }

    const RenderActionButtons = ({ onSubmit }) => {
        return (
            <div style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
                <div style={{ width: '20%', margin: '10px', position: 'absolute', bottom: 10, right: 10 }}>
                    <Button type="submit" form="student-inputs" theme={'primary'} onClick={onSubmit}>
                        {'Save'}
                    </Button>
                </div>
            </div>
        )
    }


    const handleEdit = () => {
        navigate('../new', { state: { ...state, mode: 'edit' } });
    }

    const RenderColumnByType = ({ type, row }) => {
        switch (type) {
            case 'class':
                return <TableSelect
                    options={classOptions}
                    theme={'transparent'}
                    value={classValue}
                    setValue={setClassValue}
                />
            case 'section':
                return <TableSelect
                    options={sectionOptions}
                    theme={'transparent'}
                    value={sectionValue}
                    setValue={setSectionValue} />
        }
    }

    const RenderStudentSelection = ({ row }) => {
        return columnHeader.map((column, i) => {
            const path = column.path;
            return (
                <td key={i}>
                    {<RenderColumnByType row={row} type={path} />}
                </td>
            )
        }
        )
    }

    const columnHeader = [
        { path: 'class', title: 'Class' },
        // { path: 'period', title: 'Period' },
        { path: 'section', title: 'Section' },
    ]

    const handleItemClick = (id, selected, alreadyAdded) => {
        if (alreadyAdded) {
            return false;
        }
        if (!selected) {
            setSelectedStudents(prevStudents => [...prevStudents, id])
        }
        else {
            setSelectedStudents(prevStudents => (
                // Filter out the item with the matching index
                prevStudents.filter((value) => value !== id)
            ));
        }
    }

    if (classLoading && sectionLoading) {
        return null;
        // null reu
    }

    const HandleUserIcon = ({ selected, alreadyAdded }) => {
        return (
            <div className={`user-right-icon ${selected ? 'selected' : ''}`}>
                {(!selected && !alreadyAdded) ? <BiPlus size={20} /> : alreadyAdded ? null : <BiTrash size={20} />}
            </div>
        )
    }
    return (
        <LayoutContainer label={`${fullName}`} RightComponent={<ActionButton />}>
            {!checkAllNull(busOperator) ? <DataHeader role={'Bus Operator'} name={busOperator?.full_name} email_id={email_id} username={busOperator?.username} password={password} imageSrc={busOperator?.avtar}>
            </DataHeader> : <div className='no-operator-assigned'>
                <h3>No Operator assigned!</h3>
            </div>}
            <ListContainer label={'Students List'} data={assignedStudents} role={'Student'} />
            <Table columnHeader={columnHeader} noOfRows={1} renderRow={(row) => <RenderStudentSelection row={row} />} />
            <div className='student-container'>
                {studentData?.length !== 0 ? <div className='student-wrapper'>
                    {studentData?.map((item, index) => {
                        const selected = selectedStudents.length !== 0 && selectedStudents.some(id => id == item.student_id);
                        const alreadyAdded = assignedStudents.some(el => el.student_id === item.student_id);
                        return (
                            <User data={item} selected={(selected || alreadyAdded)} index={index} role={'Student'} rightComponent={<HandleUserIcon selected={selected} alreadyAdded={alreadyAdded} />} onClick={() => handleItemClick(item.student_id, selected, alreadyAdded)} />
                        )
                    })}
                </div>
                    :
                    null}
            </div>
            <RenderActionButtons onSubmit={onSubmit} />
        </LayoutContainer>
    )
}

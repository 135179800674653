import { useMutation } from 'react-query';
import request from '../../../utils/request';
import { BASE_URL } from '../../../Config';
import { useSharedToast } from '../../../App';
import { convertToBase64Readable } from '../../../utils/base64Encode';
import { convertTo24Hour } from '../../../utils/convertTo24Hour';


const deleteClassDetails = async ({ class_id }) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/class/deleteClass`,
        method: `DELETE`,
        data: {
            class_id: class_id,
        },
    })
    return queryResponse;
}


export const useDeleteClass = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: deleteClass, isLoading, error } = useMutation(deleteClassDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Class deleted successfully!' });
            }
        },
        onError: (error) => {
            console.log(error, 'error here');
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error deleting the Class.' });
        },
    });

    return {
        deleteClass: deleteClass,
        isLoading,
        error,
    };
};

import React from 'react'
import './StudentItem.scss';

export default function StudentItem ({data, index, onClick}) {
  return (
    <div className='student-item-container' key={index} onClick={onClick}>
        <div className='student-avatar-container'>
            <img src={data.avtar} className='student-item-avatar' />
        </div>
        <div className='student-text-container'>
        <p className='student-item-name'>{data.first_name} {data?.last_name}</p>
        <p className='student-item-role'>Student</p>
        </div>
    </div>
  )
}

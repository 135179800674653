export const INPUTS = [
    {
      label: "Chapter Icon",
      type: "dragNdrop",
      name: "icon",
      placeholder: "Upload chapter icon",
    },
    {
      label: "Chapter Name",
      type: "text",
      name: "name",
      placeholder: "Enter chapter name",
    },
    {
      label: "Chapter Index",
      type: "text",
      name: "index",
      placeholder: "Enter chapter index",
    },
    {
      label: "Total Duration",
      type: "duration",
      name: "duration",
      placeholder: "Enter the duration",
    },
    {
      label: "No of Topics",
      type: "select",
      name: "no_topic",
      menuOnTop:true,
      options: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" },
        { value: 13, label: "13" },
        { value: 14, label: "14" },
        { value: 15, label: "15" },
        { value: 16, label: "16" },
        { value: 17, label: "17" },
        { value: 18, label: "18" },
        { value: 19, label: "19" },
        { value: 20, label: "20" },
        { value: 21, label: "21" },
      ],
    },
    {
      label: "Chapter Lock",
      type: "select",
      name: "lock",
      menuOnTop:true,
      options: [
        { value: true, label: "Lock" },
        { value: false, label: "Unlock" },
      ],
    },
  ];
  
  export const DEFAULT_INPUT_VALUE = {
    icon: null,
    name: null,
    index: null,
    lock: null,
  };
  
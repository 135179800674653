import React, { useCallback, useMemo, useState } from "react";
import { Button, LayoutContainer, Modal, Topbar } from "../../components";
import { GoPlus } from "react-icons/go";
import { Outlet, useNavigate, useOutlet } from "react-router-dom";

import "./Lecture.scss";
import { useAuthStore } from "../../stores";
import { Skeleton } from "primereact/skeleton";
import { useGetClass } from "../../queryHooks/Classes/useGetClass/useGetClass";
import { useGetSubject } from "../../queryHooks/Subject/useGetSubject/useGetSubject";
import User from "../../components/User/User";
import Card from "../../components/Card/Card";
import { convertToOrdinal } from "../../utils/convertToOrdinal";
import ClassModal from "./modals/ClassModal/ClassModal";
import SubjectModal from "./modals/SubjectModal/SubjectModal";
import { useSharedToast } from "../../App";
import { motion } from "framer-motion";
import { useGetBoard } from "../../queryHooks/Board/useGetBoard/useGetBoard";
import BoardItem from "./components/BoardItem/BoardItem";
import BoardModal from "./modals/BoardModal/BoardModal";
import PasswordModal from "../../components/PasswordModal/PasswordModal";
import { useDeleteClass } from "../../queryHooks/Classes/useDeleteClass/useDeleteClass";
import { DELETE_PERMISSION } from "../../Config";
import { useQueryClient } from "react-query";
import { useDeleteBoard } from "../../queryHooks/Board/useDeleteBoard/useDeleteBoard";
import { useDeleteSubject } from "../../queryHooks/Subject/useDeleteSubject/useDeleteSubject";

export default function Lecture() {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const showDelete = DELETE_PERMISSION.includes(user?.admin_type);
  const isSuperAdmin = user?.admin_type === "super_admin" ? true : false;
  const board_id = user?.school_master?.board_id || "";
  const school_id = useAuthStore((state) => state.school_id);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(
    !isSuperAdmin ? { board_id: board_id } : null
  );
  const [deletedItem, setDeletedItem] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subjectModalVisible, setSubjectModalVisible] = useState(false);
  const [classModalVisible, setClassModalVisible] = useState(false);
  const [boardModalVisible, setBoardModalVisible] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [header, setHeader] = useState("");

  const { toast } = useSharedToast();

  const queryClient = useQueryClient();

  const {
    data: boards,
    error: getBoardError,
    isInitialLoading: boardLoading,
  } = useGetBoard({
    school_id: school_id,
    enabled: school_id && isSuperAdmin && true,
  });

  const {
    data: classes,
    error: getClassError,
    isInitialLoading: classLoading,
  } = useGetClass(
    school_id,
    selectedBoard?.board_id,
    school_id && selectedBoard?.board_id ? true : false
  );

  const {
    data: subjects,
    error: subjectError,
    isInitialLoading: subjectLoading,
    refetch: subjectRefetch,
  } = useGetSubject({
    class_id: selectedClass?.class_id,
    school_id: school_id,
    enabled: school_id && true,
  });

  const {
    deleteClass,
    isLoading: deleteClassLoading,
    error: deleteClassError,
  } = useDeleteClass();
  const {
    deleteBoard,
    isLoading: deleteBoardLoading,
    error: deleteBoardError,
  } = useDeleteBoard();
  const {
    deleteSubject,
    isLoading: deleteSubjectLoading,
    error: deleteSubjectError,
  } = useDeleteSubject();

  const SkeletonLoader = ({ label }) => {
    return (
      <div className="teacher-wrapper">
        {Array.from(Array(10).keys()).map((_, index) => {
          return (
            <div
              className="user-item-container"
              key={`${index}_${label}`}
              style={{ backgroundColor: "transparent" }}
            >
              <Skeleton height="125px" key={`${index}_${label}_skeleton`} />
            </div>
          );
        })}
      </div>
    );
  };

  const showError = () => {
    toast?.current?.show({
      severity: "error",
      summary: "Invalid",
      detail: "Please select a class to create subject!",
    });
  };

  const CreateClassModal = useCallback(() => {
    return (
      <ClassModal
        visible={classModalVisible}
        setVisible={setClassModalVisible}
      />
    );
  }, [classModalVisible]);

  const CreateBoardModal = useCallback(() => {
    return (
      <BoardModal
        visible={boardModalVisible}
        setVisible={setBoardModalVisible}
      />
    );
  }, [boardModalVisible]);

  const CreateSubjectModal = useCallback(() => {
    return (
      <SubjectModal
        visible={subjectModalVisible}
        setVisible={setSubjectModalVisible}
      />
    );
  }, [subjectModalVisible]);

  const CreatePasswordVerifyModal = useCallback(() => {
    return (
      <PasswordModal
        visible={passwordModalVisible}
        setVisible={setPasswordModalVisible}
        header={header}
        onSuccess={onDelete}
      />
    );
  }, [passwordModalVisible, header]);

  const handleClassClick = (data) => {
    setSelectedClass({
      class_id: data?.class_id,
      class_name: data?.class_name,
    });
  };
  const handleSubjectClick = (data) => {
    navigate(`./${data?.subject_id}`, { state: { ...data, ...selectedClass } });
  };

  const handleBoardClick = (data) => {
    setSelectedBoard({ board_id: data?.id, board_name: data?.board_name });
  };

  const handleDeleteClick = (header, item) => {
    setHeader(header);
    setDeletedItem((prevState) => {
      return {
        ...prevState,
        [header]: item,
      };
    });
    setPasswordModalVisible(true);
  };

  const onDeleteClass = () => {
    deleteClass({ class_id: deletedItem?.class?.class_id }).then(() => {
      queryClient.invalidateQueries({ queryKey: ["getClass"] });
      setDeletedItem((prevState) => {
        return {
          ...prevState,
          class: null,
        };
      });
    });
  };

  const onDeleteSubject = () => {
    deleteSubject({ subject_id: deletedItem?.subject?.subject_id }).then(() => {
      queryClient.invalidateQueries({ queryKey: ["getSubject"] });
      setDeletedItem((prevState) => {
        return {
          ...prevState,
          subject: null,
        };
      });
    });
  };

  const onDeleteBoard = () => {
    deleteBoard({ board_id: deletedItem?.board?.id }).then(() => {
      queryClient.invalidateQueries({ queryKey: ["getBoard"] });
      setDeletedItem((prevState) => {
        return {
          ...prevState,
          board: null,
        };
      });
    });
  };

  const onDelete = () => {
    switch (header) {
      case "board":
        return onDeleteBoard();
      case "class":
        return onDeleteClass();
      case "subject":
        return onDeleteSubject();
      default:
        return null;
    }
  };

  const NoContent = () => {
    return (
      <div className="no-content-lecture">
        <p>You haven't selected any of the profile yet.</p>
      </div>
    );
  };

  const ClassButton = () => {
    return (
      <div>
        <Button
          onClick={() => {
            setClassModalVisible(true);
          }}
          icon={<GoPlus />}
          theme={"primary"}
          style={{ marginTop: "15px" }}
        >
          New Class
        </Button>
      </div>
    );
  };
  const BoardButton = () => {
    return (
      <div>
        <Button
          onClick={() => {
            setBoardModalVisible(true);
          }}
          icon={<GoPlus />}
          theme={"primary"}
          style={{ marginTop: "15px" }}
        >
          New Board
        </Button>
      </div>
    );
  };
  const SubjectButton = () => {
    return (
      <div>
        <Button
          onClick={() => {
            setSubjectModalVisible(true);
          }}
          icon={<GoPlus />}
          theme={"primary"}
          style={{ marginTop: "15px" }}
        >
          New Subject
        </Button>
      </div>
    );
  };
  if (outlet) {
    return <Outlet />;
  }
  return (
    <>
      <CreateClassModal />
      <CreateSubjectModal />
      <CreateBoardModal />
      <CreatePasswordVerifyModal />
      <LayoutContainer
        label={"Lecture Videos"}
        RightComponent={!classes && <ClassButton />}
      >
        {isSuperAdmin && (
          <>
            <div className="label-container">
              <p className="label-header">Select Board</p>
              <div style={{ width: "20%" }}>
                <BoardButton />
              </div>
            </div>
            <div className="lecture-container">
              {boardLoading ? (
                <SkeletonLoader label={"Board"} />
              ) : boards && boards?.length !== 0 ? (
                <div className="lecture-wrapper">
                  <motion.main
                    className="main__container_lecture"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                  >
                    <div className="lecture-items-wrapper">
                      {boards.map((item, index) => {
                        const selected = item?.id == selectedBoard?.board_id;
                        return (
                          <BoardItem
                            onDeleteButtonClick={() =>
                              handleDeleteClick("board", item)
                            }
                            showDeleteButton={showDelete}
                            key={index}
                            labelName={item?.board_name}
                            selected={selected}
                            onClick={() => handleBoardClick(item)}
                          />
                        );
                      })}
                    </div>
                  </motion.main>
                </div>
              ) : (
                <NoContent />
              )}
            </div>
          </>
        )}
        <div className="label-container">
          <p className="label-header">Select Class</p>
          {isSuperAdmin && (
            <div style={{ width: "20%" }}>
              <ClassButton />
            </div>
          )}
        </div>
        <div className="lecture-container">
          {classLoading ? (
            <SkeletonLoader label={"Class"} />
          ) : classes && classes?.length !== 0 ? (
            <div className="lecture-wrapper">
              <motion.main
                className="main__container_lecture"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
              >
                <div className="lecture-items-wrapper">
                  {classes.map((item, index) => {
                    const selected = item?.class_id == selectedClass?.class_id;
                    return (
                      <Card
                        key={index}
                        data={item}
                        src={item?.logo}
                        selected={selected}
                        label={` ${item?.class_name}`}
                        index={index}
                        onClick={() => handleClassClick(item)}
                        showDeleteButton={showDelete}
                        onDeleteButtonClick={() =>
                          handleDeleteClick("class", item)
                        }
                      />
                    );
                  })}
                </div>
              </motion.main>
            </div>
          ) : (
            <NoContent />
          )}
        </div>
        {classes && classes?.length !== 0 ? (
          <div className="label-container">
            <p className="label-header">Select Subject</p>
            {isSuperAdmin && (
              <div style={{ width: "20%" }}>
                <SubjectButton />
              </div>
            )}
          </div>
        ) : null}
        {subjectLoading ? (
          <SkeletonLoader label={"Subject"} />
        ) : subjects && subjects?.length !== 0 ? (
          <div className="lecture-wrapper">
            <motion.main
              className="main__container_lecture"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <div className="lecture-items-wrapper">
                {subjects.map((item, index) => {
                  return (
                    <Card
                      key={index}
                      data={item}
                      src={item?.subject_image}
                      selected={false}
                      label={`${item?.subject_name}`}
                      index={index}
                      onClick={() => handleSubjectClick(item)}
                      showDeleteButton={showDelete}
                      onDeleteButtonClick={() =>
                        handleDeleteClick("subject", item)
                      }
                    />
                  );
                })}
              </div>
            </motion.main>
          </div>
        ) : (
          classes?.length !== 0 && <NoContent />
        )}
      </LayoutContainer>
    </>
  );
}

import React from 'react';
import DefaultChapter from '../../../assets/images/default_chapter.png';
import { MdOutlineTimer } from 'react-icons/md';
import { FaRegTrashAlt } from 'react-icons/fa'; // Import delete icon from react-icons/fa

import './TopicItem.scss';
import moment from 'moment';
import { Avatar } from '../../../components';

export default function TopicItem({ label, id, index, selected, time, onClick, onDeleteClick, showDelete }) {

    const onDelete = (e) => {
        e.stopPropagation()
        onDeleteClick()
    }

    return (
        <div key={index} className={`topic-container ${selected ? 'selected' : ''}`} onClick={onClick}>
            <div>
                <Avatar textLabel={id} />
            </div>
            <div className='chapter-text-container'>
                <div className='chapter-label'>
                    {label && label}
                </div>
                <div className='topic-info'>
                    <div className='topic-info-inner'>
                        <MdOutlineTimer /> <p className='topic-inner-text'> {time}</p>
                    </div>
                </div>
            </div>
            <div>
            {showDelete && (
                <div className='delete-button-topic' onClick={onDelete}>
                    <FaRegTrashAlt className='delete-icon-topic' />
                </div>
            )}
            </div>
        </div>
    );
}

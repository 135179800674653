import { useQuery, useQueryClient } from "react-query";
import request from "../../utils/request";
import { BASE_URL } from "../../Config";


const getAdminList = async ({ queryKey }) => {
    const [_, school_id] = queryKey;
    const queryResponse = await request({
        // url: `${BASE_URL}/api/admin/onboard/getAdminList?limit=10&offset=0`,
        url: `${BASE_URL}/api/admin/onboard/getAdminList`,
        method: 'GET',
    })
    return queryResponse;
}

export const useGetAdminList = ({ school_id, enabled }) => {
    const queryClient = useQueryClient();
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getAdmin', ],
        queryFn: getAdminList,
        enabled: !!school_id,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data.rows;
    return { data: responseData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
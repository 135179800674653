import React, { useMemo, useState } from 'react'
import { CREATE_ADMIN_FORM, DEFAULT_FORM_DATA } from './constants';
import { FiUploadCloud } from 'react-icons/fi';
import { Button, LayoutContainer } from '../../../components';
import FormInput from '../../../components/FormInput/Input';
import { useNavigate } from 'react-router-dom';


import './CreateAdmin.scss';
import { useSharedToast } from '../../../App';
import { checkEmpty } from '../../../utils/checkEmpty';
import { useCreateAdmin } from '../../../queryHooks/useCreateAdmin/useCreateAdmin';
import { useAuthStore } from '../../../stores';


export default function CreateAdmin() {
  const navigate = useNavigate();
  const [input, setInput] = useState(DEFAULT_FORM_DATA);
  const { toast } = useSharedToast();
  const user = useAuthStore(state => state.user);
  const school_id = useAuthStore(state => state.school_id)
  const { createAdmin } = useCreateAdmin();

  const goBack = () => {
    navigate("../");
  }
  const ActionButton = () => {
    return (
      <div>
        <Button
          onClick={goBack}
          theme={"secondary cancel"}
          style={{ marginTop: "15px" }}
        >
          Exit
        </Button>
      </div>
    );
  };

  const showSuccess = () => {
    toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Admin profile created successfully!' });
  };

  const showError = () => {
    toast?.current?.show({ severity: 'error', summary: 'Invalid Form', detail: 'Please enter all the details!' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //do nothing
    const isEmpty = checkEmpty(input)
    if (isEmpty) {
      showError();
      return false;
    }
    createAdmin({ inputs: input, school_id: school_id }).then(() => {
      // goBack();
    }).catch((err) => {
      console.log('create admin err', err)
    })
  }

  const RenderActionButtons = ({ onSubmit }) => {
    return (
      <div style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
        <div style={{ width: '20%', margin: '10px', position: 'absolute', bottom: 10, right: 10 }}>
          <Button type="submit" form="profile-inputs" theme={'primary'} onClick={onSubmit}>
            {'Submit'}
          </Button>
        </div>
      </div>
    )
  }
  const CreateInputs = useMemo(() => {
    return (
      <>
        <form id="admin-inputs" className='admin-inputs' onSubmit={handleSubmit}>
          <div className="admin-input-container">
            {CREATE_ADMIN_FORM.map(
              (
                { label, type, options, name, placeholder, uploadButton, width},
                index
              ) => {
                return (
                  <div className={`form-field ${name}`} key={index}>
                    <h4 style={{ flexDirection: 'row', display: 'inline-flex' }}>{label}<p style={{ color: 'red' }}>*</p></h4>
                    <div className={`${uploadButton && "upload-present"}`}>
                      {type !== "dragNdrop" && (
                        <FormInput
                          type={type}
                          name={name}
                          width={width && width}
                          {...(type == 'file' && {
                            onChange: (e) => {
                              const value = URL.createObjectURL(e.target.files[0])
                              setInput(prevState => {
                                return {
                                  ...prevState,
                                  [name]: value,
                                };
                              });
                            }
                          })}
                          options={type === "select" && options}
                          {...(type !== 'file' && { value: input })}
                          setValue={setInput}
                          required={true}
                          placeholder={placeholder && placeholder}
                        />
                      )}
                      {type === "dragNdrop" && (
                        <div className="dragNdrop">{placeholder}</div>
                      )}
                      {uploadButton && (
                        <Button theme={"blue-outline-button"} icon={<FiUploadCloud color={'green'} />}>
                          {uploadButton}
                        </Button>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </form>
      </>
    )
  }, [input])

  return (
    <LayoutContainer
      label={"Create new admin profile"}
      RightComponent={<ActionButton />}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {CreateInputs}
        <RenderActionButtons onSubmit={handleSubmit} />
      </div>
    </LayoutContainer>
  )
}

import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';
import { useSharedToast } from '../../App';
import { convertToBase64Readable } from '../../utils/base64Encode';

const splitName = (name = '') => {
    const [firstName, ...lastName] = name.split(' ').filter(Boolean);
    return {
        firstName: firstName,
        lastName: lastName.join(' '),
    };
};


const createBusOperatorDetails = async ({ inputs, school_id }) => {
    const fullName = splitName(inputs.name)
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/school/create_bus_operatoer`,
        method: `POST`,
        data: {
            avtar: convertToBase64Readable(inputs.photo),
            bus_id: inputs.bus_no,
            name: fullName.firstName,
            full_name: `${fullName.firstName} ${fullName.lastName}`,
            username: inputs.username,
            gender: inputs.gender,
            address: inputs.address,
            idno: inputs.idno,
            Dl_no: inputs.Dl_no,
            emergency_no: inputs.emergency_no,
            contact_no: inputs.contact_no,
            password: inputs.password,
        },
    })
    return queryResponse;
}


export const useCreateBusOperator = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: createBusOperator, isLoading, error } = useMutation(createBusOperatorDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Bus Operator profile created successfully!' });
            }
        },
        onError: (error) => {
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error creating the operator profile.' });
        },
    });

    return {
        createBusOperator,
        isLoading,
        error,
    };
};

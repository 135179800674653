import React from "react";
import Topbar from "../Topbar/Topbar";
import "./LayoutContainer.scss";
import { motion } from "framer-motion";
export default function LayoutContainer({ children, RightComponent, label, onBackPress }) {
  const NoContent = () => {
    return (
      <div className="no-content">
        <p>You haven't selected any of the profile yet.</p>
      </div>
    );
  };

  return (
    <>
      <motion.main
        className="main__container"
        initial={{ opacity: 0, width: "100%",}}
        animate={{ width: "100%", opacity: 1 }}
        exit={{ x: "0%", opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div
          style={{
            width: "100%",
            height: "100vh",
            alignItems: "center",
            display: "flex",
            backgroundColor: "#F0F2F5",
            flexDirection: "column",
          }}
        >
          <Topbar backButton={onBackPress ? true : false} onBackPress={onBackPress && onBackPress} label={label} rightComponent={RightComponent} />
          <div className="layout-container">{children ?? <NoContent />}</div>
        </div>
      </motion.main>
    </>
  );
}

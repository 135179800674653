import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';
import { useSharedToast } from '../../App';
import { convertToBase64Readable } from '../../utils/base64Encode';


const createBusDetails = async ({ inputs, school_id }) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/school/create_bus`,
        method: `POST`,
        data: {
           name:inputs.bus_name,
           bus_number:inputs.bus_number,
           school_id:school_id
        },
    })
    return queryResponse;
}


export const useCreateBus = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: createBus, isLoading, error } = useMutation(createBusDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Bus profile created successfully!' });
            }
        },
        onError: (error) => {
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error creating the bus profile.' });
        },
    });

    return {
        createBus,
        isLoading,
        error,
    };
};

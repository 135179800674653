export const INPUTS = [
    {
        label: "Board Name",
        type: "text",
        name: "board_name",
        placeholder: "Enter Board Name",
    },
]

export const DEFAULT_INPUT_VALUE = {
    board_name: null,
}
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, DataHeader, LabelItem, LayoutContainer } from '../../../../../components';
import { SHOW_OPERATOR_NAME, SHOW_SCHOOL_FORM } from './constants';


import './BusOperatorProfile.scss';
import { useAuthStore } from '../../../../../stores';
import { toTitleCase } from '../../../../../utils/toTitleCase';

export default function BusOperatorProfile() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const user = useAuthStore(state => state.user);
    const school_id = useAuthStore(state => state.school_id)
    const fullName = `${state.full_name}`
    const email_id = state?.email_id || '-'
    const username = state?.username || '-'
    const password = '-';


    const goBack = () => {
        navigate("../");
    }

    const ActionButton = () => {
        return (
            <div>
                <Button
                    onClick={goBack}
                    theme={"secondary cancel"}
                    style={{ marginTop: "15px" }}
                >
                    Exit
                </Button>
            </div>
        );
    };

    const RenderBusOperatorProfile = () => {
        return SHOW_OPERATOR_NAME.map((item, index) => {
            if (state[item.name]) {
                return <LabelItem label={item.label} value={`${toTitleCase(state[item.name])}`} />
            }
        })
    }


    const RenderActionButtons = ({ onSubmit }) => {
        return (
            <div style={{ display: 'flex', flex: 0.5, alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
                <div style={{ width: '20%', margin: '10px' }}>
                    <Button type="submit" form="student-inputs" theme={'primary'} onClick={onSubmit}>
                        {'Edit'}
                    </Button>
                </div>
            </div>
        )
    }


    const handleEdit = () => {
        navigate('../new', { state: { ...state, mode: 'edit' } });
    }

    return (
        <LayoutContainer label={`${fullName}`} RightComponent={<ActionButton />}>
            <DataHeader role={'Bus Operator'} name={fullName} username={username} email_id={email_id} password={password} imageSrc={state.avtar}>
                <div className='student-label-container'>
                    <RenderBusOperatorProfile />
                </div>
            </DataHeader>
            <div style={{ flex: 1, height: '15vh' }} />
            {/* <RenderActionButtons onSubmit={handleEdit}/> */}
        </LayoutContainer>
    )
}

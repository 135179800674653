import { useMutation } from 'react-query';
import request from '../../../utils/request';
import { BASE_URL } from '../../../Config';
import { useSharedToast } from '../../../App';
import { convertToBase64Readable } from '../../../utils/base64Encode';
import { convertTo24Hour } from '../../../utils/convertTo24Hour';


const createSubjectDetails = async ({ inputs }) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/subject/create`,
        method: `POST`,
        data: {
            subject_name:inputs.subject_name,
            board_id:(inputs.board).toString(),
            class_id:inputs.class,
            subject_image:convertToBase64Readable(inputs.logo),
            // lock: inputs.lock
        },
    })
    return queryResponse;
}


export const useCreateSubject = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: createSubject, isLoading, error } = useMutation(createSubjectDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Subject created successfully!' });
            }
        },
        onError: (error) => {
            console.log(error, 'error here');
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error creating the subject.' });
        },
    });

    return {
        createSubject: createSubject,
        isLoading,
        error,
    };
};

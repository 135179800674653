import React, { useCallback, useState } from 'react'
import { Button, LayoutContainer, Topbar } from '../../components'
import { Outlet, useLocation, useNavigate, useOutlet } from 'react-router-dom'
import { convertToOrdinal } from '../../utils/convertToOrdinal';
import { useAuthStore } from '../../stores';
import { GoPlus } from 'react-icons/go';
import { Skeleton } from 'primereact/skeleton';
import { useGetChapters } from '../../queryHooks/useGetChapters/useGetChapters';


import './Classes.scss';
import Card from '../../components/Card/Card';
import ChapterItem from './components/ChapterItem';
import ChapterModal from './modals/ChapterModal/ChapterModal';
import { DELETE_PERMISSION } from '../../Config';
import PasswordModal from '../../components/PasswordModal/PasswordModal';

export default function Classes() {
  const { state } = useLocation();
  const outlet = useOutlet();
  const navigate = useNavigate();
  const user = useAuthStore(state => state.user);
  const showEditAndDelete = DELETE_PERMISSION.includes(user?.admin_type)
  const isSuperAdmin=user?.admin_type === "super_admin" ?true:false
  const [chapterModalVisible, setChapterModalVisible] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const school_id = useAuthStore(state => state.school_id)
  const header = `${state?.class_name ? (state.class_name) : ''} - ${state?.subject_name ?? ''}`
  const subject_id = state?.subject_id
  const class_id = state?.class_id

  const { data: chapters, error: chapterError, isInitialLoading: chapterLoading, refetch: chapterRefetch } = useGetChapters({ subject_id: state?.subject_id })


  const SkeletonLoader = () => {
    return (
      <div className='teacher-wrapper'>
        {Array.from(Array(10).keys()).map((_, index) => {
          return <div className="user-item-container" key={index} style={{ backgroundColor: 'transparent' }}>
            <Skeleton height='125px' key={index} />
          </div>
        })}
      </div>
    )
  }


  const NoContent = () => {
    return (
      <div className="no-content-chapter">
        <p>You haven't selected any of the chapter yet.</p>
      </div>
    );
  };

  const CreateChapterModal = useCallback(() => {
    return (
        <ChapterModal
        visible={chapterModalVisible}
        setVisible={setChapterModalVisible}
        subject_id={subject_id}
        class_id={class_id}
        />
    )
}, [chapterModalVisible])


  const handleChapterClick = (item) => {
    navigate(`./${item?.chapter_id}`, { state: item })
  }

  const ChapterButton = () => {
    return (
      <div>
        {isSuperAdmin &&
        <Button
          onClick={() => {
            setChapterModalVisible(true);
          }}
          icon={<GoPlus />}
          theme={"primary"}
          style={{ marginTop: "15px" }}
        >
          New Chapter
        </Button>
  }
      </div>
    );
  };


const handleBackPress = () => {
  navigate(-1)
}

const onDeleteChapter = () => {
  // deleteClass({class_id: deletedItem?.class?.class_id}).then(() => {
  //     queryClient.invalidateQueries({ queryKey: ['getClass'] })
  //     setDeletedItem((prevState) => {
  //         return {
  //             ...prevState,
  //             class: null
  //         }
  //     })
  // })
}

const handleDeleteClick = (item) => {
  setDeletedItem({chapter: item})
  setPasswordModalVisible(true);
}

const CreatePasswordVerifyModal = useCallback(() => {
  return (
      <PasswordModal
          visible={passwordModalVisible}
          setVisible={setPasswordModalVisible}
          header={'chapter'}
          onSuccess={onDeleteChapter}
      />
  )
}, [passwordModalVisible, header])



if (outlet) {
  return (
    <Outlet />
  )
}

  return (
    <>
    <CreatePasswordVerifyModal />
    <CreateChapterModal />
      <LayoutContainer onBackPress={handleBackPress} label={header} RightComponent={<ChapterButton />}>
        <div className="label-container">
          <p className="label-header">Chapters</p>
        </div>
        <div className="class-chapter-container">
          {chapterLoading ?
            <SkeletonLoader />
            : chapters && chapters.length !== 0 ?
              <div className="chapter-wrapper">
                <div className='chapter-items-wrapper'>
                  {chapters.map((item, index) => {
                    return (
                      <ChapterItem key={index} editAndDelete={showEditAndDelete} data={item} topics={item?.no_topic} label={item?.chapter_name} src={item?.chapter_image} time={item?.total_time} creationDate={item?.date_created} onDeletePress={() => handleDeleteClick(item)} index={index} onClick={() => {
                        handleChapterClick(item);
                      }} />
                    )
                  })}
                </div>
              </div>
              : <NoContent />}
        </div>
      </LayoutContainer>
    </>
  )
}

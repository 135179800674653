import { useQuery, useQueryClient } from "react-query";
import { BASE_URL } from "../../../Config";
import request from "../../../utils/request";



const getSchool = async ({ queryKey }) => {
    const [_, data] = queryKey;
    const limit = data?.limit
    const offset = data?.offset
    const school_id = data?.school_id
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/school/get?active=1&limit=${limit ?? '20'}&offset=${offset ?? '0'}${school_id ? `&school_id=${school_id}`:''}`,
        method: 'GET',
    })
    return queryResponse;
}

export const useGetSchool = ({ limit, offset, selected_id, school_id, enabled }) => {
    const queryClient = useQueryClient();
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getSchool', { limit, offset, school_id }],
        queryFn: getSchool,
        enabled: enabled,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;
    if (selected_id && responseData && responseData?.length !== 0) {
        const selectedIndex = responseData?.findIndex(item => item.school_id == selected_id);
        responseData?.push(...responseData.splice(0, selectedIndex));
    }
    return { data: responseData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
import React from 'react'
import "./Avatar.scss";
import { Avatar as Av } from 'primereact/avatar';
import DefaultAvatar from '../../assets/images/default_avatar.png'

export default function Avatar({name, role, image, textLabel, theme}) {
  return (
    <div className='avatar-container'>
      {textLabel ? <div style={{
        height:'53px',
        width:'53px',
        display:'flex',
        borderRadius:'50px',
        backgroundColor:'#c467ff4d',
        justifyContent:'center',
        alignItems:'center',
      }}>
        {textLabel}
      </div>:
      <img src={image && image !== null ? image : DefaultAvatar} height={'53px'} width={'53px'} style={{borderRadius:'50px'}} className='p-avater-image'/>}
    <div className={`avatar-text-container ${theme}`}>
        {name &&<p  className={`avatar-name ${theme}`}>
            {name}
        </p>}
        {role && <p className={`avatar-role ${theme}`}>
        {role}
        </p>}
    </div>
    </div>
  )
}

import React from "react";
import "./Topbar.scss";
import { AiOutlineArrowLeft } from 'react-icons/ai';
export default function Topbar({ label, rightComponent, backButton, onBackPress }) {
  return (
    <>
      <div className="topbar-container">
        <div className="topbar-wrapper">
          <div className="topbar-left-component"> 
            {backButton && <div className="back-button"><AiOutlineArrowLeft size={25} onClick={onBackPress}/></div>}
            <p>{label ?? "Dashboard"}</p>
          </div>
          <div className="topbar-right-component">
            {rightComponent ?? <></>}
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export const INPUTS = [
    {
        label: "Section Name",
        type: "select",
        name: "section_name",
        options: [
            { value: "A", label: "A" },
            { value: "B", label: "B" },
            { value: "C", label: "C" },
            { value: "D", label: "D" },
            { value: "E", label: "E" },
            { value: "F", label: "F" },
            { value: "G", label: "G" },
            { value: "H", label: "H" },
            { value: "I", label: "I" },
            { value: "J", label: "J" },
            { value: "K", label: "K" },
        ],
    },
    {
        label: "Lock Section",
        type: "select",
        name: "lock_section",
        menuOnTop:true,
        options: [
            { value: true, label: "Lock" },
            { value: false, label: "Unlock" },
        ],
    },
]

export const DEFAULT_INPUT_VALUE = {
    section_name: null,
    lock_section: null,
}
import { useQuery, useQueryClient } from "react-query";
import request from "../../../utils/request";
import { BASE_URL } from "../../../Config";


const getSubject = async ({ queryKey }) => {
    const [_, obj] = queryKey;
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/subject/getsubject?active=1&class_id=${obj.class_id}`,
        //&school_id=${obj.school_id}
        method: 'GET',
    })
    return queryResponse;
}

export const useGetSubject = ({ class_id, rowId, school_id, enabled }) => {
    const queryClient = useQueryClient();
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getSubject', {class_id, rowId, school_id}],
        queryFn: getSubject,
        staleTime: 10 * (60 * 1000), // 5 mins
        cacheTime: 15 * (60 * 1000), // 15 mins 
        enabled: class_id ? true : false,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;
    const formattedData = responseData?.map((item, index) => {
        let obj = {
            label: item.subject_name,
            value: item.subject_id
        }
        return { ...item, ...obj }
    })
    return { data: formattedData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
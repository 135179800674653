import React, {useState, useEffect} from "react";
import "./InputDuration.scss";
import Input from "../../Input";
import DurationPicker from 'react-duration-picker'


// const InputDuration = ({ input, name, setInput, className, ...rest }) => {

//   const formatDuration = (hours, minutes, seconds) => {
//     if (hours > 0) {
//       if (minutes > 0) {
//         return `${hours}h${minutes}m`;
//       }
//       return `${hours}h`;
//     } else if (minutes > 0) {
//       if (seconds > 0) {
//         return `${minutes}m${seconds}s`;
//       }
//       return `${minutes}m`;
//     } else {
//       return `${seconds}s`;
//     }
//   };

//     const onChange = (duration) => {
//         const { hours, minutes, seconds } = duration;
//         const formattedTime = formatDuration(hours, minutes,seconds)
//         setInput(prevState => {
// 			return {
// 				...prevState,
// 				[name]: formattedTime,
// 			};
// 		});
//       };

//     return (
//         <div className={`input-date ${className}`} {...rest}>
//             <div className="input-fields">
//                 <DurationPicker
//                     onChange={onChange}
//                     initialDuration={{ hours: 0, minutes: 0, seconds: 0 }}
//                     maxHours={5}
//                 />
//             </div>{" "}
//         </div>
//     );
// };


// export default InputDuration;

const InputDuration = ({ input, setInput, className, name, ...rest }) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const duration = `${hours}h ${formattedMinutes}m`;
    setInput(prev => ({ ...prev, [name]: duration}));
  }, [hours, minutes]);

  const handleInputChange = (event, setValue, maxValue) => {
    // const value = parseInt(event.target.value, 10);
    // setValue(isNaN(value) ? 0 : Math.min(value, maxValue));
  };

  return (
    <div className={`duration-input ${className}`} {...rest}>
      <div className="duration-input-container">
        <Input
          type="number"
          id="hours"
          value={hours}
          setValue={setHours}
        />
        <label className="duration-input-label" htmlFor="hours">Hours</label>
      </div>
      <div className="duration-input-container">
        <Input
          type="number"
          id="minutes"
          value={minutes}
          setValue={setMinutes}
        />
        <label className="duration-input-label" htmlFor="minutes">Minutes</label>
      </div>
    </div>
  );
};

export default InputDuration;

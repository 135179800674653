import { useQuery, useQueryClient } from "react-query";
import { BASE_URL } from "../../../Config";
import request from "../../../utils/request";


const getSection = async ({ queryKey }) => {
    const [_, class_id] = queryKey;
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/class/getsection?class_id=${class_id}`,
        method: 'GET',
    })
    return queryResponse;
}

export const useGetSection = ({ class_id, enabled }) => {
    const queryClient = useQueryClient();
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getSection', class_id],
        queryFn: getSection,
        staleTime: 10 * (60 * 1000), // 5 mins
        cacheTime: 15 * (60 * 1000), // 15 mins 
        enabled: class_id ? true : false,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;
    const formattedData = responseData?.map((item, index) => {
        let obj = {
            label: item.section_name,
            value: item.section_id
        }
        return { ...item, ...obj }
    })
    return { data: formattedData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
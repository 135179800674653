import { useMutation } from 'react-query';
import { useSharedToast } from '../../../App';
import { BASE_URL } from '../../../Config';
import request from '../../../utils/request';

const deleteSchoolDetails = async ({ school_id }) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/school/delete`,
        method: `DELETE`,
        data: {
            school_id: school_id
        },
    })
    return queryResponse;
}


export const useDeleteSchool = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: deleteSchool, isLoading, error } = useMutation(deleteSchoolDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'School school deleted successfully!' });
            }
        },
        onError: (error) => {
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error deleting the school profile.' });
        },
    });

    return {
        deleteSchool,
        isLoading,
        error,
    };
};

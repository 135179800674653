import React, { useCallback, useState } from "react";
import { Button, LayoutContainer, Topbar } from "../../components";
import { Outlet, useLocation, useNavigate, useOutlet } from "react-router-dom";
import { convertToOrdinal } from "../../utils/convertToOrdinal";
import { useAuthStore } from "../../stores";
import { GoPlus } from "react-icons/go";
import { Skeleton } from "primereact/skeleton";
import { useGetChapters } from "../../queryHooks/useGetChapters/useGetChapters";

import "./Chapters.scss";
import Card from "../../components/Card/Card";
import { useGetTopics } from "../../queryHooks/useGetTopics/useGetTopics";
import ChapterItem from "../Classes/components/ChapterItem";
import TopicItem from "./components/TopicItem";
import TopicModal from "./modals/TopicModal/TopicModal";
import { DELETE_PERMISSION } from "../../Config";
import PasswordModal from "../../components/PasswordModal/PasswordModal";
import { useDeleteTopic } from "../../queryHooks/useDeleteTopic/useDeleteTopic";
import { useQueryClient } from "react-query";
import lessonVideos from "../../assets/icons/lectures/Circled Play.svg";
import presentation from "../../assets/icons/lectures/Microsoft PowerPoint.svg";
import testSeries from "../../assets/icons/lectures/Exam.svg";
import descision from "../../assets/icons/lectures/Decision.svg";
import classroom from "../../assets/icons/lectures/Classroom.svg";

const lesssonFLowArray = [
  { name: "Lesson_videos", png: lessonVideos, title: "Lesson Videos" },
  { name: "Presentations", png: presentation, title: "Presentation" },
  { name: "Test_series", png: testSeries, title: "Test Series" },
  { name: "mcqs", png: descision, title: "MCQ" },
  { name: "lesson_plan", png: classroom, title: "Lesson Plan" },
];

export default function Chapters() {
  const { state } = useLocation();
  const class_id = state?.class_id;
  const subject_id = state?.subject_id;
  const chapter_id = state?.chapter_id;
  const outlet = useOutlet();
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const showDelete = DELETE_PERMISSION.includes(user?.admin_type);
  const isSuperAdmin = user?.admin_type === "super_admin" ? true : false;
  const [topicModalVisible, setTopicModalVisible] = useState(false);
  const school_id = useAuthStore((state) => state.school_id);
  const chapter = state;
  const header = `${state?.chapter_name}`;
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const queryClient = useQueryClient();

  // const {
  //   data: topics,
  //   error: topicError,
  //   isInitialLoading: topicsLoading,
  //   refetch: topicRefetch,
  // } = useGetTopics({ chapter_id: state?.chapter_id });

  const { deleteTopic } = useDeleteTopic();
  const SkeletonLoader = () => {
    return (
      <div className="teacher-wrapper">
        {Array.from(Array(10).keys()).map((_, index) => {
          return (
            <div
              className="user-item-container"
              key={index}
              style={{ backgroundColor: "transparent" }}
            >
              <Skeleton height="125px" key={index} />
            </div>
          );
        })}
      </div>
    );
  };

  const NoContent = () => {
    return (
      <div className="no-content-chapter">
        <p>You haven't selected any of the chapter yet.</p>
      </div>
    );
  };

  const onDeleteTopic = () => {
    deleteTopic({ topic_id: deletedItem?.topic?.topic_id }).then(() => {
      queryClient.invalidateQueries({ queryKey: ["getTopics"] });
      setDeletedItem((prevState) => {
        return {
          ...prevState,
          class: null,
        };
      });
    });
  };

  const CreateTopicModal = useCallback(() => {
    return (
      <TopicModal
        visible={topicModalVisible}
        setVisible={setTopicModalVisible}
        subject_id={subject_id}
        class_id={class_id}
        chapter_id={chapter_id}
      />
    );
  }, [topicModalVisible]);

  const CreatePasswordVerifyModal = useCallback(() => {
    return (
      <PasswordModal
        visible={passwordModalVisible}
        setVisible={setPasswordModalVisible}
        header={header}
        onSuccess={onDeleteTopic}
      />
    );
  }, [passwordModalVisible]);

  const handleDeleteClick = (item) => {
    setDeletedItem({ topic: item });
    setPasswordModalVisible(true);
  };

  const handleChapterClick = (item) => {
    navigate(`./${item?.chapter_id}`, { state: item });
  };

  const ChapterButton = () => {
    return (
      <div>
        {isSuperAdmin && (
          <Button
            onClick={() => {
              setTopicModalVisible(true);
            }}
            icon={<GoPlus />}
            theme={"primary"}
            style={{ marginTop: "15px" }}
          >
            New Topic
          </Button>
        )}
      </div>
    );
  };

  const handleBackPress = () => {
    navigate(-1);
  };

  if (outlet) {
    return <Outlet />;
  }

  return (
    <>
      <CreatePasswordVerifyModal />
      <CreateTopicModal />
      <LayoutContainer
        onBackPress={handleBackPress}
        label={header}
        RightComponent={<ChapterButton />}
      >
        <div className="topic-chapter-container">
          {chapter ? (
            <div className="topic-chapter-wrapper">
              <div className="chapter-items-wrapper">
                <ChapterItem
                  data={chapter}
                  topics={chapter?.no_topic}
                  label={chapter?.chapter_name}
                  src={chapter?.chapter_image}
                  time={chapter?.total_time}
                  creationDate={chapter?.date_created}
                  index={0}
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className="label-container">
          <p className="label-header">Lesson Flow</p>
        </div>
        <div className="main-parent-lessonflow">
          {lesssonFLowArray.map((item, index) => {
            return (
              <div
                onClick={() => {
                  navigate(`./${item?.name}`, { state: state, item: item });
                }}
                className="main-container-classname-lesson-flow"
              >
                <img className="img-png" src={item.png} alt={item.name} />
                <div className="name-lesson-flow-header">{item.title}</div>
              </div>
            );
          })}
        </div>
        {/* <div className="topic-chapter-container">
          {topicsLoading ? (
            <SkeletonLoader />
          ) : topics && topics.length !== 0 ? (
            <div className="topic-wrapper">
              <div className="topic-items-wrapper">
                {topics.map((item, index) => {
                  return (
                    <TopicItem
                      showDelete={showDelete}
                      onDeleteClick={() => handleDeleteClick(item)}
                      key={`${index}_${item?.id}`}
                      data={item}
                      id={item?.id}
                      label={item?.topic_name}
                      src={item?.chapter_image}
                      time={item?.time}
                      index={index}
                      onClick={() => {
                        handleChapterClick(item);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <NoContent />
          )}
        </div> */}
      </LayoutContainer>
    </>
  );
}

import { useMutation } from 'react-query';
import request from '../../../utils/request';
import { BASE_URL } from '../../../Config';
import { useSharedToast } from '../../../App';
import { convertToBase64Readable } from '../../../utils/base64Encode';
import { convertTo24Hour } from '../../../utils/convertTo24Hour';


const createClassDetails = async ({ inputs, school_id }) => {
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/class/create`,
        method: `POST`,
        data: {
            class_name:inputs.class_name,
            board_id:(inputs.board).toString(),
            logo:convertToBase64Readable(inputs.icon),
            // school_id:school_id,
            lock: inputs.lock_class?0:1
        },
    })
    return queryResponse;
}


export const useCreateClass = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: createClass, isLoading, error } = useMutation(createClassDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Class created successfully!' });
            }
        },
        onError: (error) => {
            console.log(error, 'error here');
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! there was an error creating the Class.' });
        },
    });

    return {
        createClass: createClass,
        isLoading,
        error,
    };
};

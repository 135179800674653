import React, { useLayoutEffect, useState } from "react";
import { useAuthStore, useSidebarStore } from "../../../stores";
import {
  Sidebar as SideMenu,
  Menu,
  MenuItem,
  useProSidebar,
  sidebarClasses,
  menuClasses,
  SubMenu,
} from "react-pro-sidebar";
import { MODULES } from "../../../constants";
import Avatar from "../../Avatar/Avatar";
import LogoutIcon from "../../../assets/icons/logout.svg";
import "./Sidebar.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toTitleCase } from "../../../utils/toTitleCase";
import { SUPER_ADMIN_MODULES } from "../../../constants/modules";
import { useGetSchool } from "../../../queryHooks/School/useGetSchool/useGetSchool";
import { Skeleton } from 'primereact/skeleton';



export default function Sidebar() {
  const { collapseSidebar, broken } = useProSidebar();
  const selectedSchool = useAuthStore((state) => state.school_id);
  const { data: schools, error: schoolError, isLoading: schoolLoading, isFetching: schoolFetching, refetch: schoolRefetch } = useGetSchool({ limit: 10, offset: 0, school_id: selectedSchool,enabled :selectedSchool !== "null"?true:false})
  const signOut = useAuthStore((state) => state.signOut);
  const navigate = useNavigate();
  const windowLoc = window.location.pathname;
  const user = useAuthStore((state) => state.user);
  const user_name = `${toTitleCase(user?.first_name)} ${toTitleCase(user?.last_name)}`
  const responseBreakpoint = 576;
  const [width, setWidth] = useState(0);
  const type = user?.admin_type

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const USER_TYPE = () => {
    switch (type) {
      case 'student':
        return 'Student'
      case 'admin':
        return 'Admin'
      case 'super_admin':
        return 'Super Admin'
      default:
        return 'Supervisor Admin'
    }
  }

  const RenderSuperAdminItems = () => {
    return (
      <Menu
        menuItemStyles={{
          icon: {
            [`&.${menuClasses.active}`]: {
              color: "#C467FF",
            },
          },
          button: {
            [`&.${menuClasses.active}`]: {
              color: "#C467FF",
              fontWeight: 500,
              backgroundColor: "#C467FF0D",
            },
          },
        }}

        rootStyles={{
          [`.${menuClasses.menuItemRoot}`]: {
            margin: "0.35rem 0rem",
            fontSize: "13px",
            color: "white",
            fontFamily: "Roboto",
            position: "relative",
            marginRight: "0px",
          },
          [`.${menuClasses.icon}`]: {
            margin: "0.25rem 0rem",
          },
          [`.${menuClasses.button}`]: {
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            '&:hover': {
              color: "#C467FF",
              fontWeight: 500,
              backgroundColor: "#C467FF0D",
            }
          },
        }}
      >

        {SUPER_ADMIN_MODULES.map((item, index) => {
          if (item.sub_menu) {
            return (
              <div style={{ position: "relative" }} key={item.name}>
                <SubMenu
                  rootStyles={{
                    ['& > .' + menuClasses.button]: {
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                      '&:active': {
                        color: "#C467FF",
                        fontWeight: 500,
                        backgroundColor: "#C467FF0D",
                      }
                    },
                    ['.' + menuClasses.subMenuContent]: {
                      backgroundColor: 'transparent',
                    },
                  }} defaultOpen={true}
                  label={item.label}
                  key={index}
                  icon={item.icon}
                >
                  {item?.sub_menu_items.map((subItem, subIndex) => {
                    return <MenuItem
                      key={subIndex}
                      active={windowLoc.includes(`${subItem.route}`)}
                      icon={subItem.icon}
                      component={<Link to={`${subItem.route}`} />}
                    >
                      {subItem.label}
                    </MenuItem>
                  })}
                </SubMenu>
              </div>
            );
          }
          if (item?.permission) {
            if (!item?.permission?.includes(user?.admin_type)) {
              return null;
            }
          }
          return (
            <div style={{ position: "relative" }} key={item.name}>
              {windowLoc.includes(`${item.route}`) && (
                <div className={`blip ${index}`} />
              )}
              <MenuItem
                key={index}
                active={windowLoc.includes(`${item.route}`)}
                icon={item.icon}
                component={<Link to={`${item.route}`} />}
              >
                {item.label}
              </MenuItem>
            </div>
          );
        })}
        <hr className="sidebar-divider" />
      </Menu>
    );
  };


  const RenderMenuItems = () => {
    return (
      <Menu
        menuItemStyles={{
          icon: {
            [`&.${menuClasses.active}`]: {
              color: "#C467FF",
            },
          },
          button: {
            [`&.${menuClasses.active}`]: {
              color: "#C467FF",
              fontWeight: 500,
              backgroundColor: "#C467FF0D",
            },
          },
        }}

        rootStyles={{
          [`.${menuClasses.menuItemRoot}`]: {
            margin: "0.35rem 0rem",
            fontSize: "13px",
            color: "white",
            fontFamily: "Roboto",
            position: "relative",
            marginRight: "0px",
          },
          [`.${menuClasses.icon}`]: {
            margin: "0.25rem 0rem",
          },
          [`.${menuClasses.button}`]: {
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            '&:hover': {
              color: "#C467FF",
              fontWeight: 500,
              backgroundColor: "#C467FF0D",
            }
          },
        }}
      >

        {MODULES.map((item, index) => {
          if (item?.permission) {
            if (!item?.permission?.includes(user?.admin_type)) {
              return null;
            }
          }
          if(type == 'super_admin' && item.name == 'lecture')
          {
            return null;
          }
          if (item.sub_menu) {
            return (
              <div style={{ position: "relative" }} key={item.name}>
                <SubMenu
                  rootStyles={{
                    ['& > .' + menuClasses.button]: {
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                      '&:active': {
                        color: "#C467FF",
                        fontWeight: 500,
                        backgroundColor: "#C467FF0D",
                      }
                    },
                    ['.' + menuClasses.subMenuContent]: {
                      backgroundColor: 'transparent',
                    },
                  }} defaultOpen={true}
                  label={item.label}
                  key={index}
                  icon={item.icon}
                >
                  {item?.sub_menu_items.map((subItem, subIndex) => {
                    return <MenuItem
                      key={subIndex}
                      active={windowLoc.includes(`${subItem.route}`)}
                      icon={subItem.icon}
                      component={<Link to={`${subItem.route}`} />}
                    >
                      {subItem.label}
                    </MenuItem>
                  })}
                </SubMenu>
              </div>
            );
          }

          return (
            <div style={{ position: "relative" }} key={item.name}>
              {windowLoc.includes(`${item.route}`) && (
                <div className={`blip ${index}`} />
              )}
              <MenuItem
                key={index}
                active={windowLoc.includes(`${item.route}`)}
                icon={item.icon}
                component={<Link to={`${item.route}`} />}
              >
                {item.label}
              </MenuItem>
            </div>
          );
        })}
      </Menu>
    );
  };

  return (
    <div className="main-sidebar-container disable-scrollbars">
      <SideMenu
        defaultCollapsed={false}
        collapsedWidth={'20px'}
        collapsed={width < responseBreakpoint}
        rootStyles={{
          border: 'none',
          height: "98vh",
          display: 'flex',
          flexDirection: 'column',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          backgroundColor: 'transparent',
          [`.${sidebarClasses.container}`]: {
            backgroundColor: "#1C1C1D",
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            borderRadius: "13px",
            border: "none",
            overflowY:'hidden',
          },
          [`.${sidebarClasses.root}`]: {
            backgroundColor: 'blue',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            border: 'none',
            scrollbarWidth: 'none',
          },
        }}
      >
        <div onClick={() => navigate("/")}>
          <Avatar name={user_name} role={<USER_TYPE />} theme={'dark'} />
        </div>
        <div className="sidebar-inner-wrapper disable-scrollbars">
          <RenderSuperAdminItems />
          {type === 'super_admin' && Array.isArray(schools)&& selectedSchool !=="null" &&(
            schoolLoading ? <div className="school-avatar-container" key={selectedSchool} style={{backgroundColor:'transparent'}}>
            <Skeleton height='60px' width="100%" key={selectedSchool} />
            </div>:
              <Avatar image={schools.length>0?schools[0]?.logo:""} name={schools.length>0? schools[0]?.school_name:""} role={schools[0]?.school_tagline} theme={'dark'} />
          )}
          {
            selectedSchool !=="null" &&
          
          <RenderMenuItems />
}
          <div className="sidebar-logout-container">
            <Menu
              rootStyles={{
                [`.${menuClasses.menuItemRoot}`]: {
                  margin: "0.35rem 0rem",
                  fontSize: "13px",
                  color: "white",
                  fontFamily: "Roboto",
                  position: "relative",
                  marginRight: "0px",
                },
                [`.${menuClasses.icon}`]: {
                  margin: "0.25rem 0rem",
                },
                [`.${menuClasses.button}`]: {
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  '&:hover': {
                    color: "#C467FF",
                    fontWeight: 500,
                    backgroundColor: "#C467FF0D",
                  }
                },
              }}
            >
              <MenuItem key={"logout"} onClick={() => signOut()}>
                <div className="sidebar-menu-container">
                  <img src={LogoutIcon} className="sidebar-menu-icon" />
                  <p className="log-out">{"Log Out"}</p>
                </div>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </SideMenu>
    </div>
  );
}

export const VIDEO_INPUTS = [
    {
        label: "File",
        type: "dragNdrop",
        name: "file_name",
        uploadButton: "Upload file",
    },
    {
        label: "Video Thumbnail",
        type: "dragNdrop",
        name: "video_image",
        uploadButton: "Upload image",
        media_type: 'video',
    },
    {
        label: "Video Caption",
        type: "text",
        name: "video_caption",
        placeholder:'Enter the video caption',
        media_type: 'video',
    },
    {
        label: "Video Duration",
        type: "duration",
        name: "video_duration",
        placeholder:'Enter video duration in minutes',
        media_type: 'video',
    },
    {
        label: "Name",
        type: "text",
        name: "note_name",
        placeholder: "Enter name of the note(s)",
    },
    {
        label: "Chapter Index",
        type: "text",
        name: "index",
        placeholder: "Enter chapter index",
    },
]

export const PDF_INPUTS = [
    {
        label: "File",
        type: "dragNdrop",
        name: "file_name",
        uploadButton: "Upload file",
    },
    {
        label: "Name",
        type: "text",
        name: "note_name",
        placeholder: "Enter name of the note(s)",
    },
    {
        label: "Chapter Index",
        type: "text",
        name: "index",
        placeholder: "Enter chapter index",
    },
]



export const DEFAULT_INPUT_VALUE = {
    note_name: null,
    index: null,
    video_duration:null,
    video_caption:null,
    video_image:null,
    file_name:null,
}
export const DEFAULT_NOTES_INPUT_VALUE = {
    note_name: null,
    index: null,
    file_name:null,
}
export const VIDEO_INPUTS = [
  {
    label: "Upload Pdf",
    type: "dragNdrop",
    name: "pdf",
    uploadButton: "Upload file",
  },
  {
    label: "MCQ name",
    type: "text",
    name: "name",
    placeholder: "Enter the video caption",
    media_type: "video",
  },
  {
    label: "Index",
    type: "text",
    name: "index",
    placeholder: "Enter chapter index",
  },
];

export const PDF_INPUTS = [
  {
    label: "File",
    type: "dragNdrop",
    name: "file_name",
    uploadButton: "Upload file",
  },
  {
    label: "Name",
    type: "text",
    name: "note_name",
    placeholder: "Enter name of the note(s)",
  },
  {
    label: "Chapter Index",
    type: "text",
    name: "index",
    placeholder: "Enter chapter index",
  },
];

export const DEFAULT_INPUT_VALUE = {
  pdf: null,
  name: null,
  index: null,
};
export const DEFAULT_NOTES_INPUT_VALUE = {
  note_name: null,
  index: null,
  file_name: null,
};

export const SHOW_SCHOOL_FORM = [
    {
        label: "School Name",
        name: "school_name",
    },
    {
        label: "School Address",
        name: "address",
    },
    {
        label: "School Tagline",
        name: "school_tagline",
    },
    {
        label: "Owner Name",
        name: "ownerName",
    },
    {
        label: "Location URL",
        name: "locationURL",
    },
    {
        label: "Contact No.",
        name: "contantNo",
    },
]
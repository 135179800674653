import { useQuery, useQueryClient } from "react-query";
import request from "../../utils/request";
import { BASE_URL } from "../../Config";


const getNotes = async ({ queryKey }) => {
    const [_, data] = queryKey;
    const class_id = data?.class_id
    const subject_id = data?.subject_id
    const topic_id = data?.topic_id
    const chapter_id = data?.chapter_id
    const video_id=data?.video_id
    const queryResponse = await request({
        url: `${BASE_URL}/api/admin/video/getVide_with_notest?video_id=${video_id}`,
        method: 'GET',
    })
    return queryResponse;
}

export const useGetNotes = ({ chapter_id, class_id, subject_id, topic_id,video_id, enabled }) => {
    const { data, error, isError, isLoading, isInitialLoading, isFetching, refetch } = useQuery({
        queryKey: ['getNotes', {topic_id, class_id, subject_id, chapter_id,video_id}],
        queryFn: getNotes,
        enabled:enabled,
        useErrorBoundary: (error) => error?.response?.status >= 500,
    })
    const responseData = data?.data?.data;
    const formattedData = responseData?.map((item, index) => {
        let obj = {
            label: item.name,
            value:item.id
        }
        return {...item, ...obj}
    })
    return { data: formattedData, error, isError, isLoading, isInitialLoading, isFetching, refetch };
}
import { useMutation } from 'react-query';
import request from '../../utils/request';
import { BASE_URL } from '../../Config';
import { useSharedToast } from '../../App';
import { convertToBase64Readable } from '../../utils/base64Encode';

const splitName = (name = '') => {
    const [firstName, ...lastName] = name.split(' ').filter(Boolean);
    return {
        firstName: firstName,
        lastName: lastName.join(' '),
    };
};

/* 
 "first_name":"shiv",
  "address":"testing adderess",
  "middle_name":"",
  "last_name":"kumar",
  "school_id":"school_id6268bd7d0d18",
  "class_id":"class_9207d31b0cca",
  "section":"1",
  "guardian_name":"shiv ji",
  "contact_no":"8700300731",
  "id_no":"1",
  "dob":"",
  "email_id":"shivkr98188+student2@gmail.com",
  "password":"123456789",
  "gender":"Male",
  "idNo":"1",
  "avtar":"",
  "bus_no":"1"
  */


const createAdminDetails = async ({ inputs, school_id }) => {
    const fullName = splitName(inputs.name)
    return request({
        url: `${BASE_URL}/api/admin/onboard/createAdmin`,
        method: `POST`,
        data: {
            first_name: fullName.firstName,
            last_name: fullName.lastName,
            org_id: school_id.toString(),
            admin_type: inputs.role,
            email: inputs.email_id,
            password: inputs.new_password,
            // avtar: convertToBase64Readable(inputs.photo),
        },
    })
}


export const useCreateAdmin = () => {
    const { toast } = useSharedToast();
    const { mutateAsync: createAdmin, isLoading, error } = useMutation(createAdminDetails, {
        onSuccess: (data) => {
            // Success actions
            if (data.status == 200) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Admin profile created successfully!' });
            }
            throw data;
        },
        onError: (error) => {
            console.log(error,error?.response?.data?.message, 'error here');
            const message = error?.response?.data?.message || 'Oops! there was an error creating the admin profile.'
            // Error actions
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: `${message}` });
        },
    });

    return {
        createAdmin,
        isLoading,
        error,
    };
};

import React from "react";
import { useParams } from "react-router-dom";
import LeessonVideos from "../lesson_videos/lesson_videos";
import Presentation from "../presentation/presentation";
import TestSeries from "../test_series/testSeries";
import MCQS from "../mcq/mcq";
import LessonPlan from "../lesson_plan/lessonPlan";
function LessonFlow() {
  const { chapter, topic } = useParams();

  return (
    <div style={{ flex: "1" }}>
      {topic === "lesson_plan" ? (
        <LessonPlan index={5} />
      ) : topic === "Presentations" ? (
        <Presentation index={2} />
      ) : topic === "Test_series" ? (
        <TestSeries index={3} />
      ) : topic === "mcqs" ? (
        <MCQS index={4} />
      ) : (
        <LeessonVideos topic={topic} chapterId={chapter} index={1} />
      )}
    </div>
  );
}

export default LessonFlow;

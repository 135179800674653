// logo
// subject_name
// select_board
// select_class
// subject_index
// lock

export const INPUTS = [
    {
        label: "Subject Logo",
        type: "dragNdrop",
        name: "logo",
        placeholder: "Upload subject icon",
    },
    {
        label: "Subject Name",
        type: "text",
        name: "subject_name",
        placeholder: "Enter your Subject Name",
    },
    {
        label: "Subject Index",
        type: "text",
        name: "index",
        placeholder: "Enter subject index",
    },
    {
        label: "Select Board",
        type: "select",
        name: "board",
        options: [],
        menuOnTop: true,
    },
    {
        label: "Select Class",
        type: "select",
        name: "class",
        menuOnTop: true,
    },
    {
        label: "Lock Subject",
        type: "select",
        name: "lock",
        menuOnTop: true,
        options: [
            { value: true, label: "Lock" },
            { value: false, label: "Unlock" },
        ],
    },
]

export const DEFAULT_INPUT_VALUE = {
    logo: null,
    subject_name: null,
    index: null,
    board: null,
    class:null,
    lock: null,
}
import { DATE_TYPE_OPTIONS, MONTH_TYPE_OPTIONS, YEAR_TYPE_OPTIONS } from "../../../../constants";

export const DateData = [
	{
		name: "DD",
		options: DATE_TYPE_OPTIONS,
	},
	{
		name: "MM",
		options: MONTH_TYPE_OPTIONS,
	},
	{
		name: "YYYY",
		options: YEAR_TYPE_OPTIONS,
	},
];

export const CREATE_CIRCULAR_FORM = [
    {
        label: "Select Date",
        type: "date",
        name: "event_date",
        placeholder: "Enter the Date of Event",
        customDateData : DateData,
    },
    {
        label: "Select Category",
        type: "select",
        options: [
            { value: "Extra Curricular", label: "Extra Curricular" },
            { value: "Holidays", label: "Holidays" },
        ],
        name: "event_type",
    },
    {
        label: "Add Heading",
        type: "text",
        name: "event_name",
        placeholder: "Enter circular heading",
    },
    {
        label: "Upload PDF",
        type: "file",
        name: "document",
        placeholder: "Upload your PDF",
      },
      {
        label: "Select Time",
        type: "time",
        name: "event_time",
        placeholder: "Enter the Time of Event",
    },
      {
        label: "Description",
        type: "textarea",
        name: "description",
        height:'50%',
        placeholder: "Enter Description",
      },
    
];

export const DEFAULT_FORM_DATA = {
    event_type:'',
    event_name:'',
    document:'',
    event_time:'',
    description:'',
    DD: "",
    MM: "",
    YYYY: "",
};

import React from 'react';
import { AiFillDelete } from 'react-icons/ai';
import DefaultLecture from '../../assets/images/default_lecture.png';
import './Card.scss';

export default function Card({ src, label, index, onClick, selected, showDeleteButton, onDeleteButtonClick }) {

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    if(onDeleteButtonClick && typeof(onDeleteButtonClick) == 'function') onDeleteButtonClick();
  };

  return (
    <div key={`${index}_${label}_card`} className={`card-container ${selected ? 'selected' : ''}`} onClick={onClick}>
      <div className="card-content">
        <img src={src ?? DefaultLecture} className="card-image" alt={label} />
        <div className="card-label">{label}</div>
      {showDeleteButton && (
        <button className="delete-button-card" onClick={handleDeleteClick}>
          <AiFillDelete className="delete-icon" />
        </button>
      )}
      </div>
    </div>
  );
}

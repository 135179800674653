import React, { useEffect, useRef, useState } from "react";
import { LOGO_ITEMS, ONBOARDING_ITEMS } from "./constants";
import "./Login.scss";
import { Galleria } from "primereact/galleria";
import Button from "../../components/Button/Button";
import Form from "./components/Form/Form";
import { AnimatePresence, motion } from "framer-motion";

const RenderCaptions = ({ current, activeIndex }) => {
  return (
    <AnimatePresence>
      <motion.div
        className="caption__fade"
        key={activeIndex}
        initial={{ x: 200, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -200, opacity: 0 }}
      >
        <div className="caption-wrapper"  key={current.key}>
          <p className="caption-item title">{current.title}</p>
          <p className="caption-item description">{current.text}</p>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default function Login() {
  const galleriaRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showLogin, setShowLogin] = useState(false);
  const current = ONBOARDING_ITEMS[activeIndex];

  const onItemChange = (e) => {
    setActiveIndex(e.index)
    if (e.index == ONBOARDING_ITEMS.length - 1) {
      showLogin(true);
    }
  };

  const handleItemPrev = () => {
    setActiveIndex((index) => (index > 0 ? index - 1 : index));
  };

  const handleItemNext = () => {
    setActiveIndex((index) =>
      index < ONBOARDING_ITEMS.length ? index + 1 : index
    );
  };

  const handleSkip = () => {
    setShowLogin(true);
  };
  const renderOnboardingItems = (item) => {
    return (
      <>
        <div className="gallery-overlay" />
        <div className="onboarding-gallery">
          <img src={item.src} />
        </div>
      </>
    );
  };

  const Heading = () => {
    return (
      <div className="welcome-container">
        <p className="welcome">{`Welcome To`}</p>
        <p className="company-name">{`Mahesh Gyanpeeth`}</p>
        <div className="powered-by">
          <p className="powered-by">{`Powered by Stulyfe`}</p>
        </div>
      </div>
    );
  };

  const BottomContent = () => {
    return (
      <div className="bottom-container">
        <div className="caption-container">
          <RenderCaptions current={current} activeIndex={activeIndex} />
        </div>
        <div className="pagination-container">
          {ONBOARDING_ITEMS.map((item, index) => {
            let isActive = false;
            if (index == activeIndex) {
              isActive = true;
            }
            return (
              <div
                className={`pagination ${isActive ? "active" : ""}`}
                key={item.key}
                onClick={() => setActiveIndex(index)}
              />
            );
          })}
        </div>
        <PaginationButtons />
      </div>
    );
  };

  const PaginationButtons = () => {
    return (
      <div className="pagination-button">
        {activeIndex != 0 ? (
          <Button
            onClick={handleItemPrev}
            theme={"secondary"}
            style={{ width: "171px", marginTop: "15px" }}
          >
            Previous
          </Button>
        ) : null}
        <div className="item">
          <Button
            onClick={() =>
              activeIndex < ONBOARDING_ITEMS.length - 1
                ? handleItemNext()
                : handleSkip()
            }
            theme={"primary"}
            style={{ width: "171px", marginTop: "15px" }}
          >
            {activeIndex < ONBOARDING_ITEMS.length - 1 ? "Next" : "Get Started"}
          </Button>
        </div>
      </div>
    );
  };

  // const RenderCaptions = () => {
  //   return (
  //     <SwitchTransition mode={'out-in'} >
  //        <CSSTransition
  //          key={activeIndex}
  //          timeout={300}
  //          classNames="fade"
  //          >
  //     <div className='caption-wrapper'>
  //       <p className='caption-item title'>{current.title}</p>
  //       <p className='caption-item description'>{current.text}</p>
  //     </div>
  //     </CSSTransition>
  //     </SwitchTransition>
  //   )
  // }

  return (
    <div className="full-container">
      <div className="full-wrapper">
        <div className="logo-container">
          <div className="logo-wrapper">
            {LOGO_ITEMS.map((item, index) => {
              return (
                <div className="logo-item" key={index}>
                  <img src={item.src} />
                </div>
              );
            })}
          </div>
          <Heading />
        </div>
        <div className="right-container login-container">
          <div className={`skip-button-container ${showLogin ? "hide" : ""}`}>
            {!showLogin ? (
              <Button theme={"secondary"} onClick={handleSkip}>
                Skip
              </Button>
            ) : null}
          </div>
          {!showLogin ? (
            <React.Fragment>
              <Galleria
                ref={galleriaRef}
                showIndicators={true}
                showThumbnails={false}
                onItemChange={onItemChange}
                value={ONBOARDING_ITEMS}
                activeIndex={activeIndex}
                numVisible={1}
                item={renderOnboardingItems}
                showIndicatorsOnItem={true}
              />
              <BottomContent />
            </React.Fragment>
          ) : (
            <Form />
          )}
        </div>
      </div>
    </div>
  );
}
